import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    button: {
        border: 'none',
        textDecoration: 'none',
        cursor: 'pointer',
        borderRadius: '100px',
        fontWeight: '500',
    },
    large: {
        padding: '10px 24px',
        fontSize: '18px',
    },
    medium: {
        padding: '8px 20px',
        fontSize: '16px',
    },
    small: {
        padding: '6px 16px',
        fontSize: '14px',
    },
    primary: {
        background: 'rgba(218, 161, 14, 0.15)',
        color: '#E0AA3A',
        border: '1px solid #E0AA3A',
        boxShadow: '0px 0.6px 1.8px rgba(0, 0, 0, 0.1), 0px 3.2px 7.2px rgba(0, 0, 0, 0.13)',

        '&:hover': {
            background: 'rgba(218, 161, 14, 0.25)',
        },

        '&:disabled': {
            pointerEvents: 'none',
            opacity: '0.35',
        },

        '&:active': {
            opacity: '0.7',
        },
    },
    secondary: {
        background: 'transparent',
        color: '#E0AA3A',

        '&:hover': {
            background: 'rgba(224, 170, 58, 0.35)',
        },

        '&:disabled': {
            pointerEvents: 'none',
            opacity: '0.35',
        },

        '&:active': {
            opacity: '0.7',
        },
    },
    default: {
        background: 'transparent',
        color: 'rgba(255, 255, 255, 0.87)',
        border: '1px solid rgba(255, 255, 255, 0.87)',
    },
    approval: {
        color: '#191919',
        background: '#E0AA3A',

        '&:hover': {
            background: 'rgba(224, 170, 58, 0.85)',
        },

        '&:disabled': {
            pointerEvents: 'none',
            opacity: '0.35',
        },

        '&:active': {
            opacity: '0.7',
        },
    },
    loader: {
        marginRight: '11px',
        width: '19px !important',
        height: '19px !important',
    },
    text: {
       display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    loaderWrap: {
        background: '#2E2E2E',
        color: 'rgba(255, 255, 255, 0.5)',

        '&:disabled': {
            pointerEvents: 'none',
            opacity: '1',
        },

        '&:hover': {
            background: '#2E2E2E',
            color: 'rgba(255, 255, 255, 0.5)',
        },
    },
    error: {
        background: 'transparent',
        color: '#E03838',
        border: '1px solid #E03838',

        '&:disabled': {
            pointerEvents: 'none',
            opacity: '1',
        },

        '&:hover': {
            background: 'transparent',
            color: '#E03838',
            border: '1px solid #E03838',
        },

    },

}));

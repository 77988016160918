import { addresses } from '@project/contracts';
import { CG_TOKEN_IDS } from '../state/bnbPriceContext';

const getTokenPriceByAddress = ({ chainId, tokenUsdPrices, tokenAddress }) => {
    switch (tokenAddress.toLowerCase()) {
        case addresses[chainId].BISON.toLowerCase():
            return tokenUsdPrices[CG_TOKEN_IDS.BISON]?.usd;
        case addresses[chainId].OASIS.toLowerCase():
            return tokenUsdPrices[CG_TOKEN_IDS.OASIS]?.usd;
        case addresses[chainId].STARS.toLowerCase():
            return tokenUsdPrices[CG_TOKEN_IDS.STARS]?.usd;
        case addresses[chainId].NFTY.toLowerCase():
            return tokenUsdPrices[CG_TOKEN_IDS.NFTY]?.usd;
        case addresses[chainId].CEEK.toLowerCase():
            return tokenUsdPrices[CG_TOKEN_IDS.CEEK]?.usd;
        default:
            return '';
    }

};

export default getTokenPriceByAddress;

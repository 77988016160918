import React, { useState, useEffect, useMemo, memo } from 'react';
import BigNumber from 'bignumber.js';
import { Grid, Button, InputBase, Avatar, Tooltip } from '@material-ui/core';
import { useWeb3React } from '@web3-react/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import InfoIcon from '@material-ui/icons/Info';

import { formatter } from '../../utils/utils';
import useTokenBalance from '../../hooks/useTokenBalance';

import { useBnbPriceState } from '../../state/bnbPriceContext.js';
import usePoolTokenInfo from '../../hooks/usePoolTokenInfo.js';
import magic from '../../assets/logos/magic.svg';
import { BIT_10_SYMBOL } from '../../constants/global.constants';

import useStyles from './styles.module';

const InputToken = ({ onDemandGreaterThanBalance, placeholder, header, tokenInfo, disabled, setTokenSelectList, error,
  gradient, value, onChange, valueWei, poolInfo, handleMaxValue, showUsdPrice, currentToken, tooltip,
}) => {
  const { library } = useWeb3React();
  let poolTokenInfo;
  if (poolInfo?.tokens?.find(item => item.toLowerCase() === tokenInfo.address.toLowerCase())) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    poolTokenInfo = usePoolTokenInfo(poolInfo.address, tokenInfo.address);
  }
  const balance = useTokenBalance(tokenInfo.address);
  const classes = useStyles({ gradient, error });
  const priceState = useBnbPriceState();
  const usdPrice = poolTokenInfo?.averagePrice ? poolTokenInfo.averagePrice : priceState?.[tokenInfo.priceId]?.usd;
  const [usdValue, setUsdValue] = useState(0);

  useEffect(() => {
    const bnDemandValue = Number(placeholder);
    if (onDemandGreaterThanBalance && bnDemandValue > 0) {
      const newValue = bnDemandValue > Number(balance);
      onDemandGreaterThanBalance(tokenInfo.symbol, newValue);
    }
  }, [balance, onDemandGreaterThanBalance, tokenInfo.symbol, placeholder]);

  useEffect(() => {
    if (value && usdPrice && showUsdPrice) {
      const tokenUsdValue = new BigNumber(usdPrice)
        .times(new BigNumber(value))
        .div(currentToken ? 1 : 1e18)
        .toNumber();
      setUsdValue(tokenUsdValue);
    }
  }, [tokenInfo, usdPrice, value, showUsdPrice]);

  useEffect(() => {
    if (balance && handleMaxValue && poolInfo?.symbol === BIT_10_SYMBOL) {
      handleMaxValue(formatter.format(library.utils.fromWei(balance.toFixed(0), 'ether')));
    }
  }, [balance, poolInfo, handleMaxValue, library]);

  const wrapHandleClickMax = () => {
    handleMaxValue(formatter.format(library.utils.fromWei(balance.toFixed(0), 'ether')));
    onChange(formatter.format(library.utils.fromWei(balance.toFixed(0), 'ether')));
  };

  return (
    <Grid container alignItems="center" className={classes.container}>
      <div className={classes.flexContainer}>
        {header && (
          <div className={classes.header}>
            {header}
            {tooltip && (
            <Tooltip title={tooltip} className={classes.tooltip}>
              <InfoIcon className={classes.icon} />
            </Tooltip>
            )}
          </div>
        )}
        <div className={classes.balance}>
          {`Balance: ${balance ? formatter.format(library.utils.fromWei(balance.toFixed(0), 'ether')) : '0'}`}
        </div>
      </div>
      <div className={classes.flexContainer}>
        <InputBase
          value={valueWei ? formatter.format(library.utils.fromWei(value, 'ether')) : value}
          onChange={e => onChange(e.target.value)}
          disabled={disabled}
          error={error}
          inputProps={{ 'aria-label': 'naked', className: classes.input }}
          placeholder={library && placeholder ? formatter.format(library.utils.fromWei(placeholder, 'ether')) : '0'}
        />
        {setTokenSelectList ? (
          <Button className={classes.tokenSelectButton} onClick={() => setTokenSelectList(true)}>
            <Avatar className={classes.tokenButtonLabel}>
              <img
                src={tokenInfo.img}
                className={classes.tokenButtonImg}
                alt={tokenInfo.symbol}
              />
            </Avatar>
            <div>{tokenInfo.symbol}</div>
            <NavigateNextIcon />
          </Button>
        ) : (
          <div className={classes.tokenSymbol}>{tokenInfo.symbol}</div>
        )}
      </div>
      <div className={classes.flexContainer}>
        <div className={classes.balanceUsd}>{error || (showUsdPrice && `$${formatter.format(usdValue)}`)}</div>

        {handleMaxValue && (
        <>
          {
            poolInfo?.symbol === BIT_10_SYMBOL
              ? <Tooltip title="Max burn only"><InfoIcon /></Tooltip>
              : (
                <Button
                  className={classes.maxButton}
                  disabled={disabled}
                  onClick={wrapHandleClickMax}
                >
                  <img src={magic} alt="MAX" />
                  <span>MAX</span>
                </Button>
                )
          }
        </>
        )}
      </div>
    </Grid>
  );
};

InputToken.defaultProps = {
  onInputGreaterThanBalance: () => {},
};

export default memo(
  InputToken,
  (prevProps, nextProps) => prevProps.tokenInfo?.address === nextProps.tokenInfo?.address
    && prevProps.placeholder === nextProps.placeholder
    && prevProps.error === nextProps.error
    && prevProps.value === nextProps.value,
);

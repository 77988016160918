import React from 'react';
import { useParams } from 'react-router-dom';

import Funds from '../Funds';

import usePoolYBFInfo from '../../hooks/YBFPoolhooks/usePoolYBFInfo.js';

import { FUNDS_TYPE } from '../../constants/global.constants';

const YBFunds = () => {
    const { address } = useParams();
    const poolInfo = usePoolYBFInfo(address);

    return (
      <Funds poolInfo={poolInfo} type={FUNDS_TYPE.YBF_FUNDS} />
    );
};

export default YBFunds;

import { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';

import useTokenInfo from './useTokenInfo';
import { useCurrentBlock } from '../state/currentBlockContext';

import useAveragePrice from './useAveragePrice';
import usePoolTokenBalance from './usePoolTokenBalance';
import usePoolTokenWeight from './usePoolTokenWeight';

import { getTokenAvatar } from '../utils/utils';

const usePoolTokenInfo = (poolAddress, poolToken) => {
    const { account, library, chainId } = useWeb3React();
    const [info, setInfo] = useState({});
    const block = useCurrentBlock();

    const { name, symbol } = useTokenInfo(poolToken);
    const tokenBalance = usePoolTokenBalance(poolAddress, poolToken);
    const tokenWeight = usePoolTokenWeight(poolAddress, poolToken);
    const averagePrice = useAveragePrice(poolToken);

    useEffect(() => {
        if (account && library && poolToken && name && symbol && tokenBalance && tokenWeight && averagePrice) {
            (async () => {
                const avatar = getTokenAvatar(poolToken, chainId);
                setInfo({
                    address: poolToken,
                    balance: tokenBalance,
                    weight: tokenWeight,
                    img: avatar,
                    name,
                    symbol,
                    averagePrice,
                });
            })();
        }
    }, [account, averagePrice, chainId, library, name, poolToken, symbol, tokenBalance, tokenWeight, block]);

    return info;
};

export default usePoolTokenInfo;

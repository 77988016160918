import { abis, addresses } from '@project/contracts';
import BigNumber from 'bignumber.js';
import { BINANCE_ID, FANTOM_ID } from '../constants/global.constants';
import { API_GRAPH_FUND, API_GRAPH_FUND_FANTOM, API_GRAPH_YBF, API_GRAPH_YBF_FANTOM } from '../constants/routes.constants';

import bakerytoken from '../assets/coins/bakerytoken.png';
import cake from '../assets/coins/cake.png';
import safepal from '../assets/coins/safepal.png';
import trust from '../assets/coins/trust.png';
import venus from '../assets/coins/venus.png';
import wbnb from '../assets/coins/wbnb.png';
import swipe from '../assets/coins/swipe.png';
import ellipsis from '../assets/coins/ellipsis.png';
import banana from '../assets/coins/banana.png';
import bifi from '../assets/coins/bifi.png';
import bison from '../assets/coins/bison.png';
import dragonary from '../assets/coins/CYT-LOGO-1.webp';
import bitfresh from '../assets/coins/bitfresh.png';
import jdi from '../assets/coins/jdi.svg';
import hifi from '../assets/coins/hifi.jpg';
import lyptus from '../assets/coins/lyptus.webp';
import type from '../assets/coins/type.jpg';

import apeSwap from '../assets/logos/biAPE.png';
import biChain from '../assets/logos/biChain.png';
import biGame from '../assets/logos/biGame.png';
import biMEX from '../assets/logos/biMEX.png';
import biDeFi from '../assets/logos/biDefi.jpg';
import biFoMo from '../assets/logos/biFOMO.jpg';
import ybfStable from '../assets/logos/ybfStable.png';
import ybfDex from '../assets/logos/ybfDex.png';
import mfALFA from '../assets/logos/mfALFA.png';
import biDEGEN from '../assets/logos/biDEGEN.png';
import biSPIRIT from '../assets/logos/biSPIRIT.png';
import ybfMeta from '../assets/logos/ybfMeta.png';

import ethereum from '../assets/coins/ethereum.png';
import btcb from '../assets/coins/btcb.png';
import cardano from '../assets/coins/cardano.jpg';
import fantom from '../assets/coins/FANTOM.png';
import polygon from '../assets/coins/POLYGON.png';
import avalanche from '../assets/coins/AVALANCHE.png';
import busd from '../assets/coins/busd.svg';
import mbox from '../assets/coins/mbox.svg';
import axl from '../assets/coins/axl.png';
import sfund from '../assets/coins/sfund.png';
import pots from '../assets/coins/pots.svg';
import skill from '../assets/coins/skill.svg';
import revv from '../assets/coins/revv.png';
import DOGE from '../assets/coins/DOGE.svg';
import DOT from '../assets/coins/DOT.png';
import LINK from '../assets/coins/LINK.svg';
import LTC from '../assets/coins/LTC.svg';
import SUSHI from '../assets/coins/SUSHI.svg';
import TRX from '../assets/coins/TRX.png';
import UNI from '../assets/coins/UNI.png';
import XRP from '../assets/coins/XRP.png';
import AAVE from '../assets/coins/AAVE.svg';
import COMP from '../assets/coins/COMP.svg';
import SNX from '../assets/coins/snx.svg';
import YFI from '../assets/coins/YFI.svg';
import WANA from '../assets/coins/WANA.png';
import THG from '../assets/coins/THG.png';
import SAFESTOS from '../assets/coins/SAFESTOS.jpg';
import PACOCA from '../assets/coins/PACOCA.svg';
import MATE from '../assets/coins/MATE.png';
import LAND from '../assets/coins/LAND.svg';
import HUNNY from '../assets/coins/HUNNY.png';
import HERO from '../assets/coins/HERO.png';
import DIS from '../assets/coins/DIS.png';
import SPIRIT from '../assets/coins/spirit.png';
import TAROT from '../assets/coins/TAROT.png';
import TCS from '../assets/coins/TCS.png';
import MIM from '../assets/coins/MIM.webp';
import TOMB from '../assets/coins/TOMB.svg';
import MENSA from '../assets/coins/MENSA.png';
import LQDR from '../assets/coins/LQDR.svg';
import JUST from '../assets/coins/JUST.webp';
import ICE from '../assets/coins/ICE.png';
import ANY from '../assets/coins/ANY.png';
import CRV from '../assets/coins/CRV.png';
import BNB from '../assets/coins/bnb.svg';
import CEEK from '../assets/coins/CEEK.png';
import NFTY from '../assets/coins/NFTY.png';
import STARS from '../assets/coins/STARS.png';
import OASIS from '../assets/coins/OASIS.png';
import UsdcBusdApe from '../assets/coins/USDC_BUSD_APE.png';
import UsdtBusdApe from '../assets/coins/USDT_BUSD_APE.png';
import DaiBusdCake from '../assets/coins/DAI_BUSD_Cake.png';
import TusdBusdCake from '../assets/coins/TUSD_BUSD_Cake.png';
import UsdcBusdCake from '../assets/coins/USDC_BUSD_Cake.png';
import UsdtBusdCake from '../assets/coins/USDT_BUSD_Cake.png';
import UsdtUsdcCake from '../assets/coins/USDT_USDC_Cake.png';
import UsdcUsdtBiswap from '../assets/coins/USDC_USDT_Biswap.png';
import UsdtBusdBiswap from '../assets/coins/USDT_BUSD_Biswap.png';
import TusdBusdBiswap from '../assets/coins/TUSD_BUSD_Biswap.png';
import UsdcUsdtMdex from '../assets/coins/USDC_USDT_Mdex.png';
import DaiUsdtBiswap from '../assets/coins/DAI_USDT_Biswap.png';
import DaiUsdtMdex from '../assets/coins/DAI_USDT_Mdex.png';
import BusdUsdtMdex from '../assets/coins/BUSD_USDT_Mdex.png';
import BnbBswBiswap from '../assets/coins/BNB_BSW_Biswap.png';
import BnbBananaApe from '../assets/coins/BNB_BANANA_APE.png';
import BnbMdexMdex from '../assets/coins/BNB_MDEX_MDEX.png';
import BnbBisonApe from '../assets/coins/BNB_BISON_APE.png';
import BnbCakeCake from '../assets/coins/BNB_CAKE_Cake.png';
import FtmBisonSpirit from '../assets/coins/SPIRITSWAP-FTM-BISON.png';
import FtmTarotSpirit from '../assets/coins/SPIRITSWAP-FTM-TAROT.png';
import FtmSpiritSpirit from '../assets/coins/SPITITSWAP-FTM-SPIRIT.png';
import FtmBooBoo from '../assets/coins/SPOOKYSWAP-FTM-BOO.png';
import FtmLqdrBoo from '../assets/coins/SPOOKYSWAP-FTM-LQDR.png';
import BisonBnbAPE from '../assets/coins/BISON-BNB-APE.png';
import CeekBnbApe from '../assets/coins/CEEK-BNB-APE.png';
import MogulBnBApe from '../assets/coins/MOGUL-BNB-APE.png';
import NiftyBnbApe from '../assets/coins/NIFTY-BNB-APE.png';
import OasisBnbApe from '../assets/coins/OASIS-BNB-APE.png';

import BisonLogo from '../assets/logos/bisonLogo.png';

import ape from '../assets/dexLogo/APE.png';
import bsw from '../assets/dexLogo/BSW.png';
import pns from '../assets/dexLogo/CAKE.png';
import mdex from '../assets/dexLogo/MDEX.png';
import spitit from '../assets/dexLogo/SPIRIT.png';
import sp from '../assets/dexLogo/BOO.png';

import biT10 from '../assets/coins/biT10.png';
import { CG_TOKEN_IDS } from '../state/bnbPriceContext';

export const MAX_UINT = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
export const MAX_UINT_96 = '0xffffffffffffffffffffffff';

export const formatter = new Intl.NumberFormat(
  'en-US',
  // These options are needed to round to whole numbers if that's what you want.
  // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
);

export const SHORT_TWAP_MIN = '1';
export const SHORT_TWAP_MAX = '172800';

export const makeContract = (library, abi, address) => new library.eth.Contract(abi, address);
export const isApproved = allowance => Number(allowance) >= Number(MAX_UINT_96);

// TODO: rm tokenname + symbol + address
export const getTokenAvatar = (coinaddress, chainId) => {
  if (chainId === BINANCE_ID) {
    switch (coinaddress.toLowerCase()) {
      case addresses[chainId].CEEK.toLowerCase():
        return CEEK;
      case addresses[chainId].NFTY.toLowerCase():
        return NFTY;
      case addresses[chainId].STARS.toLowerCase():
        return STARS;
      case addresses[chainId].OASIS.toLowerCase():
        return OASIS;
      case addresses[chainId].LAND.toLowerCase():
        return LAND;
      case addresses[chainId].HUNNY.toLowerCase():
        return HUNNY;
      case addresses[chainId].HERO.toLowerCase():
        return HERO;
      case addresses[chainId].DIS.toLowerCase():
        return DIS;
      case addresses[chainId].WANA.toLowerCase():
        return WANA;
      case addresses[chainId].THG.toLowerCase():
        return THG;
      case addresses[chainId].SAFESTOS.toLowerCase():
        return SAFESTOS;
      case addresses[chainId].PACOCA.toLowerCase():
        return PACOCA;
      case addresses[chainId].MATE.toLowerCase():
        return MATE;
      case addresses[chainId].YFI.toLowerCase():
        return YFI;
      case addresses[chainId].SNX.toLowerCase():
        return SNX;
      case addresses[chainId].COMP.toLowerCase():
        return COMP;
      case addresses[chainId].AAVE.toLowerCase():
        return AAVE;
      case addresses[chainId].SUSHI.toLowerCase():
        return SUSHI;
      case addresses[chainId].UNI.toLowerCase():
        return UNI;
      case addresses[chainId].LTC.toLowerCase():
        return LTC;
      case addresses[chainId].LINK.toLowerCase():
        return LINK;
      case addresses[chainId].XRP.toLowerCase():
        return XRP;
      case addresses[chainId].TRON.toLowerCase():
        return TRX;
      case addresses[chainId].DOT.toLowerCase():
        return DOT;
      case addresses[chainId].DOGE.toLowerCase():
        return DOGE;
      case addresses[chainId].REVV.toLowerCase():
        return revv;
      case addresses[chainId].SKILL.toLowerCase():
        return skill;
      case addresses[chainId].POTS.toLowerCase():
        return pots;
      case addresses[chainId].SFUND.toLowerCase():
        return sfund;
      case addresses[chainId].AXS.toLowerCase():
        return axl;
      case addresses[chainId].MBOX.toLowerCase():
        return mbox;
      case addresses[chainId].BUSD.toLowerCase():
        return busd;
      case addresses[chainId].POLYGON.toLowerCase():
        return polygon;
      case addresses[chainId].FANTOM.toLowerCase():
        return fantom;
      case addresses[chainId].AVALANCHE.toLowerCase():
        return avalanche;
      case addresses[chainId].CARDANO.toLowerCase():
        return cardano;
      case addresses[chainId].BITCOIN.toLowerCase():
        return btcb;
      case addresses[chainId].ETHERIUM.toLowerCase():
        return ethereum;
      case addresses[chainId].LYPTUS.toLowerCase():
        return lyptus;
      case addresses[chainId].TAPE.toLowerCase():
        return type;
      case addresses[chainId].HIFI.toLowerCase():
        return hifi;
      case addresses[chainId].JDI.toLowerCase():
        return jdi;
      case addresses[chainId].BITFRESH.toLowerCase():
        return bitfresh;
      case addresses[chainId].DRAGONARY.toLowerCase():
        return dragonary;
      case addresses[chainId].WBNB.toLowerCase():
        return wbnb;
      case addresses[chainId].CAKE.toLowerCase():
        return cake;
      case addresses[chainId].SWIPE.toLowerCase():
        return swipe;
      case addresses[chainId].BAKE.toLowerCase():
        return bakerytoken;
      case addresses[chainId].VENUS.toLowerCase():
        return venus;
      case addresses[chainId].ELLIPSIS.toLowerCase():
        return ellipsis;
      case addresses[chainId].BANANA.toLowerCase():
        return banana;
      case addresses[chainId].SAFEPAL.toLowerCase():
        return safepal;
      case addresses[chainId].TWT.toLowerCase():
        return trust;
      case addresses[chainId].BIFI.toLowerCase():
        return bifi;
      case addresses[chainId].BISON.toLowerCase():
        return bison;
      case addresses[chainId].biT10.toLowerCase():
        return biT10;
      case addresses[chainId].apeSwap.toLowerCase():
        return apeSwap;
      case addresses[chainId].biChain.toLowerCase():
        return biChain;
      case addresses[chainId].biGame.toLowerCase():
        return biGame;
      case addresses[chainId].biMex.toLowerCase():
        return biMEX;
      case addresses[chainId].biDeFi.toLowerCase():
        return biDeFi;
      case addresses[chainId].biFoMo.toLowerCase():
        return biFoMo;
      case addresses[chainId].biCHAIN_BNB_LP.toLowerCase():
        return biChain;
      case addresses[chainId].YBF.DAI_BUSD_Cake.toLowerCase():
        return DaiBusdCake;
      case addresses[chainId].YBF.USDT_BUSD_APE.toLowerCase():
        return UsdtBusdApe;
      case addresses[chainId].YBF.USDC_BUSD_APE.toLowerCase():
        return UsdcBusdApe;
      case addresses[chainId].YBF.USDT_USDC_Cake.toLowerCase():
        return UsdtUsdcCake;
      case addresses[chainId].YBF.USDC_BUSD_Cake.toLowerCase():
        return UsdcBusdCake;
      case addresses[chainId].YBF.TUSD_BUSD_Cake.toLowerCase():
        return TusdBusdCake;
      case addresses[chainId].YBF.USDT_BUSD_Cake.toLowerCase():
        return UsdtBusdCake;
      case addresses[chainId].YBF.USDC_USDT_Biswap.toLowerCase():
        return UsdcUsdtBiswap;
      case addresses[chainId].YBF.USDT_BUSD_Biswap.toLowerCase():
        return UsdtBusdBiswap;
      case addresses[chainId].YBF.TUSD_BUSD_Biswap.toLowerCase():
        return TusdBusdBiswap;
      case addresses[chainId].YBF.USDC_USDT_Mdex.toLowerCase():
        return UsdcUsdtMdex;
      case addresses[chainId].YBF.DAI_USDT_Biswap.toLowerCase():
        return DaiUsdtBiswap;
      case addresses[chainId].YBF.DAI_USDT_Mdex.toLowerCase():
        return DaiUsdtMdex;
      case addresses[chainId].YBF.BUSD_USDT_Mdex.toLowerCase():
        return BusdUsdtMdex;
      case addresses[chainId].YBF.BNB_BSW_Biswap.toLowerCase():
        return BnbBswBiswap;
      case addresses[chainId].YBF.BNB_BANANA_APE.toLowerCase():
        return BnbBananaApe;
      case addresses[chainId].YBF.BNB_CAKE_Cake.toLowerCase():
        return BnbCakeCake;
      case addresses[chainId].YBF.BNB_MDEX_MDEX.toLowerCase():
        return BnbMdexMdex;
      case addresses[chainId].YBF.BNB_BISON_APE.toLowerCase():
        return BnbBisonApe;
      case addresses[chainId].YBF.BISON_BNB_APE.toLowerCase():
        return BisonBnbAPE;
      case addresses[chainId].YBF.CEEK_BNB_APE.toLowerCase():
        return CeekBnbApe;
      case addresses[chainId].YBF.MOGUL_BNB_APE.toLowerCase():
        return MogulBnBApe;
      case addresses[chainId].YBF.NIFTY_BNB_APE.toLowerCase():
        return NiftyBnbApe;
      case addresses[chainId].YBF.OASIS_BNB_APE.toLowerCase():
        return OasisBnbApe;
      case addresses[chainId].YBF.ybfStable.toLowerCase():
        return ybfStable;
      case addresses[chainId].YBF.ybfDEX.toLowerCase():
        return ybfDex;
      case addresses[chainId].YBF.ybfMETA.toLowerCase():
        return ybfMeta;
      default:
        return BisonLogo;
    }
  } else if (chainId === FANTOM_ID) {
    switch (coinaddress.toLowerCase()) {
      case addresses[chainId].BNB.toLowerCase():
        return BNB;
      case addresses[chainId].YFI.toLowerCase():
        return YFI;
      case addresses[chainId].CRV.toLowerCase():
        return CRV;
      case addresses[chainId].ANY.toLowerCase():
        return ANY;
      case addresses[chainId].LINK.toLowerCase():
        return LINK;
      case addresses[chainId].SUSHI.toLowerCase():
        return SUSHI;
      case addresses[chainId].BISON.toLowerCase():
        return bison;
      case addresses[chainId].ICE.toLowerCase():
        return ICE;
      case addresses[chainId].JUST.toLowerCase():
        return JUST;
      case addresses[chainId].LQDR.toLowerCase():
        return LQDR;
      case addresses[chainId].MENSA.toLowerCase():
        return MENSA;
      case addresses[chainId].TOMB.toLowerCase():
        return TOMB;
      case addresses[chainId].DIS.toLowerCase():
        return DIS;
      case addresses[chainId].MIM.toLowerCase():
        return MIM;
      case addresses[chainId].TCS.toLowerCase():
        return TCS;
      case addresses[chainId].TAROT.toLowerCase():
        return TAROT;
      case addresses[chainId].SPIRIT.toLowerCase():
        return SPIRIT;
      case addresses[chainId].biDEGEN.toLowerCase():
        return biDEGEN;
      case addresses[chainId].biSPIRIT.toLowerCase():
        return biSPIRIT;
      case addresses[chainId].YBF.FTM_BISON_SPIRIT.toLowerCase():
        return FtmBisonSpirit;
      case addresses[chainId].YBF.FTM_TAROT_SPIRIT.toLowerCase():
        return FtmTarotSpirit;
      case addresses[chainId].YBF.FTM_SPIRIT_SPIRIT.toLowerCase():
        return FtmSpiritSpirit;
      case addresses[chainId].YBF.FTM_BOO_BOO.toLowerCase():
        return FtmBooBoo;
      case addresses[chainId].YBF.FTM_LQDR_BOO.toLowerCase():
        return FtmLqdrBoo;
      case addresses[chainId].YBF.mfALFA.toLowerCase():
        return mfALFA;
      default:
        return BisonLogo;
    }
  }

};

export const getDexAvatar = name => {
  switch (name) {
    case 'APE-LP':
      return ape;
    case 'MDEX LP':
      return mdex;
    case 'BSW-LP':
      return bsw;
    case 'Cake-LP':
      return pns;
    case 'SPIRIT-LP':
      return spitit;
    case 'spLP':
      return sp;
    default:
      return '';
  }
};

export const percentageOf = (outOff, value) => {
  const result = (Number(value) * 100) / Number(outOff);
  return result.toString();
};

export const copyTextToClipboard = text => navigator.clipboard.writeText(text);

export function sortTokens(tokenA, tokenB) {
  return tokenA.toLowerCase() < tokenB.toLowerCase() ? [tokenA, tokenB] : [tokenB, tokenA];
}

export const toScaledBN = decimals => new BigNumber(10).exponentiatedBy(decimals);

export const getGraphUrl = chainId => {
  switch (chainId) {
    case BINANCE_ID:
      return API_GRAPH_FUND;
    case FANTOM_ID:
      return API_GRAPH_FUND_FANTOM;
    default:
      return '';
  }
};

export const getGraphYbfUrl = chainId => {
  switch (chainId) {
    case BINANCE_ID:
      return API_GRAPH_YBF;
    case FANTOM_ID:
      return API_GRAPH_YBF_FANTOM;
    default:
      return '';
  }
};

export const isAddressesEqual = (address0, address1) => address0.toLowerCase() === address1.toLowerCase();

export const isAddressStable = (address, chain) => {
  if (chain === 56) {
    return [
      addresses[chain].BUSD,
      addresses[chain].USDC,
      addresses[chain].USDT,
    ].some(stableAddress => isAddressesEqual(stableAddress, address));
  }
  return false;
};

export const compound = (r, n = 365, t = 1, c = 1) => (1 + (r * c) / n) ** (n * t) - 1;

export const formatBN = (val, precision = 2) => Number((BigNumber.isBigNumber(val) ? val : new BigNumber(val)).toFixed(precision));
export const formatBNWithCommas = (val, prec = 2) => Number(formatBN(val, prec)).toLocaleString('en-US', { maximumFractionDigits: prec });

export const getTokenInfoByAddress = (tokenPrices, address, chain) => {
  if (chain === 56) {
    const { CAKE, BANANA, MDX, BSW } = addresses[chain];

    if (isAddressesEqual(address, CAKE)) {
      return {
        rewardTokenPriceUsd: tokenPrices[CG_TOKEN_IDS.CAKE]?.usd,
        abi: abis.masterChef,
        rewardPerBlockMethod: 'cakePerBlock',
        multiplierMethod: 'getMultiplier',
      };
    }
    if (isAddressesEqual(address, BANANA)) {
      return {
        rewardTokenPriceUsd: tokenPrices[CG_TOKEN_IDS.BANANA]?.usd,
        abi: abis.masterChef,
        rewardPerBlockMethod: 'cakePerBlock',
        multiplierMethod: 'getMultiplier',
      };
    }
    if (isAddressesEqual(address, MDX)) {
      return {
        rewardTokenPriceUsd: tokenPrices[CG_TOKEN_IDS.MDX]?.usd,
        abi: abis.masterChefMDEX,
        rewardPerBlockMethod: 'mdxPerBlock',
        multiplierMethod: '',
      };
    }
    if (isAddressesEqual(address, BSW)) {
      return {
        rewardTokenPriceUsd: tokenPrices[CG_TOKEN_IDS.BSW]?.usd,
        abi: abis.masterChefBiSwap,
        rewardPerBlockMethod: 'BSWPerBlock',
        multiplierMethod: 'getMultiplier',
      };
    }

  }

  return 0;
};

export const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    description: {
        lineHeight: '160%',
        marginBottom: '32px',
    },
    container: {
        background: 'rgba(0, 0, 0, 0.25)',
        borderRadius: '16px',
        margin: '16px 0',
        display: 'flex',
        flexDirection: 'column',
    },
    inputContainerTop: {
        marginBottom: '16px',
    },
    arrowContainer: {
        display: 'flex',
        borderRadius: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 auto',
        background: 'rgba(0, 0, 0, 0.25)',
        width: '40px',
        height: '40px',
        color: '#F0B90B',
    },
    mintButton: {
        marginTop: '16px',
        borderRadius: '100px',
        width: '100%',
    },
    backButton: {
        borderRadius: '32px',
    },
    selectContainer: {
        maxHeight: '285px',
        overflow: 'auto',
        padding: '0 18px',
        height: '100%',
    },
});

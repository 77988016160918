import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import { useWeb3React } from '@web3-react/core';

import Farm from '../../components/Farm';
import Button from '../../components/ButtonUI';
import Loader from '../../components/Loader';

import useFarmsInfo from '../../hooks/FarmsHooks/useFarmsInfo';

import farms from '../../config/farms.json';
import { BUTTON_TYPES } from '../../constants/style.constants';

import { useStyles } from './styles.module';

const Farms = () => {
  const { chainId } = useWeb3React();
  const classes = useStyles();
  const [isActive, setActive] = useState(true);

  const farmsInfo = useFarmsInfo(farms[chainId]);

  return (
    <Grid container className={classes.container}>
      {
            farmsInfo.length ? (
              <>
                <div className={classes.sortButtons}>
                  <Button
                    type={BUTTON_TYPES.SECONDARY}
                    className={classNames(classes.button, { [classes.active]: isActive })}
                    onClick={() => setActive(true)}
                  >
                    Active
                  </Button>
                  <Button
                    type={BUTTON_TYPES.SECONDARY}
                    className={classNames(classes.button, { [classes.active]: !isActive })}
                    onClick={() => setActive(false)}
                  >
                    Inactive
                  </Button>
                </div>
                <div className={classes.grid}>
                  {
                    isActive ? (
                      <>
                        {
                            farmsInfo.map(farm => !farm.isFinished && (
                            <Farm farmInfo={farm} key={farm.farmAddress} />
                            ))
                        }
                      </>
                    ) : (
                      <>
                        {
                            farmsInfo.map(farm => farm.isFinished && (
                            <Farm farmInfo={farm} key={farm.farmAddress} />
                            ))
                        }
                      </>
                    )
                  }
                </div>
              </>
            ) : (
              <Loader />
            )
        }
    </Grid>
  );
};

export default Farms;

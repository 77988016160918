import React from 'react';
import {
  Switch,
  Route,
  HashRouter,
} from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { ToastProvider } from 'react-toast-notifications';
import { SWRConfig } from 'swr';

import { theme } from './theme';

import Layout from './containers/Loyout';
import MainFunds from './containers/MainFunds';
import YBFunds from './containers/YBFunds';
import Dashboard from './containers/Dashboard';
import Farms from './containers/Farms';
import AuthorizationWrap from './containers/AuthorizationWrap';

import { BnbPriceContextProvider } from './state/bnbPriceContext';
import { CurrentBlockContextProvider } from './state/currentBlockContext';
import { LpApysTradingFeesContextProvider } from './state/lpTradingFeesContext';

import { PATH_INDEX, PATH_FOUNDS, PATH_YBF, PATH_FARMS } from './constants/routes.constants';

const App = () => (
  <SWRConfig
    value={{ fetcher: (resource, init) => fetch(resource, init).then(res => res.json()) }}
  >
    <HashRouter>
      <AuthorizationWrap>
        <ThemeProvider theme={theme}>
          <ToastProvider
            autoDismiss
            autoDismissTimeout={6000}
            placement="bottom-right"
          >
            <CurrentBlockContextProvider>
              <BnbPriceContextProvider>
                <LpApysTradingFeesContextProvider>
                  <Layout>
                    <Switch>
                      <Route path={PATH_INDEX} exact component={Dashboard} />
                      <Route path={PATH_FOUNDS} exact component={MainFunds} />
                      <Route path={PATH_YBF} exact component={YBFunds} />
                      <Route path={PATH_FARMS} exact component={Farms} />
                    </Switch>
                  </Layout>
                </LpApysTradingFeesContextProvider>
              </BnbPriceContextProvider>
            </CurrentBlockContextProvider>
          </ToastProvider>
        </ThemeProvider>
      </AuthorizationWrap>
    </HashRouter>
  </SWRConfig>
);

export default App;

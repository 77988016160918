import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import classNames from 'classnames';
import { useWeb3React } from '@web3-react/core';

import Button from '../ButtonUI';
import Input from '../Input';
import PercentInput from '../PercentInput';
import FarmCountdown from './FarmCountdown';

import useFarm from '../../hooks/FarmsHooks/useFarm';
import { useCurrentBlock } from '../../state/currentBlockContext';

import { formatBN, formatBNWithCommas, getTokenAvatar } from '../../utils/utils';
import getBlockDatetime from '../../utils/getBlockDatetime';

import { BUTTON_TYPES, BUTTON_SIZES } from '../../constants/style.constants';

import { useStyles } from './styles.module';

const Farm = ({ farmInfo, isPanel }) => {
  const classes = useStyles();
  const { chainId } = useWeb3React();
  const {
    earningTokenSymbol,
    farmName,
    stakingSymbol,
    getStakingTokenLink,
    endBlock,
    apr,
    earningValue,
    earningToken,
    stakingTokenAddress,
    isFinished,
  } = farmInfo;

  const {
    earnedValue,
    isTokenApproved,
    onApprove,
    stakeBalance,
    stakeValue,
    stakeInput,
    unStakeInput,
    stakeBalanceUsd,
    unStakeValue,
    handleMax,
    handleDeposit,
    totalStakedInToken,
    onChangeStakeValue,
    onChangeUnStakeValue,
    handleHarvest,
    handleWithdraw,
    stakeDrop,
    unStakeDrop,
    youStakeValue,
    stakeValueUsd,
    liquidity,
    errorStake,
    loading,
    harvestLoading,
  } = useFarm({ farmInfo });

  const currentBlock = useCurrentBlock(endBlock);

  const endBlockDatetime = useMemo(
    () => getBlockDatetime(endBlock, currentBlock, chainId),
    [chainId, currentBlock, endBlock],
  );

  const farmLogo = getTokenAvatar(stakingTokenAddress, chainId);
  const rewardTokenAvatar = getTokenAvatar(earningToken, chainId);

  return (
    <Grid item className={classNames(classes.wrap, { [classes.dropWrap]: stakeInput || unStakeInput })}>
      <div className={classNames(classes.titleRow, { [classes.panelTitle]: isPanel })}>
        <div className={classes.row}>
          <Avatar src={farmLogo} />
          <Button
            type={BUTTON_TYPES.PRIMARY}
            size={BUTTON_SIZES.M}
            className={classes.harvestButton}
            disabled={!Number(earnedValue.toFixed(18))}
            loader={harvestLoading}
            onClick={handleHarvest}
          >
            Harvest
          </Button>
        </div>
        {endBlockDatetime && (
          <div className={classNames({ [classes.margin]: isPanel })}>
            {
              isFinished
                ? (<div className={classes.timer}>Finished</div>)
                : (<FarmCountdown styleClass={classes.timer} dateEnd={endBlockDatetime.toISOString()} />)
            }
          </div>
        )}
      </div>
      <div className={classes.farmName}>{farmName}</div>
      <div className={classes.earnedValue}>{earnedValue.isZero() ? '0' : formatBN(earnedValue, 3)}</div>
      <div className={classes.earnedToken}>{earningTokenSymbol} Earned</div>
      <div className={classes.buttons}>
        {isTokenApproved ? (
          <>
            <Button
              type={BUTTON_TYPES.APPROVAL}
              className={classes.button}
              onClick={stakeDrop}
              disabled={loading || isFinished}
            >
              Stake
            </Button>
            <Button
              type={BUTTON_TYPES.APPROVAL}
              className={classes.button}
              onClick={unStakeDrop}
              disabled={loading || youStakeValue.isZero()}
            >
              Unstake
            </Button>
          </>
        ) : (
          <>
            <Button
              type={BUTTON_TYPES.APPROVAL}
              className={classes.approveButton}
              onClick={onApprove}
              loader={loading}
              disabled={isFinished}
            >
              Approve
            </Button>
            {
              youStakeValue.isGreaterThan(0) &&
              <Button
                type={BUTTON_TYPES.APPROVAL}
                className={classes.button}
                onClick={unStakeDrop}
                disabled={loading}
              >
                Unstake
              </Button>

            }
          </>
        )}
      </div>
      <div className={classes.infoWrap}>
        <div className={classes.listRow}>
          <div>APR:</div>
          <div className={classes.textWhite}>{formatBNWithCommas(apr, 2)}%</div>
        </div>
        <div className={classes.listRow}>
          <div>Your Stake:</div>
          <div className={classes.textWhite}>{formatBNWithCommas(youStakeValue, 2)}</div>
        </div>
        <div className={classes.listRow}>
          <div>Staked value:</div>
          <div className={classes.textWhite}>${stakeValueUsd ? formatBN(stakeValueUsd, 2) : '-'}</div>
        </div>
        <div className={classes.listRow}>
          <div>Earned value:</div>
          <div className={classes.textWhite}>${formatBNWithCommas(earningValue, 2)}</div>
        </div>
      </div>
      <div className={classes.infoWrap}>
        <div className={classes.listRow}>
          <div className={classes.rewardToken}>
            <Avatar src={rewardTokenAvatar} className={classes.rewardAvatar} />
            <span>Rewards</span>
          </div>
        </div>
        <div className={classes.listRow}>
          <div>Total Liquidity:</div>
          <div className={classes.textWhite}>${formatBNWithCommas(liquidity, 2)}</div>
        </div>
        <div className={classes.listRow}>
          <div>Total {stakingSymbol} Staked:</div>
          <div className={classes.textWhite}>{formatBNWithCommas(totalStakedInToken, 2)}</div>
        </div>
      </div>
      <div className={classes.linkLP}>
        <a href={getStakingTokenLink} target="_blank" rel="noreferrer">
          Get {stakingSymbol}
        </a>
      </div>
      {stakeInput && (
        <>
          <Input
            gradient
            balance={stakeBalance}
            value={stakeValue}
            onChange={onChangeStakeValue}
            className={classes.input}
            header={<b>Deposit</b>}
            tokenName={stakingSymbol}
            handleMax={handleMax}
            stakeBalanceUsd={stakeBalanceUsd}
          />
          <div className={classes.buttons}>
            <Button
              type={BUTTON_TYPES.PRIMARY}
              className={classes.button}
              onClick={stakeDrop}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              type={BUTTON_TYPES.PRIMARY}
              className={classes.button}
              loader={loading}
              disabled={!stakeValue || !Number(stakeValue)}
              onClick={handleDeposit}
              error={errorStake}
            >
              Confirm
            </Button>
          </div>
        </>
      )}
      {unStakeInput && (
        <>
          <PercentInput
            className={classes.input}
            tokenInfo={{ symbol: stakingSymbol }}
            balance={youStakeValue.toFixed(2)}
            header={<div className={classes.header}><b>Withdraw</b></div>}
            onChange={onChangeUnStakeValue}
            isPanel
          />
          <div className={classes.buttons}>
            <Button
              type={BUTTON_TYPES.PRIMARY}
              className={classes.button}
              onClick={unStakeDrop}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              type={BUTTON_TYPES.PRIMARY}
              className={classes.button}
              disabled={!unStakeValue}
              loader={loading}
              onClick={handleWithdraw}
            >
              Confirm
            </Button>
          </div>
        </>
      )}
    </Grid>
  );
};

Farm.defaultProps = {
  isPanel: false,
};

export default Farm;

import React from 'react';
import { useWeb3React } from '@web3-react/core';

import InputToken from '../../../../components/InputToken';
import Button from '../../../../components/Button';

import useMintYBF from '../../../../hooks/YBFPoolhooks/useMintYBF';
import useTokenBalance from '../../../../hooks/useTokenBalance';

import { COMMON_BASE_TOKENS } from '../../../../constants/chain.constants';

import { useStyles } from './styles.module';

// eslint-disable-next-line max-len
const DEPOSIT_TOOLTIP_TEXT = 'Due to the complexity of the contracts we reserve a small buffer to account for slippage. This buffer will be given back in BNB at the end of the transaction';

const MintPanelYBF = ({ poolInfo }) => {
    const classes = useStyles();
    const { chainId } = useWeb3React();

    const currentTokenBalance = useTokenBalance(COMMON_BASE_TOKENS[chainId][0].address);

    const {
        inputValue, handleChange, handleMint, isDemandGreaterThanBalance, loader } = useMintYBF({
        indexPool: poolInfo.address,
        currentTokenBalance,
    });

    return (
      <div>
        <div className={classes.inputContainerTop}>
          <InputToken
            poolInfo={poolInfo}
            onChange={handleChange}
            tokenInfo={COMMON_BASE_TOKENS[chainId][0]}
            size="large"
            header={<span><b>Deposit</b></span>}
            value={inputValue}
            showUsdPrice
            tooltip={DEPOSIT_TOOLTIP_TEXT}
            currentToken
          />
        </div>
        {Number(inputValue) > 0
              ? (
                <Button
                  variant="contained"
                  outLine={Number(inputValue) > 0 && isDemandGreaterThanBalance}
                  color="primary"
                  size="large"
                  onClick={handleMint}
                  loader={loader}
                  disabled={!Number(currentTokenBalance) || isDemandGreaterThanBalance}
                  className={classes.mintButton}
                  error={Number(inputValue) > 0 && isDemandGreaterThanBalance}
                >
                  {Number(inputValue) > 0 && isDemandGreaterThanBalance ? 'Insufficient balance' : 'Deposit'}
                </Button>
              )
              : (
                <Button outLine size="large" className={classes.mintButton} disabled>
                  Enter an Amount
                </Button>
              )}

      </div>
    );
};

export default MintPanelYBF;

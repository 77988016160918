/* eslint-disable max-len */
export const PATH_INDEX = '/';
export const PATH_FOUNDS = '/Funds/:address';
export const PATH_YBF = '/ybf/:address';
export const PATH_FARMS = '/farms';

export const YBF = 'ybf';

export const LINK_PATH_HOME = 'https://bishares.finance/';
export const LINK_PATH_ABOUT = 'https://bishares.finance/about-us';
export const LINK_PATH_ARBX = 'https://arbex.bishares.finance/swap';

export const API_GRAPH_EXCHANGE = 'https://api.thegraph.com/subgraphs/name/toffick/bisharesarbex';

export const API_GRAPH_FUND = 'https://api.thegraph.com/subgraphs/name/toffick/bisharesfunds';
export const API_GRAPH_FUND_FANTOM = 'https://api.thegraph.com/subgraphs/name/toffick/bisharesfunds-fantom';

export const API_GRAPH_YBF = 'https://api.thegraph.com/subgraphs/name/bisharesindexfund/bisharesfunds-ybf';
export const API_GRAPH_YBF_FANTOM = 'https://api.thegraph.com/subgraphs/name/bisharesindexfund/bisharesfunds-ybf-fantom';

export const PANCAKESWAP_LP_TRADING_FEES_URL = 'https://raw.githubusercontent.com/pancakeswap/pancake-frontend/develop/src/config/constants/lpAprs.json ';

export const API_URL = 'https://stats.bishares.finance';
export const API_YBF_INFO = `${API_URL}/funds/ybf?chain=`;

import React, {
  createContext, useContext, useState, useEffect,
} from 'react';

import { PANCAKESWAP_LP_TRADING_FEES_URL } from '../constants/routes.constants';
import { delay } from '../utils/utils';

const LpTradingFeesContext = createContext(undefined);

export function LpApysTradingFeesContextProvider({ children }) {
  const [tradingFees, setTradingFees] = useState(0);

  useEffect(() => {
    const update = async () => {
      try {
        const res = await fetch(PANCAKESWAP_LP_TRADING_FEES_URL);
        const tradingFeesRes = await res.json();
        setTradingFees(tradingFeesRes);
      } catch (error) {
        console.log('fething the pancakeswap lp trading fee error', error);
        await delay(5 * 1000); // 5s delay
        update();
      }
    };
    update();
  }, []);

  return (
    <LpTradingFeesContext.Provider value={tradingFees}>
      {children}
    </LpTradingFeesContext.Provider>
  );
}

export const useLpTradingFees = () => useContext(LpTradingFeesContext);

import React from 'react';
import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import { useWeb3React } from '@web3-react/core';
import classNames from 'classnames';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import binanceLogo from '../../assets/logos/binance-logo.svg';
import fantomLogo from '../../assets/logos/fantom-logo.png';

import { BINANCE, FANTOM } from '../../constants/global.constants';
import { PATH_INDEX } from '../../constants/routes.constants';
import { CHAIN_IDS } from '../../constants/chain.constants';

import { useStyles } from './styles.module';

const getNetworkConnectParams = () => ({
  [BINANCE]: [
    {
      chainId: CHAIN_IDS.networkIdHex.bscMainnet,
      chainName: 'Binance Smart Chain Mainnet',
      nativeCurrency: {
        name: 'BNB',
        symbol: 'bnb',
        decimals: 18,
      },
      rpcUrls: ['https://bsc-dataseed1.ninicoin.io', 'https://bsc-dataseed1.defibit.io', 'https://bsc-dataseed.binance.org'],
      blockExplorerUrls: ['https://bscscan.com'],
    },
  ],
  [FANTOM]: [
    {
      chainId: CHAIN_IDS.networkIdHex.fantomMainnet,
      chainName: 'Fantom Opera',
      nativeCurrency: {
        name: 'Fantom Token',
        symbol: 'FTM',
        decimals: 18,
      },
      rpcUrls: ['https://rpc.ftm.tools/'],
      blockExplorerUrls: ['https://ftmscan.com/'],
    },
  ],
});

const ChangeChainButton = ({ withTitle, menu }) => {
  const classes = useStyles();
  const { chainId } = useWeb3React();
  const history = useHistory();
  const matches = useMediaQuery('(max-width:1112px)');
  const handleChangeNetwork = async network => {
    await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: getNetworkConnectParams()[network],
    });
    history.push(PATH_INDEX);
  };
  return (
    <div className={classNames(classes.wrap, { [classes.menu]: menu })}>
      {(withTitle && !matches) && <div className={classes.title}>Select network</div>}
      <button
        className={classNames(classes.button, { [classes.button_active]: chainId === 56 })}
        onClick={() => handleChangeNetwork(BINANCE)}
      >
        <Avatar src={binanceLogo} variant="square" className={classes.logo} />
      </button>
      <button
        className={classNames(classes.button, { [classes.button_active]: chainId === 250 })}
        onClick={() => handleChangeNetwork(FANTOM)}
      >
        <Avatar src={fantomLogo} variant="square" className={classes.logo} />
      </button>
    </div>
  );
};

export default ChangeChainButton;

const getDexName = dexName => {
    switch (dexName) {
        case 'Cake-LP':
            return {
                name: 'by PancakeSwap',
                link: 'https://pancakeswap.finance/farms',
            };
        case 'APE-LP':
            return {
                name: 'by ApeSwap',
                link: 'https://apeswap.finance/farms',
            };
        case 'MDEX LP':
            return {
                name: 'by MDEX',
                link: 'https://mdex.com/#/liquidity?lang=en',
            };
        case 'BSW-LP':
            return {
                name: 'by biswap',
                link: 'https://biswap.org/farms',
            };
        case 'SPIRIT-LP':
            return {
                name: 'by SpiritSwap',
                link: 'https://app.spiritswap.finance/#/farms',
            };
        case 'spLP':
            return {
                name: 'by SpookySwap',
                link: 'https://spookyswap.finance/farms',
            };
        default:
            return '';
    }
};

export default getDexName;

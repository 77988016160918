import { useEffect, useState } from 'react';
import { request } from 'graphql-request';

const useGraphRequest = (url, query) => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    useEffect(() => {
        (async () => {
            const requestData = await request(url, query).then(res => res).catch(err => setError(err));
            setData(requestData);
            setLoading(false);
        })();
    }, [query, url]);

    return { data, loading, error };
};

export default useGraphRequest;

import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { TwitterIcon, GitHub, TelegramIcon, MediumSquareIcon, DiscordIcon } from '../../../constants/icons.constants';
import { PATH_INDEX, PATH_FARMS, LINK_PATH_ARBX } from '../../../constants/routes.constants';

import BiSharesLogo from '../../../assets/logos/BiSharesFooterLogo.png';

import { useStyles } from './styles.module';

const footerLinks = [
  {
    icon: TwitterIcon,
    href: 'https://twitter.com/BiSharesFinance',
  },
  {
    icon: GitHub,
    href: 'https://github.com/bisharesindexfund',
  },
  {
    icon: TelegramIcon,
    href: 'https://t.me/bishares',
  },
  {
    icon: MediumSquareIcon,
    href: 'https://bi-shares.medium.com',
  },
  {
    icon: DiscordIcon,
    href: 'https://discord.com/invite/w2jejq5rBP',
  },
];

const Footer = () => {
  const classes = useStyles();
  const matches = useMediaQuery('(max-width:1175px)');

  return (
    <footer className={classes.footer}>
      <div className={classNames(classes.rowList)}>
        <div className={classes.list}>
          <div>
            <div className={classes.title}>Products</div>
            <Link to={PATH_INDEX}>Funds</Link>
            <Link to={PATH_FARMS}>Farms</Link>
            <a href={LINK_PATH_ARBX}>ArbEx</a>
          </div>
          <div>
            <div className={classes.title}>About</div>
            <a href="https://bishares.finance/about">Team</a>
            <a href="https://bishares.finance/bison-token">BISON Token</a>
          </div>
          <div>
            <div className={classes.title}>Resources</div>
            <a href="https://coinmarketcap.com/currencies/bishares/">CoinMarketCap</a>
            <a href="https://www.coingecko.com/en/coins/bishares">CoinGecko</a>
            <a href="https://charts.bogged.finance/?token=0x19A6Da6e382b85F827088092a3DBe864d9cCba73">Bogged Finance</a>
          </div>
        </div>
        <div>
          <img src={BiSharesLogo} alt="BiSharesLogo" className={classes.logo} />
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.link}>
          {
            footerLinks.map(item => (
              <a href={item.href} key={item.href} target="_blank" rel="noreferrer">{item.icon}</a>
            ))
          }
          { !matches && (
            <div className={classes.link}>
              <a
                href="https://bishares.finance/assets/docs/bishares-gordian-audit.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Gordian Audit
              </a>
              <a
                href="https://bishares.finance/assets/docs/bishares-defiyield-audit.pdf"
                target="_blank"
                rel="noreferrer"
              >
                DefiYield Audit
              </a>
            </div>
          )}
        </div>
        {
          matches && (
          <div className={classes.link}>
            <a
              href="https://bishares.finance/assets/docs/bishares-gordian-audit.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Gordian Audit
            </a>
            <a
              href="https://bishares.finance/assets/docs/bishares-defiyield-audit.pdf"
              target="_blank"
              rel="noreferrer"
            >
              DefiYield Audit
            </a>
          </div>
          )
        }
        <div>
          © 2021 by BiShares Finance
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useState, useMemo } from 'react';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import BigNumber from 'bignumber.js';
import { addresses } from '@project/contracts';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { useWeb3React } from '@web3-react/core';

import Button from '../../../../../components/Button';
import InputToken from '../../../../../components/InputToken';
import TokenListItem from '../../../../../components/TokenListItem';

import { MAX_UINT, isApproved } from '../../../../../utils/utils.js';

import useAllowance from '../../../../../hooks/useAllowance.js';
import useApprove from '../../../../../hooks/useApprove.js';
import useTokenBalance from '../../../../../hooks/useTokenBalance';
import useRouterMint from '../../../../../hooks/IndexPoolhooks/useRouterMint';

import { COMMON_BASE_TOKENS } from '../../../../../constants/chain.constants.js';
import { CURRENT_TOKEN } from '../../../../../constants/global.constants.js';

import { useStyles } from '../styles.module.js';

const RouterMint = ({ poolInfo, inputValue, setInputValue, handleChange, inputError }) => {
  const { chainId } = useWeb3React();
  const classes = useStyles();

  const [mintWithThisToken, setMintWithThisToken] = useState(COMMON_BASE_TOKENS[chainId][0]);
  const [loader, setLoader] = useState(false);
  const [tokenSelectList, setTokenSelectList] = useState(false);
  const [isDemandGreaterThanBalance, setIsDemandGreaterThanBalance] = useState(null);
  const [potentialBnbSwappedAmount, setPotentialBnbSwappedAmount] = useState(new BigNumber(0));

  const currentTokenBalance = useTokenBalance(mintWithThisToken.address);
  const allowance = useAllowance(addresses[chainId][poolInfo.address].uniswapRouterMinter, mintWithThisToken.address);

  const tokenList = useMemo(() => poolInfo.allTokensInfo.filter(item => !poolInfo.tokens.includes(item.address)), [
    poolInfo.allTokensInfo,
    poolInfo.tokens,
  ]);
  const isTokenApproved = useMemo(() => isApproved(allowance), [allowance]);

  const { onApprove } = useApprove(
    addresses[chainId][poolInfo.address].uniswapRouterMinter,
    mintWithThisToken.address,
    setLoader,
  );

  const wrappedHandleChange = value => {
    handleChange({ value, setPotentialBnbSwappedAmount });
  };

  const { handleMint } = useRouterMint({
    indexPool: poolInfo.address,
    mintWithThisToken,
    indexPoolTokens: poolInfo.tokens,
    inputValue,
    wrappedHandleChange,
    setPotentialBnbSwappedAmount,
    setIsDemandGreaterThanBalance,
    setLoader,
  });

  const handleMintWithToken = token => {
    setMintWithThisToken(token);
    setInputValue('');
    setPotentialBnbSwappedAmount(new BigNumber(0));
    setTokenSelectList(false);
  };
  const onApproveHandler = async () => {
    setLoader(true);
    await onApprove();
  };

  return (
    <div>
      <div className={classes.description}>
        Use any single asset listed to mint the {poolInfo.symbol} token in one transaction.
      </div>
      <div className={classes.container}>
        {!tokenSelectList ? (
          <>
            <div className={classes.inputContainerTop}>
              <InputToken
                onChange={wrappedHandleChange}
                tokenInfo={{
                  symbol: poolInfo.symbol,
                  address: poolInfo.address,
                }}
                size="large"
                error={inputError}
                value={inputValue.toString()}
                gradient
                header={<div>Tokens to<b> mint</b>{' '}</div>}
              />
            </div>
            <div className={classes.arrowContainer}>
              <ArrowUpwardIcon fontSize="large" />
            </div>
            <InputToken
              poolInfo={poolInfo}
              tokenInfo={mintWithThisToken}
              size="large"
              disabled
              valueWei
              error={isDemandGreaterThanBalance}
              header={<span><b>Mint</b> with</span>}
              value={potentialBnbSwappedAmount.toFixed(0)}
              tokenSelectList={tokenSelectList}
              setTokenSelectList={setTokenSelectList}
              showUsdPrice
            />
          </>
        ) : (
          <div>
            <Button className={classes.backButton}>
              <NavigateBeforeIcon onClick={() => setTokenSelectList(false)} />
            </Button>
            <div className={classes.selectContainer}>
              {tokenList.map(token => (
                <TokenListItem
                  token={token}
                  key={token.address}
                  poolInfo={poolInfo}
                  handleMintWithToken={handleMintWithToken}
                />
              ))}
            </div>
          </div>
        )}
      </div>
      {Number(inputValue) > 0 ? (
        isTokenApproved || CURRENT_TOKEN.includes(mintWithThisToken.symbol) ? (
          <Button
            variant="contained"
            outLine={Number(inputValue) > 0 && isDemandGreaterThanBalance}
            color="primary"
            size="large"
            onClick={handleMint}
            loader={loader}
            disabled={!Number(currentTokenBalance) || isDemandGreaterThanBalance}
            className={classes.mintButton}
            error={Number(inputValue) > 0 && isDemandGreaterThanBalance}
          >
            {Number(inputValue) > 0 && isDemandGreaterThanBalance ? 'Insufficient balance' : `Mint ${poolInfo.symbol}`}
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            size="large"
            loader={loader}
            className={classes.mintButton}
            onClick={onApproveHandler}
          >
            Approve {mintWithThisToken.symbol}
          </Button>
        )
      ) : (
        <Button outLine size="large" className={classes.mintButton} disabled>
          Enter an Amount
        </Button>
      )}
    </div>
  );
};

export default RouterMint;

import React, { useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';

import Button from '../Button';

import ConnectWalletModal from '../../containers/modal/ConnectWalletModal';
import ChangeChainButton from '../ChangeChainButton';

import { ZapOffIcon, LogIn, AlertTriangle } from '../../constants/icons.constants';
import { CURRENT_CHAIN_ID } from '../../constants/global.constants';

import { useStyles } from './styles.module';

const ConnectWallet = () => {
  const classes = useStyles();
  const { chainId, account } = useWeb3React();
  const [isConnectModal, setConnectModal] = useState(false);

  useEffect(() => account && setConnectModal(false), [account]);

  return (
    <div className={classes.wrap}>
      {
        (!!account && !CURRENT_CHAIN_ID.includes(chainId))
          ? (
            <>
              <div>{AlertTriangle}</div>
              <div className={classes.text}>WRONG NETWORK DETECTED</div>
              <div className={classes.description}>Please connect to either BSC or Fantom</div>
              <ChangeChainButton />
            </>
          )
          : (
            <>
              <div>{ZapOffIcon}</div>
              <div className={classes.text}>Connect a Wallet to ACCESS the DASHBOARD</div>
              <Button className={classes.button} onClick={() => setConnectModal(true)} outLine>{LogIn}Connect Wallet</Button>
            </>
          )
      }
      {isConnectModal && <ConnectWalletModal onClose={() => setConnectModal(false)} />}
    </div>
  );
};

export default ConnectWallet;

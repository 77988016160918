import { BINANCE_ID, FANTOM_ID } from './global.constants';
import busd from '../assets/coins/busd.svg';
import bnb from '../assets/coins/bnb.svg';
import cake from '../assets/coins/cake.png';
import ftm from '../assets/coins/FANTOM.png';
import usdc from '../assets/coins/usdc.svg';
import spirit from '../assets/coins/spirit.png';

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

export const CHAIN_IDS = {
  networkId: {
    bscMainnet: 56,
    bscTestnet: 97,
    fantomMainnet: 250,
    fantomTestnet: 4002,
  },
  networkIdHex: {
    bscMainnet: '0x38',
    bscTestnet: '0x61',
    fantomMainnet: '0xfa',
    fantomTestnet: '0xfa2',
  },
  networkType: {
    binance: 'binance',
    fantom: 'fantom',
  },
};

export const COMMON_BASE_TOKENS = {
  [BINANCE_ID]: [
    {
      address: '0x0000000000000000000000000000000000000000',
      symbol: 'BNB',
      name: 'Binance Coin',
      priceId: 'binancecoin',
      decimals: 18,
      img: bnb,
    },
    {
      address: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
      symbol: 'BUSD',
      name: 'BUSD Token',
      priceId: 'binance-usd',
      decimals: 18,
      img: busd,
    },
    {
      address: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
      symbol: 'Cake',
      name: 'PancakeSwap Token',
      priceId: 'pancakeswap-token',
      decimals: 18,
      img: cake,
    },
  ],
  [FANTOM_ID]: [
    {
      address: '0x0000000000000000000000000000000000000000',
      symbol: 'FTM',
      name: 'Fantom',
      priceId: 'fantom',
      decimals: 18,
      img: ftm,
    },
    {
      address: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
      symbol: 'USDC',
      name: 'USD Coin',
      priceId: 'usd-coin',
      decimals: 18,
      img: usdc,
    },
    {
      address: '0x5cc61a78f164885776aa610fb0fe1257df78e59b',
      symbol: 'SPIRIT',
      name: 'SpiritSwap Token',
      priceId: 'spiritswap',
      decimals: 18,
      img: spirit,
    },
    {
      address: '0xd67de0e0a0fd7b15dc8348bb9be742f3c5850454',
      symbol: 'BNB',
      name: 'Binance',
      priceId: 'binancecoin',
      decimals: 18,
      img: bnb,
    },
  ],
};

export const SECONDS_PER_BLOCK = {
  [CHAIN_IDS.networkId.bscMainnet]: 3,
  [CHAIN_IDS.networkId.bscTestnet]: 3,
  [CHAIN_IDS.networkId.fantomMainnet]: 0.8,
  [CHAIN_IDS.networkId.fantomTestnet]: 0.8,
};

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  chartWrap: {
    border: '1px solid #DAA10E',
    borderRadius: '30px',
    padding: '24px 24px',
  },
  buttonWrap: {
    marginBottom: '25px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  chartButton: {
    background: 'rgba(218, 161, 14, 0.25)',
    border: 'none',
    fontSize: '16px',
    fontWeight: 'bold',
    padding: '4px 16px',
    color: '#FFFFFF',
    cursor: 'pointer',
    borderRadius: '8px',
    marginLeft: '4px',
  },
  activeButton: {
    background: '#DAA10E',
  },
  title: {
    fontSize: '20px',
    marginRight: '16px',
  },
  goldColor: {
    color: '#DAA10E',
  },
  row: {
    display: 'flex',
  },
  buttonIdent: {
    marginRight: '24px',
  },
}));

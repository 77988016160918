import React, { useState, useMemo } from 'react';
import { Grid, Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useWeb3React } from '@web3-react/core';
import BigNumber from 'bignumber.js';

import Card from '../../../../components/Card';

import { copyTextToClipboard } from '../../../../helpers/functions.helper';

import useTokenBalance from '../../../../hooks/useTokenBalance';

import { ArrowDownRed, ArrowUpGreen, ArrowUpIcon, CopyIcon } from '../../../../constants/icons.constants';
import { BINANCE_ID, BSC_SCAN_URL, FTM_SCAN_URL } from '../../../../constants/global.constants';

import { formatter, getTokenAvatar } from '../../../../utils/utils';

import { useStyles } from './styles.module';

const PoolHeader = ({ poolInfo }) => {
    const { chainId, library } = useWeb3React();
    const classes = useStyles();
    const [isCopied, setIsCopied] = useState(false);
    const matches = useMediaQuery('(max-width:960px)');
    const currentPoolAvatar = getTokenAvatar(poolInfo.address, chainId);

    const { name, totalSupply, tvl, address, generalApy, dailySnapshots } = poolInfo;

    const currentTokenBalance = useTokenBalance(poolInfo.address);

    const percentTvl = useMemo(() => (
        dailySnapshots.length
        && (dailySnapshots[dailySnapshots.length - 1].totalValueLockedUSD / dailySnapshots[0].totalValueLockedUSD - 1) * 100
    ), [dailySnapshots]);

    const balance = useMemo(() => (
        currentTokenBalance && library.utils.fromWei(currentTokenBalance?.toFixed(0), 'ether')
    ), [currentTokenBalance, library]);

    const deposited = useMemo(() => (
        tvl.div(totalSupply.div(1e18)).multipliedBy(new BigNumber(balance)).toFixed(3)
    ), [tvl, totalSupply, balance]);

    const daily = useMemo(() => (
        generalApy.multipliedBy(tvl.div(totalSupply.div(1e18)).multipliedBy(new BigNumber(balance))).div(356).div(100)
    ), [generalApy, tvl, totalSupply, balance]);

    return (
      <Grid item container justifyContent="space-between" className={classes.container} alignItems="stretch">
        <Grid
          item
          component={Card}
          xs={matches ? 12 : false}
          lg={3}
          md={3}
          sm={12}
          className={classNames(classes.card, { [classes.mobileCard]: matches })}
        >
          <img
            src={currentPoolAvatar}
            alt="bit10"
            className={classes.avatar}
          />
          <div className={classNames(classes.text, classes.withImg)}>
            <div className={classes.bsc}>
              <div className={classes.title}>{name}</div>
              <Tooltip title={isCopied ? 'Copied!' : 'Copy address to clipboard'} placement="top">
                <div
                  className={classes.icon}
                  onPointerLeave={() => setTimeout(() => setIsCopied(false), 500)}
                  onClick={() => { copyTextToClipboard(address); setIsCopied(true); }}
                >
                  {CopyIcon}
                </div>
              </Tooltip>
              <a
                className={classes.icon}
                href={`${ chainId === BINANCE_ID ? BSC_SCAN_URL : FTM_SCAN_URL}${address}`}
                target="_blank"
                rel="noreferrer"
              >
                {ArrowUpIcon}
              </a>
            </div>
          </div>
        </Grid>
        <Grid
          item
          component={Card}
          xs={matches ? 12 : false}
          lg={3}
          md={3}
          sm={12}
          className={classNames(classes.card, { [classes.mobileCard]: matches })}
        >
          <div className={classes.text}>
            <div className={classes.titleTVL}>Total Value Locked</div>
            <div className={classes.priceTVL}>
              <div>$ {formatter.format(tvl.toFixed(0))}</div>
              {
                percentTvl !== 0
                && (
                <div className={classes.percent}>
                  {percentTvl > 0 ? '+' : ''}
                  {formatter.format(percentTvl.toFixed(2))}%
                  {percentTvl > 0 ? ArrowUpGreen : ArrowDownRed}
                </div>)
                }
            </div>
          </div>
        </Grid>
        <Grid
          item
          component={Card}
          xs={matches ? 12 : false}
          lg={3}
          md={3}
          sm={12}
          className={classNames(classes.cardDeposited, { [classes.mobileCard]: matches })}
        >
          <div className={classes.flexContainer}>
            <div className={classes.titleTVL}>Deposited</div>
            <div className={classes.priceTVL}>
              <div>$ {formatter.format(deposited)}</div>
            </div>
          </div>
          {
              !!generalApy.toNumber() && (
              <div className={classes.flexContainer}>
                <div className={classes.titleTVL}>APY</div>
                <div className={classes.priceTVL}>
                  <div>{formatter.format(generalApy.toFixed(1))}%
                    (daily <span className={classes.green}>+${daily.toFixed(2)}</span>)
                  </div>
                </div>
              </div>
              )
          }

        </Grid>
      </Grid>
    );
};
export default PoolHeader;

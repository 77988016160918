import React, {
  createContext, useContext, useState, useEffect,
} from 'react';

const BnbPriceContext = createContext(undefined);
const updatedIntervalSec = 30 * 1000;

export const CG_TOKEN_IDS = {
  FANTOM: 'fantom',
  BNB: 'binancecoin',
  USDC: 'usd-coin',
  USDT: 'tether',
  BISON: 'bishares',
  BANANA: 'apeswap-finance',
  CAKE: 'pancakeswap-token',
  SPIRIT: 'spiritswap',
  BUSD: 'binance-usd',
  MDX: 'mdex',
  BSW: 'biswap',
  OASIS: 'oasis',
  STARS: 'mogul-productions',
  NFTY: 'nfty',
  CEEK: 'ceek',
};

export function BnbPriceContextProvider({ children }) {
  const [prices, setPrices] = useState(0);

  useEffect(() => {
    // eslint-disable-next-line max-len
    const bisonUrl = 'https://bsc.api.0x.org/swap/v1/quote?buyToken=BUSD&sellToken=0x19A6Da6e382b85F827088092a3DBe864d9cCba73&sellAmount=1000000000000000000&excludedSources=BakerySwap,Belt,DODO,DODO_V2,Ellipsis,Mooniswap,MultiHop,Nerve,SushiSwap,Smoothy,CafeSwap,CheeseSwap,JulSwap,LiquidityProvider&slippagePercentage=0&gasPrice=0';
    const coinGeckoUrl = `https://api.coingecko.com/api/v3/simple/price?ids=${Object.values(CG_TOKEN_IDS).join(',')}&vs_currencies=usd`;
    // eslint-disable-next-line max-len
    const NftyUrl = 'https://bsc.api.0x.org/swap/v1/quote?buyToken=BUSD&sellToken=0x5774b2fc3e91af89f89141eacf76545e74265982&sellAmount=1000000000000000000&excludedSources=BakerySwap,Belt,DODO,DODO_V2,Ellipsis,Mooniswap,MultiHop,Nerve,SushiSwap,Smoothy,CafeSwap,CheeseSwap,JulSwap,LiquidityProvider&slippagePercentage=0&gasPrice=0';
    // eslint-disable-next-line max-len
    const CeekUrl = 'https://bsc.api.0x.org/swap/v1/quote?buyToken=BUSD&sellToken=0xe0f94ac5462997d2bc57287ac3a3ae4c31345d66&sellAmount=1000000000000000000&excludedSources=BakerySwap,Belt,DODO,DODO_V2,Ellipsis,Mooniswap,MultiHop,Nerve,SushiSwap,Smoothy,CafeSwap,CheeseSwap,JulSwap,LiquidityProvider&slippagePercentage=0&gasPrice=0';
    // eslint-disable-next-line max-len
    const OasisUrl = 'https://bsc.api.0x.org/swap/v1/quote?buyToken=BUSD&sellToken=0xb19289b436b2f7a92891ac391d8f52580d3087e4&sellAmount=1000000000000000000&excludedSources=BakerySwap,Belt,DODO,DODO_V2,Ellipsis,Mooniswap,MultiHop,Nerve,SushiSwap,Smoothy,CafeSwap,CheeseSwap,JulSwap,LiquidityProvider&slippagePercentage=0&gasPrice=0';
    const urls = [coinGeckoUrl, bisonUrl, CeekUrl, NftyUrl, OasisUrl];
    const updatePrice = () => Promise.all(urls.map(url => fetch(url)))
        .then(res => Promise.all(res.map(item => item.json())))
        .then(res => {
          setPrices({
            ...res[0],
            bishares: { usd: Number(res[1].price).toFixed(2) },
            ceek: { usd: Number(res[2].price).toFixed(2) },
            nfty: { usd: Number(res[3].price).toFixed(2) },
            oasis: { usd: Number(res[4].price).toFixed(2) },
          });
        });
    updatePrice();
    try {
      setInterval(() => {
        updatePrice();
      }, updatedIntervalSec);
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <BnbPriceContext.Provider value={prices}>
      {children}
    </BnbPriceContext.Provider>
  );
}

export const useBnbPriceState = () => useContext(BnbPriceContext);

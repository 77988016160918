import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    wrap: {
        border: '1px solid #DAA10E',
        borderRadius: '100px',
        padding: '8px 20px',
        marginRight: '24px',
        position: 'relative',
        boxSizing: 'border-box',
        '@media (max-width: 1112px)': {
            padding: '12px 16px',
        },
    },

    menu: {
        padding: '0',
    },
    button: {
        borderRadius: '100px',
        background: 'rgba(218, 161, 14, 0.25)',
        padding: '1px 24px',
        margin: '0 4px',
        cursor: 'pointer',
        boxSizing: 'border-box',
        border: '1px solid #E0AA3A',

        '&:hover': {
            background: 'rgba(218, 161, 14, 0.45)',
        },

        '@media (max-width: 1112px)': {
            padding: '2px 7px',
        },
    },
    logo: {
        background: '#0B0E11',
        borderRadius: '50%',
        height: '16px',
        padding: '3px',
        width: '16px',
    },
    button_active: {
        background: '#DAA10E',
        '@media (max-width: 1112px)': {
            background: '#0B0E11',
        },
    },
    title: {
        background: 'linear-gradient(90deg, #211F19 0%, #2A2518 100%)',
        color: '#DAA10E',
        position: 'absolute',
        top: '-11px',
        padding: '0 3px',
    },
}));

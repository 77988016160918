import React, { Fragment } from 'react';
import { useWeb3React } from '@web3-react/core';

import PoolCard from './PoolCard';
import PoolCardYBF from './PoolCardYBF';
import Loader from '../../components/Loader';

import useDashboardInfo from '../../hooks/useDashboardInfo';

import { FUNDS_TYPE } from '../../constants/global.constants';

import { useStyles } from './styles.module';

const Dashboard = () => {
  const classes = useStyles();

  const { dashboardInfo, loader } = useDashboardInfo();

  if (loader) return <Loader />;

  return (
    <div className={classes.container}>
      <h1>BiShares Funds</h1>
      <div
        className={classes.content}
      >
        {dashboardInfo.map(pool => (
          <Fragment key={pool.symbol}>
            {pool.type === FUNDS_TYPE.YBF_FUNDS && <PoolCardYBF poolInfo={pool} />}
            {pool.type === FUNDS_TYPE.INDEX_FUNDS && <PoolCard poolInfo={pool} />}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default Dashboard;

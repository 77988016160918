import React, { useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Button, Avatar } from '@material-ui/core';
import BigNumber from 'bignumber.js';
import useTokenBalance from '../../hooks/useTokenBalance.js';
import { formatter } from '../../utils/utils.js';
import { useBnbPriceState } from '../../state/bnbPriceContext.js';
import usePoolTokenInfo from '../../hooks/usePoolTokenInfo.js';

import useStyles from './styles.module';

const TokenListItem = ({ poolInfo, token, handleMintWithToken }) => {
  const classes = useStyles();
  let poolTokenInfo;
  if (poolInfo?.tokens?.find(item => item.toLowerCase() === token.address.toLowerCase())) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    poolTokenInfo = usePoolTokenInfo(poolInfo.address, token.address);
  }

  const { library } = useWeb3React();
  const balance = useTokenBalance(token.address);
  const priceState = useBnbPriceState();
  const usdPrice = poolTokenInfo?.averagePrice ? poolTokenInfo.averagePrice : priceState?.[token.priceId]?.usd;

  const [usdValue, setUsdValue] = useState(0);

  useEffect(() => {
    if (balance && usdPrice) {
      const tokenUsdValue = new BigNumber(usdPrice)
        .times(balance)
        .div(1e18)
        .toNumber();
      setUsdValue(tokenUsdValue);
    }
  }, [poolInfo, token, usdPrice, balance]);

  return (
    <Button size="large" className={classes.selectItems} onClick={() => handleMintWithToken(token)}>
      <div className={classes.tokenButtonLogoGroup}>
        <Avatar className={classes.tokenButtonLabel}>
          <img
            src={token.img}
            className={classes.tokenButtonImg}
            alt={token.symbol}
          />
        </Avatar>
        {token.symbol}
      </div>
      <div>
        <div>{balance ? formatter.format(library.utils.fromWei(balance.toFixed(0), 'ether')) : '0'}</div>
        <div className={classes.usdBalance}>${formatter.format(usdValue)}</div>
      </div>
    </Button>
  );
};

export default TokenListItem;

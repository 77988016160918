import React, { useState } from 'react';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { useWeb3React } from '@web3-react/core';

import BigNumber from 'bignumber.js';
import InputToken from '../../../../components/InputToken';
import PercentInput from '../../../../components/PercentInput';
import Button from '../../../../components/Button';
import TokenListItem from '../../../../components/TokenListItem';

import useBurnYBF from '../../../../hooks/YBFPoolhooks/useBurnYBF';

import { COMMON_BASE_TOKENS } from '../../../../constants/chain.constants';

import { useStyles } from './styles.module';

const BurnPanelYBF = ({ poolInfo }) => {
    const classes = useStyles();
    const { chainId } = useWeb3React();
    const [tokenSelectList, setTokenSelectList] = useState(false);
    const [burnWithThisToken, setBurnWithThisToken] = useState(COMMON_BASE_TOKENS[chainId][0]);

    const { handleBurn, inputValue, handleChange, inputError, loader, potentialBnbSwappedAmount,
        setPotentialBnbSwappedAmount, setInputValue, isTokenApproved, onApproveHandler, deposited } = useBurnYBF({
        indexPool: poolInfo.address,
        burnWithThisToken,
        tvl: poolInfo.tvl,
        totalSupply: poolInfo.totalSupply,
    });

    const handleMintWithToken = token => {
        setBurnWithThisToken(token);
        setInputValue('');
        setPotentialBnbSwappedAmount(new BigNumber(0));
        setTokenSelectList(false);
    };

    return (
      <div>
        <div className={classes.description}>
          Withdraw your funds to BNB or BUSD in a single transaction
        </div>
        <div className={classes.container}>
          {!tokenSelectList ? (
            <>
              <div className={classes.inputContainerTop}>
                <PercentInput
                  balance={deposited}
                  tokenInfo={{ symbol: poolInfo.symbol, address: poolInfo.address }}
                  header={<div><b>Withdraw</b></div>}
                  onChange={handleChange}
                  balanceInUsd
                />
              </div>
              <div className={classes.arrowContainer}>
                <ArrowUpwardIcon fontSize="large" />
              </div>
              <InputToken
                tokenInfo={burnWithThisToken}
                size="large"
                disabled
                valueWei
                header={<div><b>Redeem</b></div>}
                value={potentialBnbSwappedAmount.toString()}
                tokenSelectList={tokenSelectList}
                setTokenSelectList={setTokenSelectList}
                showUsdPrice
              />
            </>
              ) : (
                <div>
                  <Button className={classes.backButton}>
                    <NavigateBeforeIcon onClick={() => setTokenSelectList(false)} />
                  </Button>
                  <div className={classes.selectContainer}>
                    {poolInfo.mintTokenList.map(token => (
                      <TokenListItem
                        token={token}
                        key={token.address}
                        poolInfo={poolInfo}
                        handleMintWithToken={handleMintWithToken}
                      />
                    ))}
                  </div>
                </div>
              )}
        </div>
        {
              isTokenApproved ? (
                <>
                  {
                      Number(inputValue) > 0 ? (
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          loader={loader}
                          error={inputError}
                          onClick={handleBurn}
                          className={classes.mintButton}
                        >
                          {inputError || 'Withdraw'}
                        </Button>
                      ) : (
                        <Button outLine error={inputError} size="large" className={classes.mintButton} disabled>
                          {inputError || 'Enter an Amount'}
                        </Button>
                      )
                  }
                </>
              )
              : (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  loader={loader}
                  className={classes.mintButton}
                  onClick={onApproveHandler}
                >
                  Approve {poolInfo.symbol}
                </Button>
              )
          }
      </div>
    );
};

export default BurnPanelYBF;

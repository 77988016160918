import React from 'react';
import Button from '@material-ui/core/Button';
import { useWeb3React } from '@web3-react/core';

import ModalWrap from '../../../components/ModalWrap';

import { useAuthMethod } from '../../../hooks/useAuth';

import { Wallet } from '../../../constants/icons.constants';
import { BSC_SCAN_URL, FTM_SCAN_URL, BINANCE_ID, FANTOM_ID } from '../../../constants/global.constants';

import { useStyles } from './styles.module';

const LogOutModal = ({ title, onClose, account }) => {
  const classes = useStyles();
  const { chainId } = useWeb3React();
  const { logOut } = useAuthMethod();

  return (
    <ModalWrap
      iconTitle={Wallet}
      title={title}
      onClose={onClose}
    >
      <div className={classes.wrap}>
        {
          chainId === BINANCE_ID && (
            <Button className={classes.button} href={`${BSC_SCAN_URL}${account}`} target="_blank">
              View on BSCScan
            </Button>
          )
        }
        {
          chainId === FANTOM_ID && (
          <Button className={classes.button} href={`${FTM_SCAN_URL}${account}`} target="_blank">
            View on FTMScan
          </Button>
          )
        }
        <Button className={classes.button} onClick={() => { logOut(); onClose(); }}>Log Out</Button>
      </div>
    </ModalWrap>
  );
};

export default LogOutModal;

import React from 'react';
import { Grid } from '@material-ui/core';

import PoolHeader from './PoolHeader';
import PoolContent from './PoolContent';

const InfoCardYBF = ({ poolInfo }) => (
  <Grid item lg={8}>
    <PoolHeader poolInfo={poolInfo} />
    <PoolContent poolInfo={poolInfo} />
  </Grid>
);
export default InfoCardYBF;

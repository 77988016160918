import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { useWeb3React } from '@web3-react/core';

import Card from '../../../components/Card';
import Button from '../../../components/ButtonUI';

import { getTokenAvatar, formatBNWithCommas } from '../../../utils/utils';
import { useStyles } from './styles.module';

const PoolCard = ({ poolInfo }) => {
  const classes = useStyles();
  const history = useHistory();
  const { chainId } = useWeb3React();
  const { name, symbol, tokens, address, price } = poolInfo;

  const currentPoolAvatar = getTokenAvatar(address, chainId);

  /* const percentPrice = useMemo(() => (
      poolInfo?.dailySnapshots?.length
      && (poolInfo?.dailySnapshots[poolInfo?.dailySnapshots.length - 1].value / poolInfo?.dailySnapshots[0].value - 1) * 100
  ), [poolInfo?.dailySnapshots]); */

  const otherToken = useMemo(() => (
      tokens?.map(item => <Avatar src={item.img} key={item.symbol} />)
  ), [tokens]);

  return (
    <Grid item>
      <Card className={classes.card}>
        <div className={classes.row}>
          <Avatar
            src={currentPoolAvatar}
            className={classes.avatar}
          />
          <div className={classes.titleName}>{name}</div>
          <AvatarGroup max={5} className={classes.avatarGroup}>
            {otherToken}
          </AvatarGroup>
          <div className={classes.statistic}>
            <div className={classes.price}>
              ${formatBNWithCommas(price, 2)}
            </div>
            {/* {
            !!percentPrice && (
              <div className={classNames(classes.percent, { [classes.percentRed]: percentPrice < 0 })}>
                {percentPrice > 0 ? '+' : ''}{percentPrice.toFixed(2)}%
              </div>
            )
          } */}
          </div>
        </div>

        <Button onClick={() => history.push(`/funds/${address}`)} className={classes.button}>
          {symbol} Dashboard
        </Button>
      </Card>
    </Grid>
  );
};

export default PoolCard;

import React, { useState } from 'react';

import Farm from '../../../../components/Farm';
import Loader from '../../../../components/Loader';
import Button from '../../../../components/ButtonUI';

import useFarmsInfo from '../../../../hooks/FarmsHooks/useFarmsInfo';

import { BUTTON_TYPES } from '../../../../constants/style.constants';
import { useStyles } from './styles.module';

const FarmsPanel = ({ farm }) => {
    const classes = useStyles();
    const farmsInfo = useFarmsInfo(farm);
    const [page, setPage] = useState(0);

    const nextPage = () => {
        if (page === farmsInfo.length - 1) setPage(0);
        else setPage(prevState => prevState + 1);

    };

    const pervPage = () => {
        if (page === 0) setPage(farmsInfo.length - 1);
        else setPage(prevState => prevState - 1);
    };

    return (
      <div>
        {
          farmsInfo.length > 1 && (
          <div className={classes.buttonGroup}>
            <Button onClick={pervPage} className={classes.button} type={BUTTON_TYPES.SECONDARY}>previous farm</Button>
            <Button onClick={nextPage} className={classes.button} type={BUTTON_TYPES.SECONDARY}>next farm</Button>
          </div>
          )
        }

        {
            farmsInfo.length
                ? (<Farm farmInfo={farmsInfo[page]} key={farmsInfo[page].farmAddress} isPanel />)
                : (<Loader />)
        }
      </div>
    );
};

export default FarmsPanel;

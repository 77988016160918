import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  wrap: {
    background: '#1D1F20',
    borderRadius: '30px',
    padding: '28px 24px',
    color: '#9F9F9F',
    fontWeight: '400',
    maxHeight: '566px',
  },
  dropWrap: {
    maxHeight: '100%',
  },
  row: {
    display: 'flex',
  },
  titleRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  panelTitle: {
    display: 'block',
  },
  margin: {
    marginTop: '10px',
  },
  timer: {
    color: '#DAA10E',
    fontWeight: '700',
    fontSize: '24px',
  },
  listRow: {
    display: 'flex',
    justifyContent: 'space-between',
    '&:not(:first-child)': {
      marginTop: '8px',
    },
  },
  farmName: {
    fontWeight: '700',
    fontSize: '16px',
    color: '#FFFFFF',
    marginTop: '16px',
    marginBottom: '4px',
  },
  earnedValue: {
    fontSize: '24px',
    fontWeight: '700',
    color: '#FFFFFF',
  },
  earnedToken: {
    fontSize: '14px',
    marginTop: '4px',
  },
  approveButton: {
    width: '100%',
    margin: '16px 0',
    '& button': {
      width: '100%',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    width: '100%',
    margin: '16px 0',
    '&:not(:first-child)': {
      marginLeft: '16px',
    },
    '& button': {
      width: '100%',
    },
  },
  infoWrap: {
    background: '#191919',
    borderRadius: '22px',
    padding: '16px',
    fontSize: '18px',
    marginBottom: '11px',
  },
  textWhite: {
    color: '#ffffff',
  },
  rewardToken: {
    display: 'flex',
    alignItems: 'center',
    '& span': {
      color: '#ffffff',
      fontSize: '18px',
      marginLeft: '8px',
    },
  },
  rewardAvatar: {
    height: '24px',
    width: '24px',
  },
  harvestButton: {
    marginLeft: '15px',
  },
  linkLP: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& a': {
      color: '#ffffff',
      textDecoration: 'none',
      '&:hover': {
        color: '#DAA10E',
      },
    },
  },
  input: {
    marginTop: '25px',
  },
  header: {
    color: '#ffffff',
  },
}));

import React from 'react';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';

import { BUTTON_SIZES, BUTTON_TYPES } from '../../constants/style.constants';

import { useStyles } from './styles.module';

const Button = ({ className, disabled, children, size, type, onClick, loader, error }) => {
    const classes = useStyles();
    return (
      <div className={className}>
        <button
          disabled={disabled || loader || error}
          onClick={onClick}
          className={classNames(classes.button, {
              [classes[size]]: size,
              [classes[type]]: type,
              [classes.loaderWrap]: loader,
              [classes.error]: error,
          })}
        >
          <div className={classes.text}>
            {loader && <CircularProgress className={classes.loader} />}
            <div>{error || children}</div>
          </div>
        </button>
      </div>
    );
};

Button.defaultProps = {
    children: '',
    className: '',
    disabled: false,
    size: BUTTON_SIZES.L,
    type: BUTTON_TYPES.PRIMARY,
    loader: false,
    error: '',
};

export default Button;

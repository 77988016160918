import { useMemo, useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import BigNumber from 'bignumber.js';
import useSWR from 'swr';

import useGraphRequest from './useGraphRequest';

import { getGraphUrl, getGraphYbfUrl, getTokenAvatar } from '../utils/utils';

import funds from '../config/funds.json';
import { FUNDS_TYPE, CHAIN } from '../constants/global.constants';
import { API_YBF_INFO } from '../constants/routes.constants';
import { getYbfDashboard, getIndexDashboard } from '../constants/graph.constants';

const useDashboardInfo = () => {
    const { chainId } = useWeb3React();
    const [loader, setLoader] = useState(true);
    const [dashboardInfo, setDashboardInfo] = useState([]);

    const ybfFunds = useMemo(() => (
        chainId && funds[chainId].reduce((acc, item) => (
            item.fundType === FUNDS_TYPE.YBF_FUNDS ? [...acc, item.contractAddress] : acc
        ), [])
    ), [chainId]);

    const indexFunds = useMemo(() => (
        chainId && funds[chainId].reduce((acc, item) => (
            item.fundType === FUNDS_TYPE.INDEX_FUNDS ? [...acc, item.contractAddress] : acc
        ), [])
    ), [chainId]);

    const { data: ybfData } = useGraphRequest(getGraphYbfUrl(chainId), getYbfDashboard(ybfFunds));
    const { data: indexData } = useGraphRequest(getGraphUrl(chainId), getIndexDashboard(indexFunds));
    const { data: ybfInfoApy } = useSWR(`${API_YBF_INFO}${CHAIN[chainId]}`);

    useEffect(() => {
        if (ybfData?.indexPools && indexData?.indexPools) {
            const ybfInfo = ybfData?.indexPools.map(item => ({
                name: item?.name,
                symbol: item?.symbol,
                address: item?.id,
                type: FUNDS_TYPE.YBF_FUNDS,
                dexName: [...new Set(item.tokens.map(token => token.token.strategy.lpToken.symbol))],
                apy: ybfInfoApy?.find(fund => fund.id === item?.id)?.averageApy,
            }));
            const indexInfo = indexData?.indexPools.map(item => ({
                name: item?.name,
                symbol: item?.symbol,
                address: item?.id,
                type: FUNDS_TYPE.INDEX_FUNDS,
                tokens: item.tokens.map(token => ({ ...token.token, img: getTokenAvatar(token.token.id, chainId) })),
                price: new BigNumber(item.totalValueLockedUSD).div(new BigNumber(item.totalSupply).div(1e18)),

            }));
            setDashboardInfo([...ybfInfo, ...indexInfo]);
            setLoader(false);
        }
    }, [ybfData, indexData, chainId]);

    return { dashboardInfo, loader };
};
export default useDashboardInfo;

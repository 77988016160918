import BigNumber from 'bignumber.js';

export const BSC_SCAN_URL = 'https://bscscan.com/address/';
export const FTM_SCAN_URL = 'https://ftmscan.com/address/';

export const BIT_10_SYMBOL = 'biT10';
export const MAX_UINT256 = '115792089237316195423570985008687907853269984665640564039457584007913129639935';
export const ADDRESS_ZERO = '0x0000000000000000000000000000000000000000';
export const AR_BEX_URL = 'https://arbex.bishares.finance/swap';

export const BNB = 'BNB';
export const WBNB = 'WBNB';
export const FTM = 'FTM';

export const CURRENT_TOKEN = [BNB, FTM];

export const GAS_MULTIPLYER = 1.1; // 110%
export const MIN_MAX_CHANGE_VALUE = 0.03; // 3%

export const LAST_DAY = Date.now() / 1e3 - 86400;
export const LAST_TWO_DAYS = Date.now() / 1e3 - 172800;
export const LAST_WEEK = Date.now() / 1e3 - 604800;

export const BINANCE = 'binance';
export const FANTOM = 'fantom';

export const BINANCE_ID = 56;
export const BINANCE_TESTNET_ID = 97;
export const FANTOM_ID = 250;

export const CURRENT_CHAIN_ID = [BINANCE_ID, FANTOM_ID];

export const MULTI_CALLS = {
    [BINANCE_ID]: '0xB94858b0bB5437498F5453A16039337e5Fdc269C',
    [BINANCE_TESTNET_ID]: '0x17d40e2a60b1887e262ae2c34545ff59df8da66e',
    [FANTOM_ID]: '0xC9F6b1B53E056fd04bE5a197ce4B2423d456B982',
};

export const FUNDS_TYPE = {
    YBF_FUNDS: 'ybf',
    INDEX_FUNDS: 'index',
};

export const STAKED_TOKEN_TYPE = {
    LP: 'lp',
    SINGLE: 'single',
    YBF: 'ybf',
};

export const CHAIN = {
    [BINANCE_ID]: 'bsc',
    [FANTOM_ID]: 'fantom',
};

export const SECOND_PER_YEAR = new BigNumber(31536000);

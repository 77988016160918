import { useMemo } from 'react';
import BigNumber from 'bignumber.js';
import { abis } from '@project/contracts';
import { useWeb3React } from '@web3-react/core';
import { useToasts } from 'react-toast-notifications';

import { makeContract } from '../../utils/utils';

const useMultiBurn = ({ poolAddress, inputValue, minAmountsOut, wrappedHandleChange, setLoader }) => {
    const { account, library } = useWeb3React();
    const { addToast } = useToasts();
    const contract = makeContract(library, abis.indexpool, poolAddress);

    const poolAmountOut = useMemo(() => (
        library.utils.toWei(inputValue === '' ? '0' : inputValue, 'ether')
    ), [inputValue, library]);

    const handleMultiBurn = async () => {
        setLoader(true);
        const params = [
            poolAmountOut.toString(),
            minAmountsOut.map(() => 1),
        ];
        const gas = await contract.methods
            .exitPool(...params)
            .estimateGas({ from: account })
            .then(res => new BigNumber(res).multipliedBy(1.1).toFixed(0))
            .catch(() => {
                addToast('Error during gas estimation', { appearance: 'error', autoDismiss: true });
                setLoader(false);
            });

        if (!gas) return;

        addToast('Waiting for transaction success...', { appearance: 'info', autoDismiss: true });

        await contract.methods
            .exitPool(...params)
            .send({ from: account, gasLimit: gas }).then(() => {
                addToast('Transaction Success!', { appearance: 'success', autoDismiss: true });
                setLoader(false);
                wrappedHandleChange('');
            }).catch(err => {
                if (err.message.includes('User denied transaction signature')) {
                    addToast('Denied Transaction', { appearance: 'error', autoDismiss: true });
                    setLoader(false);
                } else {
                    addToast('Transaction Failed', { appearance: 'error', autoDismiss: true });
                    setLoader(false);
                }
            });

    };

    return { handleMultiBurn };
};

export default useMultiBurn;

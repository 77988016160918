import { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';
import useSWR from 'swr';

import useGraphRequest from '../useGraphRequest';

import { getGraphYbfUrl } from '../../utils/utils';

import { COMMON_BASE_TOKENS } from '../../constants/chain.constants';
import { CHAIN } from '../../constants/global.constants';
import { API_YBF_INFO } from '../../constants/routes.constants';
import { getYBFPool } from '../../constants/graph.constants';

const usePoolYBFInfo = poolAddress => {
  const [info, setInfo] = useState();

  const { library, chainId } = useWeb3React();

  const { data } = useGraphRequest(getGraphYbfUrl(chainId), getYBFPool(poolAddress));
  const { data: ybfInfoApy } = useSWR(`${API_YBF_INFO}${CHAIN[chainId]}`);

  useEffect(() => {
    (async () => {
      if (data?.indexPool) {
        const { name, symbol, totalSupply, totalWeight, tokens, totalValueLockedUSD, dailySnapshots } = data?.indexPool;
        const currentFund = ybfInfoApy?.find(item => item.id === poolAddress);

        const farmInfoList = tokens.map(item => {
          const { id, strategy: { lpToken, lpToken0, lpToken1 }, priceUSD } = item.token;

          return ({
            apy: currentFund?.tokens?.find(token => token.id === id)?.apy,
            address: id,
            dexName: lpToken.symbol,
            symbol: `${lpToken0.symbol}-${lpToken1.symbol}`,
            weight: new BigNumber(item.balance)
              .div(1e18).multipliedBy(new BigNumber(priceUSD))
              .div(new BigNumber(totalValueLockedUSD))
              .multipliedBy(100),
          });
        });

        setInfo({
          name,
          symbol,
          farmInfoList,
          generalApy: new BigNumber(currentFund?.averageApy),
          dailySnapshots,
          address: poolAddress.toLowerCase(),
          totalSupply: new BigNumber(totalSupply),
          totalWeight: new BigNumber(totalWeight),
          tvl: new BigNumber(totalValueLockedUSD),
          mintTokenList: [COMMON_BASE_TOKENS[chainId][0], COMMON_BASE_TOKENS[chainId][1]],
        });
      }
    })();

  }, [chainId, data, library, poolAddress, ybfInfoApy]);

  return info;
};

export default usePoolYBFInfo;


const addresses = {
  // local fork - depending on what chain you are forking you can copy the addresses from below.
  250: {
    factory: '0xEF45d134b73241eDa7703fa787148D9C9F4950b0',
    router: '0x16327E3FbDaCA3bcF7E38F5Af2599D2DDc33aE52',
    uniswapV2oracle: '0x8b789c1dDCde8994347a9b96f11358f60b6dd8FE',

    MULTISIG: '0xE064F590d11EdE74CE2F13a7926ADD4A32E014E3',
    DEV_EXIT: '0xFd1b8BECECe07ccdC5BB641976c60398aA9df5BB',

    // Tokens
    WBNB: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    SPIRIT: '0x5cc61a78f164885776aa610fb0fe1257df78e59b',
    TAROT: '0xc5e2b037d30a390e62180970b3aa4e91868764cd',
    TCS: '0xfbfae0dd49882e503982f8eb4b8b1e464eca0b91',
    MIM: '0x82f0b8b456c1a451378467398982d4834b6829c1',
    DIS: '0x0e121961dd741c9d49c9a04379da944a9d2fac7a',
    YFI: '0x29b0da86e484e1c0029b56e817912d778ac0ec69',
    CRV: '0x1e4f97b9f9f913c46f1632781732927b9019c68b',
    LINK: '0xb3654dc3d10ea7645f8319668e8f54d2574fbdc8',
    SUSHI: '0xae75a438b2e0cb8bb01ec1e1e376de11d44477cc',
    TOMB: '0x6c021ae822bea943b2e66552bde1d2696a53fbb7',
    MENSA: '0x6b77d8b9be440fcbfe72cb4104d98bbd7cf91b14',
    LQDR: '0x10b620b2dbac4faa7d7ffd71da486f5d44cd86f9',
    JUST: '0x37c045be4641328dfeb625f1dde610d061613497',
    ICE: '0xf16e81dce15b08f326220742020379b855b87df9',
    BISON: '0xd2f38621c3c65300ecaba7020c05d1350f9c265c',
    ANY: '0xddcb3ffd12750b45d32e084887fdf1aabab34239',
    BNB: '0xd67de0e0a0fd7b15dc8348bb9be742f3c5850454',

    '0xa55568847c5c5be3b6c59c6e57e74677591d900d': {
      uniswapRouterBurner: '0x3614CB1058a5a1c1D03D481eD86574cB5ff0F8DC',
      uniswapRouterMinter: '0xFF67Ae3F9e4f0Ee7A655cDA3c845db3f20fd34bb',
      biDEGEN: '0xa55568847C5c5be3B6C59c6e57E74677591d900d',
    },

    '0x259c0cf00f8710b473457314420114ae4c87cee5': {
      uniswapRouterBurner: '0x3614CB1058a5a1c1D03D481eD86574cB5ff0F8DC',
      uniswapRouterMinter: '0xFF67Ae3F9e4f0Ee7A655cDA3c845db3f20fd34bb',
      biSPIRIT: '0x259c0cF00F8710b473457314420114AE4C87Cee5',
    },

    biDEGEN: '0xa55568847C5c5be3B6C59c6e57E74677591d900d',
    biSPIRIT: '0x259c0cF00F8710b473457314420114AE4C87Cee5',

    YBF: {
      FTM_BISON_SPIRIT: '0xed94e02b9946de6fc363823bb889522093b0694f',
      FTM_TAROT_SPIRIT: '0x408bd7e0cdcf28103ea978ea5e5b6307cd48f36d',
      FTM_SPIRIT_SPIRIT: '0xb69c3fc013c5a1c5b615d2aa90c6d6fc54701d01',
      FTM_BOO_BOO: '0xf41d1d1cc6edb7512ef8b9f8d0134dc7df9b71f0',
      FTM_LQDR_BOO: '0x4667c53a1150a94224c0ab4ccd24aeec1d8b764c',

      '0x1eb9656dff676f76b5603a65867ad8b2e7ed0fca': {
        uniswapYBFRouterMinter: '0xc8d8Ccb89b4B2e64e057C5934eC4DADb0Ec93135',
        uniswapYBFRouterBurner: '0x80fE0f42A7bFAd202cAE2Ad9Db4C40C4B4819fF8',
      },

      mfALFA: '0x1eb9656dff676f76b5603a65867ad8b2e7ed0fca',
    },
  },
  56: {
    // BSC MAINNET
    // APESWAP
    factory: '0x0841BD0B734E4F5853f0dD8d7Ea041c241fb0Da6',  // ApeSwap MAINNET uniswapV2factory.
    router: '0xcF0feBd3f17CEf5b47b0cD257aCf6025c5BFf3b7',   // ApeSwap MAINNET uniswapV2router.
    uniswapV2oracle: '0x7D1F68D7BA8A40B73EeB499E8D9D96145d1f6500', // apeswap MAINNET oracle deployed at 9 juli

    MULTISIG: '0x60731C18EDd8a0Fd2A0d0C8B6462c8026e1939A7',

    // FIRST FUND TOKENS
    WBNB: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    CAKE: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    SWIPE: '0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A',
    BAKE: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    VENUS: '0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63',
    ELLIPSIS: '0xA7f552078dcC247C2684336020c03648500C6d9F',
    BANANA: '0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95',
    SAFEPAL: '0xD41FDb03Ba84762dD66a0af1a6C8540FF1ba5dfb',
    TWT: '0x4B0F1812e5Df2A09796481Ff14017e6005508003',
    BIFI: '0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
    BISON: '0x19A6Da6e382b85F827088092a3DBe864d9cCba73',
    JDI: '0x0491648C910ad2c1aFaab733faF71D30313Df7FC',
    HIFI: '0x0A38bc18022b0cCB043F7b730B354d554C6230F1',
    BITFRESH: '0xa4F93159cE0A4B533b443c74b89967C60A5969F8',
    TAPE: '0xF63400ee0420ce5b1Ebdee0C942D7dE1C734a41f',
    LYPTUS: '0xBA26397cdFF25F0D26E815d218Ef3C77609ae7f1',
    DRAGONARY: '0xd9025e25Bb6cF39f8c926A704039D2DD51088063',
    ETHERIUM: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    BITCOIN: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    CARDANO: '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
    AVALANCHE: '0x1CE0c2827e2eF14D5C4f29a091d735A204794041',
    FANTOM: '0xAD29AbB318791D579433D831ed122aFeAf29dcfe',
    POLYGON: '0xCC42724C6683B7E57334c4E856f4c9965ED682bD',
    BUSD: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    USDT: '0x55d398326f99059fF775485246999027B3197955',
    USDC: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    MBOX: '0x3203c9E46cA618C8C1cE5dC67e7e9D75f5da2377',
    AXS: '0x715D400F88C167884bbCc41C5FeA407ed4D2f8A0',
    SFUND: '0x477bC8d23c634C154061869478bce96BE6045D12',
    POTS: '0x3Fcca8648651E5b974DD6d3e50F61567779772A8',
    SKILL: '0x154A9F9cbd3449AD22FDaE23044319D6eF2a1Fab',
    REVV: '0x833F307aC507D47309fD8CDD1F835BeF8D702a93',
    DOGE: '0xba2ae424d960c26247dd6c32edc70b295c744c43',
    DOT: '0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
    TRON: '0x85eac5ac2f758618dfa09bdbe0cf174e7d574d5b',
    XRP: '0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe',
    LINK: '0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd',
    LTC: '0x4338665cbb7b2485a8855a139b75d5e34ab0db94',
    UNI: '0xbf5140a22578168fd562dccf235e5d43a02ce9b1',
    SUSHI: '0x947950BcC74888a40Ffa2593C5798F11Fc9124C4',
    AAVE: '0xfb6115445bff7b52feb98650c87f44907e58f802',
    COMP: '0x52ce071bd9b1c4b00a0b92d298c512478cad67e8',
    SNX: '0x9ac983826058b8a9c7aa1c9171441191232e8404',
    YFI: '0x88f1a5ae2a3bf98aeaf342d26b30a79438c9142e',
    WANA: '0x339C72829AB7DD45C3C52f965E7ABe358dd8761E',
    THG: '0x9fD87aEfe02441B123c3c32466cD9dB4c578618f',
    HERO: '0xD40bEDb44C081D2935eebA6eF5a3c8A31A1bBE13',
    MATE: '0x2198B69B36B86F250549d26D69C5957912A34Ec2',
    PACOCA: '0x55671114d774ee99D653D6C12460c780a67f1D18',
    HUNNY: '0x565b72163f17849832A692A3c5928cc502f46D69',
    LAND: '0x9D986A3f147212327Dd658F712d5264a73a1fdB0',
    DIS: '0x57efFdE2759b68d86C544e88F7977e3314144859',
    SAFESTOS: '0x9eAB0a93b0cd5d904493694F041BdCedb97b88C6',
    MDX: '0x9c65ab58d8d978db963e63f2bfb7121627e3a739',
    BSW: '0x965f527d9159dce6288a2219db51fc6eef120dd1',
    biCHAIN_BNB_LP: '0xDAff7BEB8cAD0E75CcA3d9F36b8f8C0e1cf37b85',
    OASIS: '0xb19289b436b2f7a92891ac391d8f52580d3087e4',
    STARS: '0xbd83010eb60f12112908774998f65761cf9f6f9a',
    NFTY: '0x5774b2fc3e91af89f89141eacf76545e74265982',
    CEEK: '0xe0f94ac5462997d2bc57287ac3a3ae4c31345d66',

    '0x2a5f983706b6535ab5d69f003eb271ec44b5e839': {
      biT10: '0x2a5f983706b6535ab5d69f003eb271ec44b5e839',
      uniswapRouterBurner: '0x924AF62D2666625A197229c520D9981CE606c6a5',
      uniswapRouterMinter: '0x3a0b1837F3b6235e2e884A52473D917A7C9275A2',
    },

    '0xf19b6746769132926ee82f47137aeb7a8702c4b9': {
      uniswapRouterBurner: '0x924AF62D2666625A197229c520D9981CE606c6a5',
      uniswapRouterMinter: '0x3a0b1837F3b6235e2e884A52473D917A7C9275A2',
    },

    '0x10a520829c1e9631ef9e60e37eea3916092caa99': {
      uniswapRouterBurner: '0x924AF62D2666625A197229c520D9981CE606c6a5',
      uniswapRouterMinter: '0x3a0b1837F3b6235e2e884A52473D917A7C9275A2',
      biChain: '0x10a520829c1e9631ef9e60e37eea3916092caa99',
    },

    '0x8f9f6589c2eb04ae4a0a737b78353fdbb628ffe8': {
      uniswapRouterBurner: '0x924AF62D2666625A197229c520D9981CE606c6a5',
      uniswapRouterMinter: '0x3a0b1837F3b6235e2e884A52473D917A7C9275A2',
      biGame: '0x8f9f6589c2eb04ae4a0a737b78353fdbb628ffe8',
    },

    '0xfc02d63e44e71d4b8c81a436f19ce580eb7bfada': {
      uniswapRouterBurner: '0x8bFD1FA57563F7b5E6e173751455Ef205e73403c',
      uniswapRouterMinter: '0x84356119571Df5201813D49f7A1c8A2E1e92C36D',
      biMex: '0xfc02d63e44e71d4b8c81a436f19ce580eb7bfada',
    },

    '0x28939911a862bd4e6b10e281f233a5e060114e0b': {
      uniswapRouterBurner: '0x924AF62D2666625A197229c520D9981CE606c6a5',
      uniswapRouterMinter: '0x3a0b1837F3b6235e2e884A52473D917A7C9275A2',
      biDEFI: '0x28939911a862bd4E6b10E281F233a5E060114E0B',
    },

    '0xf09026225aa59d8ab89ec4e06aa1d6dcbe5eb1cf': {
      uniswapRouterBurner: '0x8bFD1FA57563F7b5E6e173751455Ef205e73403c',
      uniswapRouterMinter: '0x84356119571Df5201813D49f7A1c8A2E1e92C36D',
      biFoMo: '0xf09026225aa59d8ab89ec4e06aa1d6dcbe5eb1cf',
    },

    biT10: '0x2a5F983706b6535ab5d69f003Eb271eC44b5E839',
    apeSwap: '0xF19B6746769132926Ee82F47137aEB7A8702c4b9',
    biChain: '0x10A520829C1E9631Ef9E60e37eEA3916092cAa99',
    biGame: '0x8F9f6589C2eB04Ae4A0A737b78353FdBB628ffe8',
    biMex: '0xFC02D63E44e71D4b8C81a436f19Ce580Eb7bFAda',
    biDeFi: '0x28939911a862bd4E6b10E281F233a5E060114E0B',
    biFoMo: '0xf09026225AA59D8Ab89Ec4E06aa1d6dCbe5eB1cF',

    YBF: {
      DAI_BUSD_Cake: '0x229fdcf42b5875fcbca715ca664082fddb8658b5',
      USDT_BUSD_APE: '0x287b5c73e109837e2da69482d7656531908d8fb9',
      USDC_BUSD_APE: '0x61f98065ab608717f010b916183a7cbb1612a0e5',
      USDT_USDC_Cake: '0x7ae680c088246d2021a27de40c394784e37e8f27',
      USDC_BUSD_Cake: '0x84b59102bb7766e2fd6bd78afbf2e9301885e970',
      TUSD_BUSD_Cake: '0xd325f9cc64a94381843ebe0a64f4dde732d13c96',
      USDT_BUSD_Cake: '0xf7086e6876bb03e1afbb6346dd6922cbf8900299',
      USDC_USDT_Biswap: '0x48f607fa683845C22Ef80b08fcd391AC147713c8',
      USDT_BUSD_Biswap: '0x72f14bCb09510967876362d06Cb6c584a3821181',
      TUSD_BUSD_Biswap: '0x5BFf579ED2B3D8077886F63Dc6349708104Ac2bb',
      USDC_USDT_Mdex: '0x9cd4DBa63e47d3DbB7Fe7d2DF6bAa5B54BFF8673',
      DAI_USDT_Biswap: '0xef0E11118e2ec60CfEBeD948B19f62ad9bF04a4C',
      DAI_USDT_Mdex: '0xF5Ac20c1f99f1242b49dAaB1513573fD373854A9',
      BUSD_USDT_Mdex: '0xFdfa54A2820Ff6e18cbD71a2A5eBcBf96e1062d8',
      BNB_BSW_Biswap: '0xAa244De285918953329C126dD8683619ca2a9073',
      BNB_BANANA_APE: '0x3DCea44F030b3d6095cA60C791e750FC029d7270',
      BNB_CAKE_Cake: '0xe7922288131C51F8550ef04963881149e95380c2',
      BNB_MDEX_MDEX: '0x5788974f1C3c6683D5cA526B915858C3C07bfAb9',
      BNB_BISON_APE: '0x2128736bA7E0d27890D603d863FA3C73c8A69d10',
      BISON_BNB_APE: '0x2c3982d12d99de7e5b589d9f37f821519d56f909',
      CEEK_BNB_APE: '0xc8a96434a1f281e7f48bc1da78f1c9b90e5dc213',
      MOGUL_BNB_APE: '0x8adc2b6c205afb09a9dfcb3847513742cdbc0ad3',
      NIFTY_BNB_APE: '0x2b70a25c235dc1dcd80117c2994b99b052149a85',
      OASIS_BNB_APE: '0x396c0bfe404db1f413a7707debeba92956d7e758',

      '0x767326ade6d727957764a29c11a7ebf5ba6fd797': {
        uniswapYBFRouterMinter: '0xa3c05D4C9407faE01c82cF5B9CABFFa33D42E651',
        uniswapYBFRouterBurner: '0xcf30EfEC4a1b14A36E8D3b61d19051b3ca590943',
      },

      '0x0ce62dc7d1a0414d6aec2ede849de79eeba7544f': {
        uniswapYBFRouterMinter: '0xa3c05D4C9407faE01c82cF5B9CABFFa33D42E651',
        uniswapYBFRouterBurner: '0xcf30EfEC4a1b14A36E8D3b61d19051b3ca590943',
      },

      '0x3b202644744e0a3647dad7310a95373669332a77': {
        uniswapYBFRouterMinter: '0x17f7ea4fEA7cc0962507B2eA21ed92352536AAaE',
        uniswapYBFRouterBurner: '0x278dB8dbAe2646946A450A9598F4D8CC66f85C9b',
      },

      '0x372f129d262aa847056ff5d3db5195848219c427': {
        uniswapYBFRouterMinter: '0xA376a6153bb7A708B9989db1A5efbCF1F106659B',
        uniswapYBFRouterBurner: '0x278dB8dbAe2646946A450A9598F4D8CC66f85C9b',
      },

      ybfStable: '0x767326ade6d727957764a29c11a7ebf5ba6fd797',
      ybfDEX: '0x0ce62dc7d1a0414d6aec2ede849de79eeba7544f',
      ybfTest: '0x3b202644744e0a3647dad7310a95373669332a77',
      ybfMETA: '0x372f129d262aa847056ff5d3db5195848219c427',
    },
  },
};

export default addresses;

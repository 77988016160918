import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import classNames from 'classnames';

import TokenInfo from './TokenInfo';
import PoolChart from '../../../../components/PoolChart';

import { useStyles } from './styles.module';

const PoolContent = ({ poolInfo }) => {
  const classes = useStyles();
  const [isChart, setChart] = useState(false);

  if (!poolInfo) return null;

  return (
    <Grid
      container
      item
      direction="column"
      className={classes.content}
    >
      <Grid item spacing={2} container direction="column" className={classes.text}>
        <div>
          <button
            className={classNames(classes.titleButton, { [classes.activeButton]: !isChart })}
            onClick={() => setChart(false)}
          >
            Asset Allocation
          </button>
          <button
            className={classNames(classes.titleButton, { [classes.activeButton]: isChart })}
            onClick={() => setChart(true)}
          >
            Charts
          </button>
        </div>
        {
         !isChart
         && <Grid item className={classes.description}>Breakdown of total assets in this fund</Grid>
        }
      </Grid>
      {
        isChart
          ? <PoolChart address={poolInfo.address} />
          : (
            <Grid container spacing={6} item>
              {poolInfo.tokens.map(token => {
                  const balance = poolInfo.balances.find(item => item.address.toLowerCase() === token.toLowerCase());

                  if (!balance) return <div key={token} />;

                  return (
                    <Grid container item lg={6} key={token}>
                      <TokenInfo
                        poolInfo={poolInfo}
                        balance={balance?.balance}
                        poolToken={token}
                      />
                    </Grid>
                  );
                })}
            </Grid>
            )
}
    </Grid>
  );
};

export default PoolContent;

import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../../theme';

export const useStyles = makeStyles({
  card: {
    height: '100%',
    backgroundColor: '#DAA10E',
    color: theme.palette.text.primary,
    width: '100%',
    borderRadius: '32px',
    background: 'linear-gradient(180deg, #DAA10E 50%, #1E1F20 100%)',
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 16px',
    height: '70px',
  },
  button: {
    border: 'none',
    background: 'transparent',
    cursor: 'pointer',
    color: '#000000',
    fontSize: '20px',
    fontWeight: '400',
    marginRight: '10px',
  },
  activeButton: {
    fontWeight: '700',
  },
  content: {
    padding: '32px',
    minWidth: '100%',
    color: theme.palette.text.main,
    backgroundColor: '#1E1F20',
    borderRadius: '32px',
    position: 'relative',
    minHeight: '200px',
  },
  farmPanel: {
    padding: '0',
    paddingBottom: '20px',
  },
  nowrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  difference: {
    color: `${theme.palette.green.main} !important`,
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
  },
  kebabContainer: {
    position: 'relative',
  },
  kebabMenu: {
    position: 'absolute',
    top: '40px',
    right: '20px',
    width: '222px',
    height: '58px',
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.25)',
    backgroundColor: '#4D4D4D',
    borderRadius: '16px 4px 16px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& a': {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        '& path': {
          stroke: '#fff',
        },
      },
    },
  },
  menuIcon: {
    color: '#191919',
    '&:hover': {
      color: '#191919',
      opacity: 1,
    },
    '& svg': {
      color: '#191919',
      '&:hover': {
        color: 'white',
      },
    },
  },
});

import React, { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

import ConnectWalletModal from '../../modal/ConnectWalletModal';
import LogOutModal from '../../modal/LogOutModal';
import ChangeChainButton from '../../../components/ChangeChainButton';

import BiSharesLogo from '../../../assets/logos/BiSharesLogo.png';
import BisonLogo from '../../../assets/logos/bisonLogo.png';

import { LogIn, Wallet } from '../../../constants/icons.constants';
import {
  PATH_INDEX,
  LINK_PATH_HOME,
  LINK_PATH_ABOUT,
  PATH_FARMS,
  LINK_PATH_ARBX,
} from '../../../constants/routes.constants';

import { useBnbPriceState } from '../../../state/bnbPriceContext';

import { useStyles } from './styles.module';

const Header = () => {
  const classes = useStyles();
  const history = useHistory();
  const { account, chainId } = useWeb3React();
  const priceState = useBnbPriceState();
  const [isConnectModal, setConnectModal] = useState(false);
  const [isLogOutModal, setLogOutModal] = useState(false);
  const [isDropDown, setDropDown] = useState(false);
  const matches = useMediaQuery('(max-width:1145px)');

  const walletAddress = useMemo(
    () => account && `${account?.slice(0, 7)}...${account?.slice(account?.length - 4, account?.length)}`,
    [account],
  );

  useEffect(() => account && setConnectModal(false), [account]);

  return (
    <>
      <header className={classes.header}>
        <div className={classes.row}>
          <div>
            <Link to={PATH_INDEX}>
              <img src={BiSharesLogo} alt="BiShares Index" className={classes.logo} />
            </Link>
          </div>
          {
              !matches && (
              <nav className={classes.nav}>
                <a href={LINK_PATH_HOME}><button className={classes.link}>Home</button></a>
                <a href={LINK_PATH_ABOUT}><button className={classes.link}>About</button></a>
                <button className={classes.link} onClick={() => history.push(PATH_INDEX)}>Funds</button>
                <button className={classes.link} onClick={() => history.push(PATH_FARMS)}>Farms</button>
                {chainId === 56 && <a href={LINK_PATH_ARBX}><button className={classes.link}>ArbEx</button></a>}
              </nav>
              )
            }
        </div>
        {
            !matches && (
            <div className={classes.row}>
              {
                !!priceState?.bishares?.usd && (
                <div className={classes.price}>
                  <img src={BisonLogo} alt="bisonLogo" />
                  ${priceState?.bishares?.usd}
                </div>
                )
              }
              {
                    account ? (
                      <>
                        <ChangeChainButton />
                        <button
                          className={classes.button}
                          onClick={() => setLogOutModal(true)}
                        >
                          {Wallet}{walletAddress}
                        </button>
                      </>
                    ) : (
                      <button
                        className={classes.button}
                        onClick={() => setConnectModal(true)}
                      >
                        {LogIn}Connect Wallet
                      </button>
                    )
                  }

            </div>
            )
          }
        {
            matches && (
            <>
              {
                    isDropDown ? (
                      <CloseIcon
                        fontSize="large"
                        className={classes.navIcon}
                        onClick={() => setDropDown(false)}
                      />
                    ) : (
                      <MenuIcon
                        fontSize="large"
                        className={classes.navIcon}
                        onClick={() => setDropDown(true)}
                      />
                    )
                  }
            </>
            )
          }
        {isConnectModal && <ConnectWalletModal onClose={() => setConnectModal(false)} />}
        {isLogOutModal && <LogOutModal onClose={() => setLogOutModal(false)} title={walletAddress} account={account} />}
      </header>
      {
        (matches && isDropDown) && (
        <div className={classes.menu}>
          <a href={LINK_PATH_HOME}><button className={classNames(classes.link, classes.mobileLink)}>Home</button></a>
          <a href={LINK_PATH_ABOUT}><button className={classNames(classes.link, classes.mobileLink)}>About</button></a>
          <button className={classNames(classes.link, classes.mobileLink)} onClick={() => history.push(PATH_INDEX)}>Funds</button>
          <button className={classNames(classes.link, classes.mobileLink)} onClick={() => history.push(PATH_FARMS)}>Farms</button>
          {chainId === 56 && <a href={LINK_PATH_ARBX}><button className={classNames(classes.link, classes.mobileLink)}>ArbEx</button></a>}
          <div className={classes.otherButton}>
            <div className={classes.price}>
              <img src={BisonLogo} alt="bisonLogo" />
              ${priceState?.bishares?.usd}
            </div>
            {
              account ? (
                <>
                  <button
                    className={classes.button}
                    onClick={() => setLogOutModal(true)}
                  >
                    {Wallet}{walletAddress}
                  </button>
                </>
              ) : (
                <button
                  className={classes.button}
                  onClick={() => setConnectModal(true)}
                >
                  {LogIn}Connect Wallet
                </button>
              )
            }
          </div>
        </div>
        )
      }
    </>
  );
};

export default Header;

import { useState, useEffect, useMemo } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useToasts } from 'react-toast-notifications';
import { abis, addresses } from '@project/contracts';
import BigNumber from 'bignumber.js';

import { isApproved, makeContract } from '../../utils/utils';

import useAllowance from '../useAllowance';
import useApprove from '../useApprove';
import useTokenBalance from '../useTokenBalance';

import { ADDRESS_ZERO, GAS_MULTIPLYER } from '../../constants/global.constants';

const useBurnYBF = ({ indexPool, burnWithThisToken, tvl, totalSupply }) => {
    const { account, library, chainId } = useWeb3React();
    const { addToast } = useToasts();

    const [inputValue, setInputValue] = useState('');
    const [loader, setLoader] = useState(false);
    const [inputError, setInputError] = useState();
    const [potentialBnbSwappedAmount, setPotentialBnbSwappedAmount] = useState(new BigNumber(0));

    const isMainToken = burnWithThisToken.address === ADDRESS_ZERO;

    const contract = makeContract(library, abis.uniswapYBFRouterBurner, addresses[chainId].YBF[indexPool].uniswapYBFRouterBurner);

    const allowance = useAllowance(addresses[chainId].YBF[indexPool].uniswapYBFRouterBurner, indexPool);

    const tokenBalance = useTokenBalance(indexPool);

    const balance = useMemo(() => (
        tokenBalance && library.utils.fromWei(tokenBalance?.toFixed(0), 'ether')
    ), [tokenBalance, library]);

    const deposited = useMemo(() => (
        tvl.div(totalSupply.div(1e18)).multipliedBy(new BigNumber(balance)).toFixed(3)
    ), [tvl, totalSupply, balance]);

    const isTokenApproved = useMemo(() => isApproved(allowance), [allowance]);

    const { onApprove } = useApprove(
        addresses[chainId].YBF[indexPool].uniswapYBFRouterBurner,
        indexPool,
        setLoader,
    );

    const onApproveHandler = async () => {
        setLoader(true);
        await onApprove();
    };

    useEffect(() => {
        if (inputValue) {
            (async () => {
                if (isMainToken) {
                    const onMinAmountForBurn = await contract.methods
                        .burnForAllTokensAndSwapForETH(indexPool, inputValue, '1')
                        .call({ from: account });
                    setPotentialBnbSwappedAmount(onMinAmountForBurn);
                } else {
                    const onMinAmountForBurn = await contract.methods
                        .burnForAllTokensAndSwapForTokens(indexPool, inputValue, burnWithThisToken.address, '1')
                        .call({ from: account });
                    setPotentialBnbSwappedAmount(onMinAmountForBurn);
                }
            })();
        }
    }, [inputValue, indexPool, burnWithThisToken, contract, account, isMainToken]);

    const handleBurn = async () => {
        setLoader(true);
        if (isMainToken) {
            const gas = await contract.methods
                .burnForAllTokensAndSwapForETH(indexPool, inputValue, '1')
                .estimateGas({ from: account })
                .then(res => new BigNumber(res).multipliedBy(GAS_MULTIPLYER).toFixed(0))
                .catch(() => {
                    addToast('Error during gas estimation', { appearance: 'error', autoDismiss: true });
                    setLoader(false);
                });
            if (!gas) return;
            addToast('Waiting for transaction success...', { appearance: 'info', autoDismiss: true });
            await contract.methods
                .burnForAllTokensAndSwapForETH(indexPool, inputValue, '1')
                .send({ from: account, gasLimit: gas })
                .then(() => {
                    addToast('Transaction Success!', { appearance: 'success', autoDismiss: true });
                    setInputValue('');
                    setLoader(false);
                })
                .catch(err => {
                    if (err.message.includes('User denied transaction signature')) {
                        addToast('Denied Transaction', { appearance: 'error', autoDismiss: true });
                        setLoader(false);
                    } else {
                        addToast('Transaction Failed', { appearance: 'error', autoDismiss: true });
                        setLoader(false);
                    }
                });
        } else {
            const gas = await contract.methods
                .burnForAllTokensAndSwapForTokens(indexPool, inputValue, burnWithThisToken.address, '1')
                .estimateGas({ from: account })
                .then(res => new BigNumber(res).multipliedBy(GAS_MULTIPLYER).toFixed(0))
                .catch(() => {
                    addToast('Error during gas estimation', { appearance: 'error', autoDismiss: true });
                    setLoader(false);
                });
            if (!gas) return;
            addToast('Waiting for transaction success...', { appearance: 'info', autoDismiss: true });
            await contract.methods
                .burnForAllTokensAndSwapForTokens(indexPool, inputValue, burnWithThisToken.address, '1')
                .send({ from: account, gasLimit: gas })
                .then(() => {
                    addToast('Transaction Success!', { appearance: 'success', autoDismiss: true });
                    setInputValue('');
                    setLoader(false);
                })
                .catch(err => {
                    if (err.message.includes('User denied transaction signature')) {
                        addToast('Denied Transaction', { appearance: 'error', autoDismiss: true });
                        setLoader(false);
                    } else {
                        addToast('Transaction Failed', { appearance: 'error', autoDismiss: true });
                        setLoader(false);
                    }
                });
        }
    };

    const handleChange = async value => {
        setInputError();
        const erc20Contract = makeContract(library, abis.erc20, indexPool);
        const balance = await erc20Contract.methods.balanceOf(account).call();
        const currentValue = new BigNumber(balance).multipliedBy(new BigNumber(value).div(100));
        setInputValue((currentValue.toFixed(0)));
    };

    return {
        handleBurn,
        inputValue,
        handleChange,
        inputError,
        loader,
        potentialBnbSwappedAmount,
        setPotentialBnbSwappedAmount,
        setInputValue,
        isTokenApproved,
        onApproveHandler,
        deposited,
    };
};

export default useBurnYBF;

import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import classNames from 'classnames';

import useStyles from './styles.module';

const PercentInput = ({ header, tokenInfo, onChange, balance, className, balanceInUsd, isPanel }) => {
    const [percent, setPercent] = useState(0);
    const classes = useStyles();

    return (
      <Grid container alignItems="center" className={classNames(classes.container, className)}>
        <div className={classes.flexContainer}>
          {header && <div>{header}</div>}
          {
            balance && (
            <div className={classes.balance}>
              Balance: {balanceInUsd && '$'}{balance}
            </div>
            )
          }
        </div>
        <div className={classNames(classes.flexContainer, classes.margin)}>
          <div className={classes.percent}>{percent}%</div>
          <div className={classes.tokenSymbol}>{tokenInfo?.symbol}</div>
        </div>
        <div className={classNames(classes.flexContainer, { [classes.smallButton]: isPanel })}>
          <button className={classes.percentButton} onClick={() => { setPercent(25); onChange(25); }}>25%</button>
          <button className={classes.percentButton} onClick={() => { setPercent(50); onChange(50); }}>50%</button>
          <button className={classes.percentButton} onClick={() => { setPercent(75); onChange(75); }}>75%</button>
          <button className={classes.percentButton} onClick={() => { setPercent(100); onChange(100); }}>Max</button>
        </div>
      </Grid>
    );
};

PercentInput.defaultProps = {
    className: '',
    balanceInUsd: false,
    isPanel: false,
};

export default PercentInput;

import { useEffect, useMemo } from 'react';
import BigNumber from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';
import { useToasts } from 'react-toast-notifications';
import { abis, addresses } from '@project/contracts';

import { makeContract } from '../../utils/utils';

import useTokenBalance from '../useTokenBalance';

import { ADDRESS_ZERO, GAS_MULTIPLYER, MIN_MAX_CHANGE_VALUE, WBNB } from '../../constants/global.constants';

const useRouterMint = ({ indexPool, mintWithThisToken, indexPoolTokens, inputValue, wrappedHandleChange, setLoader,
       setPotentialBnbSwappedAmount, setIsDemandGreaterThanBalance,
}) => {
    const { account, library, chainId } = useWeb3React();
    const { addToast } = useToasts();
    const balance = useTokenBalance(mintWithThisToken.address);
    const contract = makeContract(
        library,
        abis.uniswapRouterMinter,
        addresses[chainId][indexPool].uniswapRouterMinter,
    );
    const maxAmountMulCoefficient = 1 + MIN_MAX_CHANGE_VALUE;
    const isMainToken = mintWithThisToken.address === ADDRESS_ZERO || mintWithThisToken.symbol === WBNB;
    const mintToken = isMainToken ? addresses[chainId].WBNB : mintWithThisToken.address;

    const poolAmountOut = useMemo(() => (
        library.utils.toWei(inputValue === '' ? '0' : inputValue, 'ether')
    ), [inputValue, library]);

    const intermediaries = useMemo(() => (
        indexPoolTokens.map(() => (isMainToken ? ADDRESS_ZERO : addresses[chainId].WBNB))
    ), [indexPoolTokens, isMainToken, chainId]);

    useEffect(() => {
        if (inputValue) {
            (async () => {
                const onMaxAmountForJoin = await contract.methods
                    .getMaxAmountForJoin(mintToken, intermediaries, indexPool, poolAmountOut)
                    .call();
                setPotentialBnbSwappedAmount(new BigNumber(onMaxAmountForJoin));
                if (new BigNumber(onMaxAmountForJoin).isGreaterThan(balance)) {
                    setIsDemandGreaterThanBalance('Insufficient balance');
                } else {
                    setIsDemandGreaterThanBalance(null);
                }
            })();
        }
    }, [
        inputValue,
        mintToken,
        indexPool,
        intermediaries,
        poolAmountOut,
        contract,
        balance,
        setPotentialBnbSwappedAmount,
        setIsDemandGreaterThanBalance,
    ]);

    const handleMint = async () => {
        setLoader(true);

        const maxAmountIn = await contract.methods
            .getMaxAmountForJoin(mintToken, intermediaries, indexPool, poolAmountOut)
            .call()
            .then(res => new BigNumber(res).multipliedBy(maxAmountMulCoefficient).toFixed(0));

        if (isMainToken) {
            const gas = await contract.methods
                .swapETHForAllTokensAndMintExact(indexPool, intermediaries, poolAmountOut)
                .estimateGas({ from: account, value: maxAmountIn })
                .then(res => new BigNumber(res).multipliedBy(GAS_MULTIPLYER).toFixed(0))
                .catch(() => {
                    addToast('Error during gas estimation', { appearance: 'error', autoDismiss: true });
                    setLoader(false);
                });
            if (!gas) return;

            addToast('Waiting for transaction success...', { appearance: 'info', autoDismiss: true });

            await contract.methods
                .swapETHForAllTokensAndMintExact(indexPool, intermediaries, poolAmountOut)
                .send({ from: account, value: maxAmountIn, gasLimit: gas })
                .then(() => {
                    addToast('Transaction Success!', { appearance: 'success', autoDismiss: true });
                    wrappedHandleChange('');
                    setLoader(false);
                })
                .catch(err => {
                    if (err.message.includes('User denied transaction signature')) {
                        addToast('Denied Transaction', { appearance: 'error', autoDismiss: true });
                        setLoader(false);
                    } else {
                        addToast('Transaction Failed', { appearance: 'error', autoDismiss: true });
                        setLoader(false);
                    }
                });
        } else {
            const gas = await contract.methods
                .swapTokensForAllTokensAndMintExact(mintToken, maxAmountIn, intermediaries, indexPool, poolAmountOut)
                .estimateGas({ from: account })
                .then(res => new BigNumber(res).multipliedBy(GAS_MULTIPLYER).toFixed(0))
                .catch(() => {
                    addToast('Error during gas estimation', { appearance: 'error', autoDismiss: true });
                    setLoader(false);
                });
            if (!gas) return;
            addToast('Waiting for transaction success...', { appearance: 'info', autoDismiss: true });
            await contract.methods
                .swapTokensForAllTokensAndMintExact(mintToken, maxAmountIn, intermediaries, indexPool, poolAmountOut)
                .send({ from: account, gasLimit: gas })
                .then(() => {
                    addToast('Transaction Success!', { appearance: 'success', autoDismiss: true });
                    wrappedHandleChange('');
                    setLoader(false);
                })
                .catch(err => {
                    if (err.message.includes('User denied transaction signature')) {
                        addToast('Denied Transaction', { appearance: 'error', autoDismiss: true });
                        setLoader(false);
                    } else {
                        addToast('Transaction Failed', { appearance: 'error', autoDismiss: true });
                        setLoader(false);
                    }
                });
        }

    };

    return { handleMint };

};
export default useRouterMint;

import erc20Abi from "./abis/erc20.json";
import indexpoolAbi from './abis/indexpool.json'
import uniswapV2oracleAbi from './abis/oracle.json'
import sellerAbi from './abis/seller.json'
import uniswapRouterMinterAbi from './abis/uniswapRouterMinter.json'
import uniswapRouterBurnerAbi from './abis/uniswapRouterBurner.json'
import factory from './abis/factory.json'
import pairs from './abis/pairs.json'
import uniswapYBFRouterBurnerAbi from './abis/uniswapYBFRouterBurner.json';
import uniswapYBFRouterMinterAbi from './abis/uniswapYBFRouterMinter.json';
import masterChefAbi from './abis/masterChef.json';
import masterChefBiSwapAbi from './abis/masterChefBiSwap.json';
import masterChefMDEXAbi from './abis/masterChefMDEX.json';
import oldFarmAbi from './abis/oldFarm.json';
import newFarmAbi from './abis/newFarm.json';

const abis = {
  erc20: erc20Abi,
  oldFarm: oldFarmAbi,
  newFarm: newFarmAbi,
  masterChef: masterChefAbi,
  masterChefBiSwap: masterChefBiSwapAbi,
  masterChefMDEX: masterChefMDEXAbi,
  indexpool: indexpoolAbi,
  uniswapV2oracle: uniswapV2oracleAbi,
  indexseller: sellerAbi,
  factory: factory,
  pairs: pairs,
  uniswapRouterMinter: uniswapRouterMinterAbi,
  uniswapRouterBurner: uniswapRouterBurnerAbi,
  uniswapYBFRouterBurner: uniswapYBFRouterBurnerAbi,
  uniswapYBFRouterMinter: uniswapYBFRouterMinterAbi,
};

export default abis;

import { useMemo, useState, useCallback } from 'react';
import BigNumber from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';
import { abis } from '@project/contracts';
import { useToasts } from 'react-toast-notifications';

import useAllowance from '../useAllowance';
import useApprove from '../useApprove';
import useTokenBalance from '../useTokenBalance';

import { isApproved, makeContract, toScaledBN } from '../../utils/utils';

const useFarm = ({ farmInfo }) => {
  const { library, account } = useWeb3React();
  const { addToast } = useToasts();

  const [loading, setLoader] = useState(false);
  const [harvestLoading, sethHarvestLoading] = useState(false);

  const [stakeValue, setStakeValue] = useState();
  const [unStakeValue, setUnStakeValue] = useState();

  const [errorStake, setErrorStake] = useState();
  const [stakeBalanceUsd, setStakeBalanceUsd] = useState(0);

  const [stakeInput, setStakeInput] = useState(false);
  const [unStakeInput, setUnStakeInput] = useState(false);

  const {
    rewardValue,
    farmAddress,
    stakingTokenAddress,
    totalStaked,
    earningDecimals,
    stakingDecimals,
    stakeTokenPrice,
    userInfo,
  } = farmInfo;

  const farmContract = makeContract(library, abis.newFarm, farmAddress);

  const allowance = useAllowance(farmAddress, stakingTokenAddress);
  const stakingTokenBalance = useTokenBalance(stakingTokenAddress);

  const isTokenApproved = useMemo(() => isApproved(allowance), [allowance]);
  const stakeBalance = useMemo(
    () => stakingTokenBalance && stakingTokenBalance.div(toScaledBN(stakingDecimals)).toFixed(3),
    [stakingTokenBalance, stakingDecimals],
  );
  const earnedValue = useMemo(
    () => new BigNumber(rewardValue).div(toScaledBN(earningDecimals)),
    [rewardValue, earningDecimals],
  );
  const totalStakedInToken = useMemo(
    () => new BigNumber(totalStaked).div(toScaledBN(stakingDecimals)).toFixed(2),
    [totalStaked, stakingDecimals],
  );
  const youStakeValue = useMemo(
    () => new BigNumber(userInfo[0]).div(toScaledBN(stakingDecimals)),
    [userInfo, stakingDecimals],
  );
  const stakeValueUsd = useMemo(() => stakeTokenPrice.times(youStakeValue), [stakeTokenPrice, youStakeValue]);

  const liquidity = useMemo(
    () => new BigNumber(totalStaked).div(toScaledBN(stakingDecimals)).times(stakeTokenPrice),
    [totalStaked, stakingDecimals, stakeTokenPrice],
  );

  const { onApprove } = useApprove(farmAddress, stakingTokenAddress, setLoader);

  const stakeDrop = useCallback(() => {
    setStakeValue(null);
    setErrorStake(null);
    setStakeBalanceUsd(0);
    setStakeInput(prevState => !prevState);
    setUnStakeInput(false);
  }, []);

  const unStakeDrop = useCallback(() => {
    setUnStakeValue(null);
    setUnStakeInput(prevState => !prevState);
    setStakeInput(false);
  }, []);

  const onChangeStakeValue = value => {
    if (value !== undefined && value !== '' && Number(value) >= 0) {
      if (new BigNumber(value).multipliedBy(1e18).isGreaterThan(stakingTokenBalance)) {
        setErrorStake('No balance');
      } else {
        setStakeBalanceUsd(0);
        setErrorStake(null);
      }
      setStakeBalanceUsd(new BigNumber(value).multipliedBy(stakeTokenPrice));
      setStakeValue(value);
    }
    if (value === '') {
      setStakeBalanceUsd(0);
      setStakeValue('');
    }
  };

  const handleMax = () => {
    setStakeBalanceUsd(new BigNumber(stakingTokenBalance).div(1e18).multipliedBy(stakeTokenPrice));
    setStakeValue(stakingTokenBalance.div(1e18));
  };

  const onChangeUnStakeValue = value => {
    setUnStakeValue(value);
  };

  const handleDeposit = async () => {
    setLoader(true);
    addToast('Waiting for transaction success...', { appearance: 'info', autoDismiss: true });

    const depositValue = new BigNumber(stakeValue).times(toScaledBN(stakingDecimals)).toFixed(0);

    await farmContract.methods
      .deposit(depositValue)
      .send({ from: account })
      .then(() => {
        addToast('Transaction Success!', { appearance: 'success', autoDismiss: true });
        setStakeValue('');
      })
      .catch(err => {
        if (err.message.includes('User denied transaction signature')) {
          addToast('Denied Transaction', { appearance: 'error', autoDismiss: true });
        } else {
          addToast('Transaction Failed', { appearance: 'error', autoDismiss: true });
        }
      })
      .finally(() => {
        setStakeBalanceUsd(0);
        setLoader(false);
      });
  };

  const handleWithdraw = async () => {
    setLoader(true);
    addToast('Waiting for transaction success...', { appearance: 'info', autoDismiss: true });

    const withdrawValue = new BigNumber(userInfo[0]).multipliedBy(unStakeValue).div(100).toFixed(0);

    await farmContract.methods
      .withdraw(withdrawValue)
      .send({ from: account })
      .then(() => {
        addToast('Transaction Success!', { appearance: 'success', autoDismiss: true });
        setStakeValue('');
      })
      .catch(err => {
        if (err.message.includes('User denied transaction signature')) {
          addToast('Denied Transaction', { appearance: 'error', autoDismiss: true });
        } else {
          addToast('Transaction Failed', { appearance: 'error', autoDismiss: true });
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleHarvest = async () => {
    setUnStakeInput(false);
    setStakeInput(false);
    sethHarvestLoading(true);
    addToast('Waiting for transaction success...', { appearance: 'info', autoDismiss: true });

    await farmContract.methods
      .deposit('0')
      .send({ from: account })
      .then(() => {
        addToast('Transaction Success!', { appearance: 'success', autoDismiss: true });
        setStakeValue('');
      })
      .catch(err => {
        if (err.message.includes('User denied transaction signature')) {
          addToast('Denied Transaction', { appearance: 'error', autoDismiss: true });
        } else {
          addToast('Transaction Failed', { appearance: 'error', autoDismiss: true });
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  return {
    earnedValue,
    isTokenApproved,
    onApprove,
    stakeBalance,
    stakeInput,
    unStakeInput,
    stakeValue,
    unStakeValue,
    stakeBalanceUsd,
    stakeDrop,
    unStakeDrop,
    onChangeStakeValue,
    onChangeUnStakeValue,
    handleDeposit,
    handleWithdraw,
    handleHarvest,
    handleMax,
    totalStakedInToken,
    youStakeValue,
    stakeValueUsd,
    liquidity,
    errorStake,
    loading,
    harvestLoading,
  };
};

export default useFarm;

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    inputContainerTop: {
        marginBottom: '16px',
        background: 'rgba(0, 0, 0, 0.25)',
        borderRadius: '16px',
    },
    mintButton: {
        marginTop: '16px',
        borderRadius: '100px',
        width: '100%',
    },
});

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  header: {
    background: '#191919',
    padding: '10px 32px 10px 40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  nav: {
    marginLeft: '48px',
  },
  link: {
    background: 'transparent',
    border: 'none',
    padding: '.5rem 1rem',
    color: '#ffffff',
    fontSize: '16px',
    fontWeight: '500',
    cursor: 'pointer',

    '&:hover': {
      background: '#1E1F20',
      borderRadius: '10px',
    },
  },
  button: {
    background: 'rgba(218, 161, 14, 0.15)',
    border: '1px solid #E0AA3A',
    borderRadius: '100px',
    color: '#E0AA3A',
    padding: '9px 20px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    fontSize: '18px',
    fontWeight: '500',

    '& svg': {
      marginRight: '8px',
    },

    '&:hover': {
      background: 'rgba(218, 161, 14, 0.25)',
      color: '#E0AA3A',
    },
  },

  row: {
    display: 'flex',
    alignItems: 'center',
  },

  price: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '34px',
    color: '#E0AA3A',
    fontSize: '16px',

    '& img': {
      height: '35px',
      weight: '35px',
      marginRight: '12px',
    },
  },

  navIcon: {
    color: '#E0AA3A',
  },
  menu: {
    background: '#191919',
    padding: '0 32px 0 40px',
    display: 'flex',
    flexDirection: 'column',
  },
  mobileLink: {
    color: '#ffffff',
    borderBottom: '1px solid #242424',
    width: '100%',
    textAlign: 'left',
  },
  otherButton: {
    display: 'flex',
    marginTop: '10px',
    paddingBottom: '10px',
  },
  logo: {
    minHeight: '52px',
    maxHeight: '65px',
  },
}));

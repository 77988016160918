import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  footer: {
    background: '#1E1F20',
    padding: '100px 200px',

    '@media (max-width: 1175px)': {
      padding: '66px 42px',
    },
  },
  rowList: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '100px',

    '@media (max-width: 1175px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  list: {
    display: 'grid',
    columnGap: '100px',
    gridTemplateColumns: 'repeat(3, 1fr)',
    color: '#CACACA',

    '& a': {
      display: 'block',
      marginTop: '20px',
      fontSize: '16px',
      fontWeight: '400',
      cursor: 'pointer',
      textDecoration: 'none',
      color: '#CACACA',

      '&:hover': {
        color: '#E0AA3A',
      },
    },

    '@media (max-width: 1175px)': {
      marginBottom: '30px',
      gridTemplateColumns: '1fr',
      textAlign: 'center',
    },
  },
  title: {
    textTransform: 'uppercase',
    fontSize: '20px',
    fontWeight: '700',
    color: '#ffffff',

    '@media (max-width: 1175px)': {
      marginTop: '30px',
    },
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#9F9F9F',

    '@media (max-width: 1175px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  link: {
    display: 'flex',

    '& a': {
      display: 'block',
      marginRight: '20px',
      cursor: 'pointer',
      color: '#9F9F9F',
      textDecoration: 'none',
      fontWeight: '500',

      '&:hover': {
        color: '#E0AA3A',
        '& svg': {
          '& path': {
            fill: '#E0AA3A',
          },
        },
      },
    },

    '@media (max-width: 1175px)': {
      marginBottom: '15px',
    },
  },
  logo: {
    maxWidth: '198px',
  },
}));

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    container: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        '& h1': {
            color: '#FFFFFF',
        },
    },
    content: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gridGap: '24px 15px',
        '@media (max-width: 1190px)': {
            gridTemplateColumns: '1fr',
        },
        '& a': {
            textDecoration: 'none',
        },
    },
}));

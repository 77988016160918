import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  card: {
    padding: '32px 41px',
    justifyContent: 'space-between',
    position: 'relative',
    maxWidth: '330px',
    minHeight: '370px',
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  flag: {
    position: 'absolute',
    top: '0',
    right: '0',
  },

  avatar: {
    width: '120px',
    height: '120px',
    marginBottom: '8px',
  },

  avatarGroup: {
    margin: '24px 0',
    color: 'white',
  },

  statistic: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '80%',
    marginBottom: '41px',
  },

  price: {
    color: '#FFFFFF',
    fontWeight: '500',
  },

  percent: {
    color: '#38EE7B',
    fontWeight: '500',
  },

  percentRed: {
    color: '#FF2424',
  },

  titleName: {
    fontWeight: '500',
    fontSize: '20px',
    maxWidth: '200px',
    textAlign: 'center',
  },

  button: {
    width: '100%',
    '& button': {
      width: '100%',
    },
  },
});

import { useCallback, useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { abis } from '@project/contracts';
import { makeContract } from '../utils/utils';

const useAllBalanceAvailable = (poolInfo, poolAmounts, inputValue) => {

    const { account, library } = useWeb3React();
    const [hasAllBalance, setHasAllBalance] = useState(true);
    const [checkBalanceStatus, setCheckBalanceStatus] = useState(false);

    const fetchBalance = useCallback(async tokenAddress => {
        const contract = makeContract(library, abis.erc20, tokenAddress);
        const balance = await contract.methods.balanceOf(account).call();
        return balance;
    }, [account, library]);

    const { tokens } = poolInfo;

    const getBalanceStatus = useCallback(async () => {

        if (inputValue === 0 && checkBalanceStatus === true) {
            setHasAllBalance(true);
        }
        tokens.forEach(async (token, index) => {
            const inputAmount = poolAmounts[index] || '0';
            const balance = await fetchBalance(token);
            if (balance === 0 || parseInt(balance) < parseInt(inputAmount)) {
                setHasAllBalance(false);
            }
        });

        setCheckBalanceStatus(true);
    }, [poolAmounts, checkBalanceStatus]);

    useEffect(() => {
        if (account && library) {
            getBalanceStatus();
        }

    }, [account, library, getBalanceStatus, checkBalanceStatus]);

    return hasAllBalance;
};

export default useAllBalanceAvailable;

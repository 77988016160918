import { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { abis } from '@project/contracts';
import { makeContract } from '../utils/utils';

const usePoolBalance = poolAddress => {
    const [balance, setBalance] = useState();
    const { library } = useWeb3React();

    useEffect(() => {
        if (library) {
            (async () => {
                const contract = makeContract(library, abis.indexpool, poolAddress);
                const currentTokens = await contract.methods.getCurrentTokens().call();
                const _balance = await Promise.all(currentTokens.map(async item => {
                    const tokenBalance = await contract.methods.getBalance(item).call();
                    return {
                        balance: tokenBalance,
                        address: item,
                    };
                }));
                setBalance(_balance);
            })();
        }
    }, [library, poolAddress]);

    return balance;
};

export default usePoolBalance;

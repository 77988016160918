import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { useWeb3React } from '@web3-react/core';

import Button from '../../components/Button';
import Loader from '../../components/Loader';
import InfoCard from './InfoCard';
import InfoCardYBF from './InfoCardYBF';
import ActionCard from './ActionCard';

import { FUNDS_TYPE } from '../../constants/global.constants';

import { useStyles } from './styles.module';

const Funds = ({ poolInfo, type }) => {
  const { chainId } = useWeb3React();
  const [showActionCard, setShowActionCard] = useState(false);
  const classes = useStyles({ showActionCard });

  return (
    <Grid container alignItems="flex-start" justifyContent="center" className={classes.container}>
      {(poolInfo && chainId) ? (
        <>
          {type === FUNDS_TYPE.INDEX_FUNDS && <InfoCard poolInfo={poolInfo} />}
          {type === FUNDS_TYPE.YBF_FUNDS && <InfoCardYBF poolInfo={poolInfo} />}
          <Grid item lg md className={classes.actionCard}>
            <ActionCard poolInfo={poolInfo} type={type} />
          </Grid>
          {showActionCard && <div className={classes.actionOverlay} />}
          {showActionCard && (
          <button className={classes.actionOverlayClose} onClick={() => setShowActionCard(false)}>
            CLOSE
          </button>
          )}
          <div className={classes.showActionButton}>
            <Button outLine onClick={() => setShowActionCard(true)}>
              Mint/Burn
            </Button>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </Grid>
  );
};

export default Funds;

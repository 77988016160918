import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        padding: '13px 18px',
    },
    itemLogo: {
        display: 'flex',
        alignItems: 'center',
        width: '50%',
    },
    item: {
        width: '25%',
        display: 'flex',
        justifyContent: 'center',
    },
    itemDemand: {
        width: '25%',
        color: props => (props.demandBalance ? '#DD3434' : '#fafafa'),
    },
    itemPercentage: {
        fontFamily: 'DM Mono',
    },
}));

export default useStyles;

import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../../../theme';

export const useStyles = makeStyles({
  content: {
    padding: '32px 24px',
    boxSizing: 'border-box',
    background: 'rgba(40, 44, 49, 0.3)',
    borderRadius: '32px',
  },
  text: {
    marginBottom: '30px',
  },
  titleButton: {
    fontSize: '20px',
    fontWeight: '700',
    background: 'none',
    outline: 'inherit',
    cursor: 'pointer',
    border: 'none',
    marginRight: '32px',
    color: theme.palette.text.primary,
  },
  activeButton: {
    borderBottom: '1px solid #FFFFFF',
  },
  description: {
    fontSize: '14px',
    fontWeight: '400',
    color: theme.palette.text.secondary,
  },
});

import BigNumber from 'bignumber.js';
import { addresses } from '@project/contracts';

import { isAddressesEqual, isAddressStable } from './utils';

const getLpTokenPriceUsd = async ({ lpTokenContract, nativeAssetPriceUsd, chainId }) => {

  const WBNB_ADDRESS = addresses[chainId].WBNB;
  const totalSupply = await lpTokenContract.methods.totalSupply().call();
  const token0 = await lpTokenContract.methods.token0().call();
  const token1 = await lpTokenContract.methods.token1().call();
  const { _reserve0, _reserve1 } = await lpTokenContract.methods.getReserves().call();
  const totalSupplyBN = new BigNumber(totalSupply).div(1e18);
  if (isAddressesEqual(token0, WBNB_ADDRESS) || isAddressesEqual(token1, WBNB_ADDRESS)) {
    const reserve = isAddressesEqual(token0, WBNB_ADDRESS) ? new BigNumber(_reserve0).div(1e18) : new BigNumber(_reserve1).div(1e18);
    return reserve.multipliedBy(nativeAssetPriceUsd).multipliedBy(2).div(totalSupplyBN);
  } if (isAddressStable(token0, chainId) || isAddressStable(token1, chainId)) {
    const reserve = isAddressStable(token0) ? new BigNumber(_reserve0).div(1e18) : new BigNumber(_reserve1).div(1e18);
    return reserve.multipliedBy(2).div(totalSupplyBN);
  }
};

export default getLpTokenPriceUsd;

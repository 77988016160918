import { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';
import { SHORT_TWAP_MIN, SHORT_TWAP_MAX } from '../utils/utils';
import { getOracle } from '../utils/contracts';
import { useBnbPriceState } from '../state/bnbPriceContext';
import { COMMON_BASE_TOKENS } from '../constants/chain.constants.js';

const useAveragePrice = tokenAddress => {
  const { account, library, chainId } = useWeb3React();
  const [price, setPrice] = useState(new BigNumber(0));
  const priceState = useBnbPriceState();
  const usdPrice = priceState?.[COMMON_BASE_TOKENS[chainId][0].priceId]?.usd;
  useEffect(() => {
    const fetchPrice = async _tokenAddress => {
      const contract = getOracle(library, chainId);
      const _price = await contract.methods
        .computeAverageTokenPrice(_tokenAddress, SHORT_TWAP_MIN, SHORT_TWAP_MAX)
        .call();
      const usePrice = new BigNumber(_price).div(2 ** 112).times(usdPrice);
      setPrice(usePrice);
    };

    if (account && library && tokenAddress) {
      fetchPrice(tokenAddress);
    }
  }, [account, usdPrice, chainId, library, tokenAddress]);

  return price;
};

export default useAveragePrice;

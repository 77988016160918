import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '20px 16px 20px',
        background: props => (props.gradient
            ? 'linear-gradient(270deg, rgba(218, 161, 14, 0.5) 0%, rgba(218, 161, 14, 0.21875) 36.98%, rgba(218, 161, 14, 0) 68.75%), #191919'
            : ''),
        borderRadius: '16px',
    },
    input: {
        color: props => (props.error ? '#DD3434' : '#9F9F9F'),
    },
    flexContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    header: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title: {
        color: '#ffffff',
    },
    balance: {
        color: '#9F9F9F',
        fontSize: '14px',
    },
    stakeToken: {
        background: 'rgba(255, 255, 255, 0.05)',
        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.25)',
        borderRadius: '100px',
        padding: '6px 8px',
        color: '#FFFFFF',
        fontSize: '14px',
        fontWeight: '700',
    },
    row: {
        display: 'flex',
    },
    maxButton: {
        background: 'transparent',
        border: 'none',
        marginRight: '16px',
        color: '#DAA10E',
        fontWeight: '700',
        fontSize: '14px',
        cursor: 'pointer',
        '& span': {
            marginLeft: '6px',
        },
        '&:hover': {
            color: 'rgba(218, 161, 14, 0.5)',
            '& svg': {
                '& path': {
                    fill: 'rgba(218, 161, 14, 0.5)',
                },
            },
        },
        '&:active': {
            color: 'rgba(218, 161, 14, 0.15)',
            '& svg': {
                '& path': {
                    fill: 'rgba(218, 161, 14, 0.15)',
                },
            },
        },
        '&:disabled': {
            pointerEvents: 'none',
            color: 'rgba(224, 170, 58, 0.25)',
            '& svg': {
                '& path': {
                    fill: 'rgba(224, 170, 58, 0.25)',
                },
            },
        },
    },
}));

export default useStyles;

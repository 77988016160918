import { useMemo } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useToasts } from 'react-toast-notifications';
import { abis } from '@project/contracts';
import BigNumber from 'bignumber.js';
import { makeContract } from '../../utils/utils';

import { GAS_MULTIPLYER } from '../../constants/global.constants';

const useMultiMint = ({ poolAddress, inputValue, maxAmountsIn, wrappedHandleChange }) => {
    const { account, library } = useWeb3React();
    const { addToast } = useToasts();
    const contract = makeContract(library, abis.indexpool, poolAddress);

    const poolAmountOut = useMemo(() => (
        library.utils.toWei(inputValue === '' ? '0' : inputValue, 'ether')
    ), [inputValue, library]);

    const handleMultiMint = async () => {
        const params = [
            poolAmountOut,
            maxAmountsIn.map(item => new BigNumber(item).multipliedBy(GAS_MULTIPLYER).toFixed(0)),
        ];
        const gas = await contract.methods
            .joinPool(...params)
            .estimateGas({ from: account })
            .then(res => new BigNumber(res).multipliedBy(GAS_MULTIPLYER).toFixed(0))
            .catch(() => addToast('Error during gas estimation', { appearance: 'error', autoDismiss: true }));
        if (!gas) return;

        addToast('Waiting for transaction success...', { appearance: 'info', autoDismiss: true });

        await contract.methods
            .joinPool(...params)
            .send({ from: account, gasLimit: gas }).then(() => {
                addToast('Transaction Success!', { appearance: 'success', autoDismiss: true });
                wrappedHandleChange('');
            }).catch(err => {
                if (err.message.includes('User denied transaction signature')) {
                    addToast('Denied Transaction', { appearance: 'error', autoDismiss: true });
                } else {
                    addToast('Transaction Failed', { appearance: 'error', autoDismiss: true });
                }
            });

    };

    return { handleMultiMint };
};
export default useMultiMint;

import React, { useState, useMemo } from 'react';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import BigNumber from 'bignumber.js';
import { addresses } from '@project/contracts';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { useWeb3React } from '@web3-react/core';

import Button from '../../../../../components/Button';
import InputToken from '../../../../../components/InputToken';
import Index from '../../../../../components/TokenListItem';

import { isApproved } from '../../../../../utils/utils.js';

import useAllowance from '../../../../../hooks/useAllowance.js';
import useApprove from '../../../../../hooks/useApprove.js';
import useRouterBurn from '../../../../../hooks/IndexPoolhooks/useRouterBurn.js';

import { COMMON_BASE_TOKENS } from '../../../../../constants/chain.constants.js';
import { BIT_10_SYMBOL } from '../../../../../constants/global.constants';

import { useStyles } from '../styles.module.js';

const RouterBurn = ({ poolInfo, inputValue, setInputValue, handleChange, inputError, handleMaxValue }) => {
  const { library, chainId } = useWeb3React();
  const classes = useStyles();
  const commonTokenList = poolInfo.symbol === BIT_10_SYMBOL ? COMMON_BASE_TOKENS[chainId][1] : COMMON_BASE_TOKENS[chainId][0];
  const [burnWithThisToken, setBurnWithThisToken] = useState(commonTokenList);
  const [loader, setLoader] = useState(false);
  const [tokenSelectList, setTokenSelectList] = useState(false);
  const [potentialBnbSwappedAmount, setPotentialBnbSwappedAmount] = useState(new BigNumber(0));
  const [amounts, setAmounts] = useState(poolInfo.tokens.map(() => '0'));

  const allowance = useAllowance(addresses[chainId][poolInfo.address].uniswapRouterBurner, poolInfo.address);

  const { onApprove } = useApprove(
    addresses[chainId][poolInfo.address].uniswapRouterBurner,
    poolInfo.address,
    setLoader,
  );

  const isTokenApproved = useMemo(
    () => isApproved(allowance, library.utils.toWei(inputValue === '' ? '0' : inputValue, 'ether')),
    [allowance, inputValue, library.utils],
  );

  const handleMintWithToken = token => {
    setBurnWithThisToken(token);
    setInputValue('');
    setPotentialBnbSwappedAmount(new BigNumber(0));
    setTokenSelectList(false);
  };
  const onApproveHandler = async () => {
    setLoader(true);
    await onApprove();
  };

  const wrappedHandleChange = value => {
    handleChange({ value, setPotentialBnbSwappedAmount, setAmounts });
  };

  const { handleBurn } = useRouterBurn({
    indexPool: poolInfo.address,
    indexPoolTokens: poolInfo.tokens,
    burnWithThisToken,
    wrappedHandleChange,
    inputValue,
    setLoader,
    setPotentialBnbSwappedAmount,
  });

  return (
    <div>
      <div className={classes.description}>
        Burn your {poolInfo.symbol} token directly to a single listed asset in one transaction using the underlaying
        tokens.
      </div>
      <div className={classes.container}>
        {!tokenSelectList ? (
          <>
            <div className={classes.inputContainer}>
              <InputToken
                onChange={wrappedHandleChange}
                poolInfo={poolInfo}
                tokenInfo={{
                  symbol: poolInfo.symbol,
                  address: poolInfo.address,
                }}
                size="large"
                disabled={poolInfo.symbol === BIT_10_SYMBOL}
                error={inputError}
                value={inputValue.toString()}
                gradient
                handleMaxValue={handleMaxValue}
                header={<div>Tokens to <b>burn</b></div>}
              />
            </div>
            <div className={classes.arrowContainer}>
              {' '}
              <ArrowDownwardIcon fontSize="large" />
            </div>
            <div className={classes.inputContainerBot}>
              <InputToken
                tokenInfo={burnWithThisToken}
                size="large"
                disabled
                valueWei
                header={<div><b>Redeem</b></div>}
                value={potentialBnbSwappedAmount.toString()}
                tokenSelectList={tokenSelectList}
                setTokenSelectList={poolInfo.symbol === BIT_10_SYMBOL ? () => {} : setTokenSelectList}
                showUsdPrice
              />
            </div>
          </>
        ) : (
          <div>
            <Button className={classes.backButton}>
              <NavigateBeforeIcon onClick={() => setTokenSelectList(false)} />
            </Button>
            <div className={classes.selectContainer}>
              {poolInfo.allTokensInfo.map(token => (
                <Index
                  token={token}
                  key={token.address}
                  poolInfo={poolInfo}
                  handleMintWithToken={handleMintWithToken}
                />
              ))}
            </div>
          </div>
        )}
      </div>
      {
        isTokenApproved ? (
          <>
            {
                Number(inputValue) > 0 ? (
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    loader={loader}
                    error={inputError}
                    onClick={handleBurn}
                    className={classes.mintButton}
                  >
                    {inputError || `Burn ${poolInfo.symbol}`}
                  </Button>
                ) : (
                  <Button outLine error={inputError} size="large" className={classes.mintButton} disabled>
                    {inputError || 'Enter an Amount'}
                  </Button>
                )
              }
          </>
        ) : (
          <Button
            variant="contained"
            color="primary"
            size="large"
            loader={loader}
            className={classes.mintButton}
            onClick={onApproveHandler}
          >
            Approve {poolInfo.symbol}
          </Button>
        )
      }
    </div>
  );
};

export default RouterBurn;

import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme';

export const useStyles = makeStyles(() => ({
  container: {
    justifyContent: 'center',
    color: theme.palette.text.primary,
  },
  sortButtons: {
    display: 'flex',
    marginBottom: '43px',
  },
  button: {
    '& button': {
      marginRight: '16px',
    },
  },
  active: {
    '& button': {
      border: '1px solid rgba(224, 170, 58, 0.5)',
    },
  },
  grid: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: '24px 32px',
    '@media (max-width: 1190px)': {
      gridTemplateColumns: '1fr',
    },
  },
}));

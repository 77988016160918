import { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';

import useGraphRequest from './useGraphRequest';
import { getTokenAvatar, getGraphUrl } from '../utils/utils';

import { getTokenInfo } from '../constants/graph.constants';

const useTokenInfo = tokenAddress => {
    const { chainId } = useWeb3React();
    const [tokenInfo, setTokenInfo] = useState({ name: null, symbol: null, priceUSD: null, decimals: null });
    const { data } = useGraphRequest(getGraphUrl(chainId), getTokenInfo(tokenAddress));

    useEffect(() => {
        if (data?.token) {
            const avatar = getTokenAvatar(tokenAddress, chainId);
            const { decimals, name, priceUSD, symbol } = data?.token;
            setTokenInfo({ name, symbol, priceUSD, decimals, avatar });
        }

    }, [data, chainId, tokenAddress]);

    return tokenInfo;
};

export default useTokenInfo;

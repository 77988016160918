import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme';

export const useStyles = makeStyles(_theme => ({
  container: {
    minHeight: '100vh',
    backgroundColor: theme.palette.background.dark,
  },
  content: {
    padding: '30px',
    backgroundColor: '#191919',
    minHeight: 'calc(100vh - 250px)',
    position: 'relative',
  },
  header: {
    position: 'relative',
    zIndex: 1,
  },
  footer: {
    zIndex: 0,
  },
}));

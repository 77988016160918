import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '20px 16px 20px',
        background: 'linear-gradient(270deg, rgba(218, 161, 14, 0.5) 0%, rgba(218, 161, 14, 0.21875) 36.98%, rgba(218, 161, 14, 0) 68.75%), #191919',
        borderRadius: '16px',
    },
    flexContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    margin: {
        margin: '16px 0',
    },
    balance: {
        color: '#9F9F9F',
        fontSize: '14px',
    },
    tokenSymbol: {
        color: '#F0B90B',
    },
    percent: {
        color: 'rgba(255, 255, 255, 0.87)',
        fontSize: '24px',
        fontWeight: '700',
    },
    percentButton: {
        background: '#DAA10E',
        borderRadius: '35px',
        border: 'none',
        padding: '6px 21px',
        cursor: 'pointer',
        fontSize: '14px',
        fontWeight: '700',
        '&:hover': {
            background: 'rgba(218, 161, 14, 0.45)',
            color: '#ffffff',
        },
        '&:active': {
            transform: 'translateY(1px)',
        },
        '@media (max-width: 1280px)': {
            padding: '6px 14px',
        },
    },
    smallButton: {
        '& button': {
            padding: '6px 14px',
        },
    },
}));

export default useStyles;

import React, { useState } from 'react';
import BigNumber from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';

import { calcAllOutGivenPoolIn } from '../../../../utils/bmath';

import RouterBurn from './RouterBurn';
import MultiBurn from './MultiBurn';

import useAllowance from '../../../../hooks/useAllowance.js';

import { useStyles } from './styles.module';
import useTokenBalance from '../../../../hooks/useTokenBalance.js';

const BurnPanel = ({ poolInfo }) => {
  const { library } = useWeb3React();
  const poolAllowance = useAllowance(poolInfo.address, poolInfo.address);
  const [inputValue, setInputValue] = useState('');
  const [asset, setAsset] = useState('ROUTER');
  const [inputError, setInputError] = useState();
  const poolTokenBalance = useTokenBalance(poolInfo.address);
  const classes = useStyles();

  const handleChange = ({ value, setPotentialBnbSwappedAmount, handleAmounts, setAmounts }) => {
    setInputError();
    if (value !== undefined && value !== '' && Number(value) >= 0 && poolInfo) {
      const val = library.utils.toWei(value, 'ether');
      if (new BigNumber(val).gt(poolInfo.totalSupply)) {
        setInputError(`Max value is ${new BigNumber(poolInfo.totalSupply).div(1e18).toFixed(4)} ${poolInfo.symbol}`);
        return;
      }
      if (Number(value) > Number(library.utils.fromWei(poolTokenBalance?.toFixed(0), 'ether'))) {
        setInputError('Insufficient balance');
        return;
      }
      setInputError(null);
      setInputValue(value);
      const balanceArray = poolInfo.balances.map(item => new BigNumber(item.balance));
      const _amounts = calcAllOutGivenPoolIn(balanceArray, poolInfo.weights, poolInfo.totalSupply, val);
      if (setAmounts) setAmounts(_amounts);
      if (handleAmounts) handleAmounts(val, poolInfo);
    } else {
      const val = '';
      setInputError(null);
      setInputValue(val);
      if (setAmounts) setAmounts(poolInfo.tokens.map(() => '0'));
      if (setPotentialBnbSwappedAmount) setPotentialBnbSwappedAmount(new BigNumber(0));
    }
  };

  const handleSetAsset = assetType => {
    setAsset(assetType);
    setInputValue('');
  };

  const handleMaxValue = value => {
    setInputValue(value);
  };

  if (!poolTokenBalance) return null;

  return (
    <div>
      <div className={classes.buttonGroup}>
        <button
          className={`${classes.buttonTab} ${asset === 'ROUTER' && classes.activeButton}`}
          onClick={() => handleSetAsset('ROUTER')}
        >
          Single Asset
        </button>
        <button
          className={`${classes.buttonTab} ${asset === 'MULTI' && classes.activeButton}`}
          onClick={() => handleSetAsset('MULTI')}
        >
          Multi Asset
        </button>
      </div>
      {asset === 'ROUTER' ? (
        <RouterBurn
          poolInfo={poolInfo}
          inputValue={inputValue}
          setInputValue={setInputValue}
          handleChange={handleChange}
          inputError={inputError}
          poolAllowance={poolAllowance}
          handleMaxValue={handleMaxValue}
        />
      ) : (
        <MultiBurn
          poolAllowance={poolAllowance}
          poolInfo={poolInfo}
          inputValue={inputValue}
          handleChange={handleChange}
          inputError={inputError}
          handleMaxValue={handleMaxValue}
        />
      )}
    </div>
  );
};

export default BurnPanel;

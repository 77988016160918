import React, { useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import MaterialCard from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useWeb3React } from '@web3-react/core';

import MintPanel from './MintPanel';
import BurnPanel from './BurnPanel';
import MintPanelYBF from './MintPanelYBF';
import BurnPanelYBF from './BurnPanelYBF';
import FarmsPanel from './FarmsPanel';

import farms from '../../../config/farms.json';
import { FUNDS_TYPE } from '../../../constants/global.constants';

import { useStyles } from './styles.module';

const ActionCard = ({ poolInfo, type }) => {
  const { chainId } = useWeb3React();
  const [currentActionTab, setCurrentActionTab] = useState('MINT');
  const classes = useStyles({ currentActionTab });

  const isFarm = useMemo(() => (
      farms[chainId].filter(item => item.stakingTokenAddress.toLowerCase() === poolInfo.address.toLowerCase())
  ), [chainId, poolInfo.address]);

  return (
    <MaterialCard className={classes.card}>
      <Grid container alignItems="flex-start">
        <div className={classes.header}>
          <button
            className={classNames(classes.button, { [classes.activeButton]: currentActionTab === 'MINT' })}
            onClick={() => setCurrentActionTab('MINT')}
          >
            {type === FUNDS_TYPE.YBF_FUNDS && 'Deposit'}
            {type === FUNDS_TYPE.INDEX_FUNDS && 'Mint'}
          </button>
          <button
            className={classNames(classes.button, { [classes.activeButton]: currentActionTab === 'BURN' })}
            onClick={() => setCurrentActionTab('BURN')}
          >
            {type === FUNDS_TYPE.YBF_FUNDS && 'Withdraw'}
            {type === FUNDS_TYPE.INDEX_FUNDS && 'Burn'}
          </button>
          {
            !!isFarm.length && (
              <button
                className={classNames(classes.button, { [classes.activeButton]: currentActionTab === 'FARM' })}
                onClick={() => setCurrentActionTab('FARM')}
              >
                Farm
              </button>
            )
          }
        </div>
        <Grid item xs={12} className={classNames(classes.content, { [classes.farmPanel]: currentActionTab === 'FARM' })}>
          {poolInfo ? (
            <>
              {
                type === FUNDS_TYPE.YBF_FUNDS && (
                <>
                  {currentActionTab === 'MINT' && <MintPanelYBF poolInfo={poolInfo} />}
                  {currentActionTab === 'BURN' && <BurnPanelYBF poolInfo={poolInfo} />}
                </>
                )
              }
              {
                type === FUNDS_TYPE.INDEX_FUNDS && (
                <>
                  {currentActionTab === 'MINT' && <MintPanel poolInfo={poolInfo} />}
                  {currentActionTab === 'BURN' && <BurnPanel poolInfo={poolInfo} />}
                </>
                )
              }
              {currentActionTab === 'FARM' && <FarmsPanel farm={isFarm} />}
            </>
          ) : (
            <div className={classes.spinner}>
              <CircularProgress color="primary" />
            </div>
          )}
        </Grid>
      </Grid>
    </MaterialCard>
  );
};

export default ActionCard;

import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import BigNumber from 'bignumber.js';
import Avatar from '@material-ui/core/Avatar';

import ProgressBar from '../../../../../components/ProgressBar';

import { formatter, toScaledBN } from '../../../../../utils/utils';

import useTokenInfo from '../../../../../hooks/useTokenInfo';

import { useStyles } from './styles.module';

const TokenInfo = ({
  poolInfo, poolToken, balance,
}) => {
  const classes = useStyles();
  const { name, symbol, avatar, priceUSD, decimals } = useTokenInfo(poolToken);
  const matches = useMediaQuery('(max-width:450px)');

  const usdValue = useMemo(() => (
      priceUSD && new BigNumber(priceUSD).times(balance).div(toScaledBN(decimals)).toNumber()
  ), [balance, priceUSD, decimals]);

  const weightPercentage = useMemo(() => (
      ((100 * Number(usdValue)) / poolInfo?.tvl.toNumber())
  ), [poolInfo, usdValue]);

  const balanceValue = useMemo(() => (
    balance && new BigNumber(balance).div(toScaledBN(decimals)).toNumber()
  ), [balance, decimals]);

  return (
    <Grid
      container
      spacing={1}
      className={classes.root}
      direction="row"
    >
      <Grid container item xs>
        <Grid
          container
          item
          xs={12}
          alignItems="flex-start"
          justifyContent="space-between"
          className={classes.wrap}
        >
          <Grid item className={classes.row}>
            <Grid item>
              <Avatar src={avatar} variant="square" className={classes.avatar} />
            </Grid>
            <Grid item className={classes.tokenNameColumn}>
              <Grid item>
                <span>{symbol?.toUpperCase()}</span>
                {!matches && <span className={classes.tokenName}>{name}</span>}
              </Grid>
              {
                  (!!balanceValue && !!usdValue) && (
                  <Grid item className={classes.text}>
                    {formatter.format(balanceValue)}{' = $'}{formatter.format(usdValue)}
                  </Grid>
                  )
                }
            </Grid>
          </Grid>
          <Grid item className={classes.column}>
            {priceUSD && <Grid item>${formatter.format(priceUSD)}</Grid>}
            {weightPercentage && <Grid item className={classes.textPercentage}>{weightPercentage.toFixed(2)}%</Grid>}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ProgressBar percentage={weightPercentage || '0'} />
        </Grid>
      </Grid>
    </Grid>
  );
};

TokenInfo.defaultProps = {
  handleSetUsdValue: () => { },
};

export default TokenInfo;

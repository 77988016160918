import { useEffect, useState } from 'react';
import { MultiCall } from 'eth-multicall';
import { useWeb3React } from '@web3-react/core';
import { abis } from '@project/contracts';
import BigNumber from 'bignumber.js';
import { request } from 'graphql-request';

import { getGraphYbfUrl, makeContract, toScaledBN } from '../../utils/utils';
import getLpTokenPriceUsd from '../../utils/getLpTokenPriceUsd';
import getNativeChainPrice from '../../utils/getNativeChainPrice';
import getTokenPriceByAddress from '../../utils/getTokenPriceByAddress';

import { useCurrentBlock } from '../../state/currentBlockContext';
import { useBnbPriceState } from '../../state/bnbPriceContext';

import { MULTI_CALLS, SECOND_PER_YEAR, STAKED_TOKEN_TYPE } from '../../constants/global.constants';
import { getYbfPoolInfo } from '../../constants/graph.constants';
import { SECONDS_PER_BLOCK } from '../../constants/chain.constants';

const useFarmsInfo = farmList => {
  const { chainId, library, account } = useWeb3React();
  const [farmInfo, setFarmInfo] = useState([]);
  const currentBlock = useCurrentBlock();
  const tokenUsdPrices = useBnbPriceState();

  useEffect(() => {
    if (farmList?.length && tokenUsdPrices) {
      (async () => {
        const multiCall = new MultiCall(library, MULTI_CALLS[chainId]);

        const [multiCallResult] = await multiCall.all([
          farmList.map(farm => {
            const farmContract = makeContract(library, farm.oldFarm ? abis.oldFarm : abis.newFarm, farm.contractAddress);

            return {
              farmName: farm.poolName,
              farmAddress: farm.contractAddress,
              startBlock: farmContract.methods.startBlock(),
              endBlock: farmContract.methods.bonusEndBlock(),
              userInfo: farmContract.methods.userInfo(account),
              rewardPerBlock: farmContract.methods.rewardPerBlock(),
              stakingTokenAddress: farm.stakingTokenAddress,
              stakingDecimals: farm.stakingDecimals,
              stakingSymbol: farm.stakingSymbol,
              getStakingTokenLink: farm.getStakingTokenLink,
              earningToken: farm.earningToken,
              earningDecimals: farm.earningDecimals,
              earningTokenSymbol: farm.earningTokenSymbol,
              rewardValue: farmContract.methods.pendingReward(account),
              stakedTokenType: farm.stakedTokenType,
            };
          }),
        ]);
        const result = await Promise.all(
          multiCallResult.map(async item => {
            const tokenContract = makeContract(library, abis.erc20, item.stakingTokenAddress);
            const totalStaked = await tokenContract.methods.balanceOf(item.farmAddress).call();
            let stakeTokenPrice = new BigNumber(0);

            const nativeAssetPriceUsd = getNativeChainPrice({ tokenUsdPrices, chainId });
            const earningTokenPrice = getTokenPriceByAddress({
              tokenUsdPrices,
              chainId,
              tokenAddress: item.earningToken,
            });

            const rewardPerYearUSD = new BigNumber(item.rewardPerBlock)
              .div(1e18)
              .multipliedBy(earningTokenPrice)
              .multipliedBy(SECOND_PER_YEAR.div(new BigNumber(SECONDS_PER_BLOCK[chainId])));
            const earningValue = new BigNumber(item.rewardValue).multipliedBy(earningTokenPrice).div(1e18);

            if (item.stakedTokenType === STAKED_TOKEN_TYPE.LP) {
              const lpTokenContract = makeContract(library, abis.pairs, item.stakingTokenAddress);
              stakeTokenPrice = await getLpTokenPriceUsd({
                lpTokenContract,
                chainId,
                nativeAssetPriceUsd,
              });
            }
            if (item.stakedTokenType === STAKED_TOKEN_TYPE.SINGLE) {
              stakeTokenPrice = new BigNumber(getTokenPriceByAddress({
                tokenUsdPrices,
                chainId,
                tokenAddress: item.stakingTokenAddress,
              }));
            }
            if (item.stakedTokenType === STAKED_TOKEN_TYPE.YBF) {
                const { indexPool } = await request(getGraphYbfUrl(chainId), getYbfPoolInfo(item.stakingTokenAddress));
                stakeTokenPrice = new BigNumber(indexPool.totalValueLockedUSD)
                    .div(new BigNumber(indexPool.totalSupply).div(1e18));
            }

            const totalStakedInUsd = new BigNumber(totalStaked)
              .div(toScaledBN(item.stakingDecimals))
              .multipliedBy(stakeTokenPrice);
            const apr = rewardPerYearUSD.div(totalStakedInUsd).multipliedBy(100);
            return {
              ...item,
              stakeTokenPrice,
              apr,
              earningValue,
              totalStaked,
              isFinished: item.endBlock < currentBlock,
            };
          }),
        );
        setFarmInfo(result);
      })();
    }
  }, [library, chainId, farmList, account, currentBlock, tokenUsdPrices]);

  return farmInfo;
};

export default useFarmsInfo;

import React from 'react';
import { useParams } from 'react-router-dom';

import Funds from '../Funds';

import usePoolInfo from '../../hooks/IndexPoolhooks/usePoolInfo';

import { FUNDS_TYPE } from '../../constants/global.constants';

const MainFunds = () => {
    const { address } = useParams();
    const poolInfo = usePoolInfo(address);

    return (
      <Funds poolInfo={poolInfo} type={FUNDS_TYPE.INDEX_FUNDS} />
    );
};

export default MainFunds;

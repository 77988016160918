import React from 'react';
import classNames from 'classnames';
import { LineChart, XAxis, YAxis, Line, Tooltip, ResponsiveContainer } from 'recharts';

import useCharts from '../../hooks/useCharts';

import { LAST_DAY, LAST_WEEK } from '../../constants/global.constants';

import { useStyles } from './styles.module';

const PoolChart = ({ address }) => {
    const classes = useStyles();
    const { chartData, setWeek, setLastDay, timestamp, snapshotPrice, percentPrice, fondPrice, xTicks } = useCharts(address);

    return (
      <div className={classes.chartWrap}>
        <div className={classes.buttonWrap}>
          <div className={classes.row}>
            <div className={classNames(classes.title, classes.goldColor)}>
              ${fondPrice ? Number(fondPrice).toFixed(3) : '-'}
            </div>
            <div className={classes.title}>
              {snapshotPrice > 0 ? '+' : ''}
              {snapshotPrice ? Number(snapshotPrice).toFixed(2) : '-'}$
              ({percentPrice > 0 ? '+' : ''}{percentPrice ? Number(percentPrice).toFixed(2) : '-'}%)
            </div>
          </div>
          <div className={classes.row}>
            <button
              className={classNames(classes.chartButton, { [classes.activeButton]: timestamp === LAST_DAY })}
              onClick={setLastDay}
            >
              Day
            </button>
            <button
              className={classNames(classes.chartButton, { [classes.activeButton]: timestamp === LAST_WEEK })}
              onClick={setWeek}
            >
              Week
            </button>
          </div>

        </div>
        <ResponsiveContainer height={400}>
          <LineChart
            data={chartData}
          >
            <XAxis dataKey="date" ticks={xTicks} tickMargin={15} />
            <YAxis dataKey="price" domain={['auto', 'dataMax']} tickMargin={15} />
            <Tooltip
              cursor={{ strokeWidth: 1, strokeDasharray: 2 }}
              contentStyle={{
                  background: 'rgba(218, 161, 14, 0.25)',
                  border: 'none',
                  borderRadius: '8px',
              }}
            />
            <Line type="monotone" dataKey="price" stroke="#DAA10E" dot={false} />
            <Line type="monotone" dataKey="price1" stroke="#DAA10E" dot={false} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
};

export default PoolChart;

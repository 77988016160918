import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    tokenButtonLabel: {
        width: '22px',
        height: '22px',
        marginRight: '6px',
    },
    tokenButtonImg: {
        width: '22px',
        height: '22px',
    },
    selectItems: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    tokenButtonLogoGroup: {
        display: 'flex',
        alignItems: 'center',
    },
    usdBalance: {
        fontSize: '12px',
        color: '#9F9F9F',
        textAlign: 'end',
    },
}));

export default useStyles;

import React, { useCallback, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import BigNumber from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';

import RouterMint from './RouterMint';
import MultiMint from './MultiMint';

import { useStyles } from './styles.module';

const MintPanel = ({ poolInfo }) => {
  const { library } = useWeb3React();

  const [inputValue, setInputValue] = useState('');
  const [asset, setAsset] = useState('ROUTER');
  const [inputError, setInputError] = useState();
  const classes = useStyles({ asset });

  const handleChange = useCallback(
    ({ value, setPotentialBnbSwappedAmount, handleAmounts, setAmounts, setIsDemandGreaterThanBalance }) => {
      if (value !== undefined && value !== '' && Number(value) >= 0) {
        const val = library.utils.toWei(value, 'ether');
        if (new BigNumber(val).gt(poolInfo.totalSupply)) {
          setInputError(`Max value is ${new BigNumber(poolInfo.totalSupply).div(1e18).toFixed(4)} ${poolInfo.symbol}`);
          return;
        }
        setInputError(null);
        setInputValue(value);
        if (handleAmounts) handleAmounts(val, poolInfo);
        if (setIsDemandGreaterThanBalance) setIsDemandGreaterThanBalance(false);
      } else {
        setInputError(null);
        setInputValue('');
        if (setAmounts) setAmounts(poolInfo.tokens.map(() => '0'));
        if (setPotentialBnbSwappedAmount) setPotentialBnbSwappedAmount(new BigNumber(0));
      }
    },
    [library, poolInfo],
  );

  const handleSetAsset = assetType => {
    setAsset(assetType);
    setInputValue('');
  };

  return (
    <div>
      <div className={classes.buttonGroup}>
        <button
          className={`${classes.buttonTab} ${asset === 'ROUTER' && classes.activeButton}`}
          onClick={() => handleSetAsset('ROUTER')}
        >
          Single Asset
        </button>
        <button
          className={`${classes.buttonTab} ${asset === 'MULTI' && classes.activeButton}`}
          onClick={() => handleSetAsset('MULTI')}
        >
          Multi Asset
        </button>
      </div>
      {asset === 'ROUTER' ? (
        <RouterMint
          poolInfo={poolInfo}
          inputValue={inputValue}
          setInputValue={setInputValue}
          handleChange={handleChange}
          inputError={inputError}
        />
      ) : (
        <MultiMint
          poolInfo={poolInfo}
          inputValue={inputValue}
          handleChange={handleChange}
          inputError={inputError}
        />
      )}
    </div>
  );
};

export default MintPanel;

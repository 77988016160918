import React, { useEffect, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import Avatar from '@material-ui/core/Avatar';

import { useWeb3React } from '@web3-react/core';
import ApproveButton from '../buttons/ApproveButton.component';

import { formatter } from '../../utils/utils.js';
import useTokenInfo from '../../hooks/useTokenInfo.js';
import useTokenBalance from '../../hooks/useTokenBalance.js';

import useStyles from './styles.module';

const TokenItem = ({ value, index, poolAmounts, address, onDemandGreaterThanBalance, withButton, demandBalance, weight,
       totalWeight,
}) => {
  const classes = useStyles();
  const { symbol, avatar } = useTokenInfo(value);
  const balance = useTokenBalance(value);
  const { library } = useWeb3React();

  const weightPercentage = useMemo(() => totalWeight && (100 * Number(weight)) / Number(totalWeight), [
    totalWeight,
    weight,
  ]);

  useEffect(() => {
    const bnDemandValue = Number(poolAmounts[index]);
    if (onDemandGreaterThanBalance && bnDemandValue > 0) {
      const newValue = bnDemandValue > Number(balance);
      onDemandGreaterThanBalance(symbol, newValue);
    }
  }, [balance, onDemandGreaterThanBalance, symbol, poolAmounts, index]);

  return (
    <div className={classes.container}>
      <div className={classes.itemLogo}>
        <ListItemAvatar>
          <Avatar alt="Avatar" src={avatar} />
        </ListItemAvatar>
        {symbol}
      </div>
      <div className={classes.itemDemand}>
        {formatter.format(library.utils.fromWei(poolAmounts[index], 'ether'))}
        {demandBalance}
      </div>
      {withButton ? (
        <div className={classes.item}>
          <ListItem>
            <ApproveButton poolAddress={address} poolTokenAddress={value} />
          </ListItem>
        </div>
      ) : <div className={classes.itemPercentage}>{weightPercentage.toFixed(2)}%</div>}
    </div>
  );
};

const TokenList = ({ poolInfo, poolAmounts, withButton, onDemandGreaterThanBalance, demandBalanceMap }) => {
  const classes = useStyles();
  return (
    <>
      {poolInfo?.tokens && poolAmounts && poolInfo?.address && (
        <Grid container>
          <List className={classes.root} dense>
            {poolInfo?.tokens.map((value, index) => (
              <TokenItem
                key={value}
                address={poolInfo?.address}
                value={value}
                index={index}
                weight={poolInfo.weights.find(item => item.address === value.toLowerCase()).weight}
                poolAmounts={poolAmounts}
                withButton={withButton}
                totalWeight={poolInfo?.totalWeight}
                onDemandGreaterThanBalance={onDemandGreaterThanBalance}
                demandBalance={demandBalanceMap?.[Object.keys(demandBalanceMap)?.[index]]}
              />
            ))}
          </List>
        </Grid>
      )}
    </>
  );
};

TokenList.defaultProps = {
  handleMintDisabling: () => {},
  onDemandGreaterThanBalance: () => {},
};

export default TokenList;

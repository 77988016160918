/* eslint-disable no-else-return */
import React, { memo } from 'react';
import Countdown from 'react-countdown';

const FarmCountdown = memo(({ styleClass, dateEnd }) => {
  const renderer = ({ formatted: { days, hours, minutes } }) => {
    days = Number(days);
    hours = Number(hours);
    minutes = Number(minutes);
    if (days > 0) {
      return <div className={styleClass}>Ends in {days} days</div>;
    } else if (hours > 0) {
      return (
        <div className={styleClass}>
          Ends in {hours}h:{minutes}m
        </div>
      );
    } else {
      return <div className={styleClass}>{minutes}m</div>;
    }
  };

  return <Countdown zeroPadTime={400} precision={100} date={new Date(dateEnd)} renderer={renderer} />;
});

export default FarmCountdown;

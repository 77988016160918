import { useState, useEffect } from 'react';
import moment from 'moment';
import useGraphRequest from './useGraphRequest';

import { getInfoForCharts, getInfoExchangeForCharts } from '../constants/graph.constants';
import { API_GRAPH_FUND, API_GRAPH_EXCHANGE } from '../constants/routes.constants';
import { LAST_DAY, LAST_WEEK } from '../constants/global.constants';

const useCharts = address => {
    const [timestamp, setTimestamp] = useState(LAST_DAY);
    const [chartData, setChartData] = useState();

    const [snapshotPrice, setSnapshotPrice] = useState();
    const [percentPrice, setPercentPrice] = useState();
    const [fondPrice, setFondPrice] = useState();

    const [xTicks, setXTicks] = useState();

    const { data } = useGraphRequest(API_GRAPH_FUND, getInfoForCharts(address, timestamp.toFixed(0)));

    useEffect(() => {
        if (data?.indexPool) {
            const { dailySnapshots } = data.indexPool;
            const newData = dailySnapshots.map(item => ({
                price: Number(item.value).toFixed(2),
                date: moment(Number(item.date) * 1e3).format(timestamp === LAST_DAY ? 'HH:mm' : 'MMM DD'),
            }));
            setXTicks([...new Set(newData.map(item => item.date))]);
            setSnapshotPrice(dailySnapshots[dailySnapshots.length - 1].value - dailySnapshots[0].value);
            setPercentPrice((dailySnapshots[dailySnapshots.length - 1].value / dailySnapshots[0].value - 1) * 100);
            setFondPrice(dailySnapshots[dailySnapshots.length - 1].value);
            setChartData(newData);
        }
    }, [data, timestamp]);

    const setWeek = () => setTimestamp(LAST_WEEK);
    const setLastDay = () => setTimestamp(LAST_DAY);

    return { chartData, setWeek, setLastDay, timestamp, snapshotPrice, percentPrice, fondPrice, xTicks };
};

export default useCharts;

import { gql } from 'graphql-request';
import { LAST_DAY, LAST_TWO_DAYS } from './global.constants';

export const getIndexPoolTokens = id => (
  gql`
    {
      indexPool(id: "${id?.toLowerCase()}") {
        id
        name
        symbol
        tokensList
        totalValueLockedUSD
        totalSupply
        totalWeight
        swapFee
        dailySnapshots(where: {date_gt: ${LAST_DAY.toFixed(0)}}) {
            date
            value
            totalValueLockedUSD
        }
        tokens {
          balance
          denorm
          token {
            id
            name
            priceUSD
          }
        }
      }
}
`);

export const getYBFPool = id => (
  gql`
   {
    indexPool(id: "${id?.toLowerCase()}"){
        name,
        totalSupply,
        symbol,
        totalWeight,
        totalValueLockedUSD,
        dailySnapshots(where: {date_gt: ${LAST_DAY.toFixed(0)}}) {
            date,
            totalValueLockedUSD
        }
        tokens {
          balance,
          token {
            id,
            priceUSD,
            strategy {
              rewardToken,
              poolId,
              masterChef,
              lpToken0 {
                name,
                symbol,
              }
              lpToken1 {
                name,
                symbol,
              }
              lpToken {
                symbol
                id
                decimals
              }
            }
          }
        }
    } 
   }
`);

export const getYbfDashboard = ids => (
    gql`
    {
        indexPools(where: {id_in: [${ids?.map(item => `"${item?.toLowerCase()}"`)}] }){
            id,
            name,
            symbol,
            tokens {
                token {
                    id,
                    symbol,
                    strategy { 
                        lpToken {
                            symbol,
                            id,
                        }
                    }
                }
            }
        } 
   }
`);

export const getIndexDashboard = ids => (
    gql`
    {
        indexPools(where: {id_in: [${ids?.map(item => `"${item?.toLowerCase()}"`)}] }){
            id,
            name,
            symbol,
            totalSupply,
            totalValueLockedUSD,
            tokens {
                token {
                    id,
                    symbol,
                }
            }
            
        } 
   }
`);

export const getYbfPoolInfo = id => (
    gql`
   {
    indexPool(id: "${id?.toLowerCase()}"){
        name,
        totalSupply,
        symbol,
        totalValueLockedUSD,
    } 
   }
`);

export const getTokenInfo = id => (
  gql`
    {
      token(id: "${id?.toLowerCase()}"){
        id
        symbol
        name
        decimals
        priceUSD
      }
}`);

export const getExchangePoolInfo = id => (
  gql`
    {
      token(id: "${id?.toLowerCase()}"){
        id
        tradeVolumeUSD
        derivedUSD
        tokenDayData(where: {date_gt: ${LAST_TWO_DAYS.toFixed(0)}}) {
          date
          dailyVolumeUSD
          priceUSD
        }
      }
    }
`);

export const getInfoForCharts = (id, timestamp) => (
  gql`
    {
      indexPool(id: "${id?.toLowerCase()}") {
        dailySnapshots(where: {date_gt: ${timestamp}}) {
            date
            value
        }
      }}`
);

export const getInfoExchangeForCharts = (id, timestamp) => (
  gql`
    {
      token(id: "${id?.toLowerCase()}") {
        tokenDayData(where: {date_gt: ${timestamp}}) {
            date
            priceUSD
        }
      }}`
);

export const getIndexPoolInfo = id => (
  gql`
    {
      indexPool(id: "${id?.toLowerCase()}"){
        id
        symbol
        name
      }
}`);

import React from 'react';
import classNames from 'classnames';
import { InputBase } from '@material-ui/core';

import { formatBN } from '../../utils/utils';

import { MagicIcon } from '../../constants/icons.constants';
import useStyles from './styles.module';

const Input = ({
  gradient,
  className,
  header,
  balance,
  onChange,
  disabled,
  error,
  value,
  placeholder,
  tokenName,
  handleMax,
  stakeBalanceUsd,
}) => {
  const classes = useStyles({ gradient });

  return (
    <div className={classNames(classes.container, className)}>
      <div className={classes.flexContainer}>
        <div className={classes.header}>
          <div className={classes.title}>{header}</div>
          <div className={classes.balance}>{`Balance: ${balance ? formatBN(balance, 3) : '0'}`}</div>
        </div>
      </div>
      <div className={classes.flexContainer}>
        <InputBase
          value={value || ''}
          onChange={(event) => onChange(event.target.value)}
          disabled={disabled}
          error={error}
          placeholder={placeholder}
          inputProps={{ 'aria-label': 'naked', className: classes.input }}
        />
      </div>
      <div className={classes.flexContainer}>
        <div>{!!stakeBalanceUsd && `$${formatBN(stakeBalanceUsd, 3)}`}</div>
        <div className={classes.row}>
          {handleMax && (
            <button className={classes.maxButton} onClick={handleMax} disabled={!Number(balance)}>
              {MagicIcon}
              <span>MAX</span>
            </button>
          )}
          <div className={classes.stakeToken}>{tokenName}</div>
        </div>
      </div>
    </div>
  );
};

Input.defaultProps = {
  gradient: false,
  className: '',
  header: '',
  balance: '',
  placeholder: '0',
  handleMax: false,
};

export default Input;

import { useMemo, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useToasts } from 'react-toast-notifications';
import { abis, addresses } from '@project/contracts';

import BigNumber from 'bignumber.js';
import { ADDRESS_ZERO, GAS_MULTIPLYER, WBNB } from '../../constants/global.constants';
import { makeContract } from '../../utils/utils';

const useRouterBurn = ({ indexPool, indexPoolTokens, burnWithThisToken, wrappedHandleChange, inputValue, setLoader,
       setPotentialBnbSwappedAmount,
}) => {
    const { account, library, chainId } = useWeb3React();
    const { addToast } = useToasts();

    const contract = makeContract(
        library,
        abis.uniswapRouterBurner,
        addresses[chainId][indexPool].uniswapRouterBurner,
    );

    const isMainToken = burnWithThisToken.address === ADDRESS_ZERO || burnWithThisToken.symbol === WBNB;
    const burnToken = isMainToken ? addresses[chainId].WBNB : burnWithThisToken.address;

    const intermediaries = useMemo(() => (
        indexPoolTokens.map(item => (
            item === addresses[chainId].WBNB.toLowerCase() ? ADDRESS_ZERO : isMainToken ? ADDRESS_ZERO : addresses[chainId].WBNB
        ))
    ), [indexPoolTokens, isMainToken, chainId]);

    const poolAmountIn = useMemo(() => (
        library.utils.toWei(inputValue === '' ? '0' : inputValue, 'ether')
    ), [inputValue, library]);

    const minAmountsOut = useMemo(() => indexPoolTokens.map(() => 1), [indexPoolTokens]);

    useEffect(() => {
        if (inputValue) {
            (async () => {
                const onMinAmountForBurn = await contract.methods
                    .burnForAllTokensAndSwapForTokens(indexPool, minAmountsOut, intermediaries, poolAmountIn, burnToken, '1')
                    .call({ from: account });
                setPotentialBnbSwappedAmount(onMinAmountForBurn);
            })();
        }
    }, [
        account,
        contract,
        intermediaries,
        minAmountsOut,
        burnToken,
        indexPool,
        inputValue,
        poolAmountIn,
        setPotentialBnbSwappedAmount,
    ]);

    const handleBurn = async () => {
        setLoader(true);
        if (isMainToken) {
            const gas = await contract.methods
                .burnForAllTokensAndSwapForETH(indexPool, minAmountsOut, intermediaries, poolAmountIn, '1')
                .estimateGas({ from: account })
                .then(res => new BigNumber(res).multipliedBy(GAS_MULTIPLYER).toFixed(0))
                .catch(() => {
                    addToast('Error during gas estimation', { appearance: 'error', autoDismiss: true });
                    setLoader(false);
                });
            if (!gas) return;
            addToast('Waiting for transaction success...', { appearance: 'info', autoDismiss: true });
            await contract.methods
                .burnForAllTokensAndSwapForETH(indexPool, minAmountsOut, intermediaries, poolAmountIn, '1')
                .send({ from: account, gasLimit: gas })
                .then(() => {
                    addToast('Transaction Success!', { appearance: 'success', autoDismiss: true });
                    wrappedHandleChange('');
                    setLoader(false);
                })
                .catch(err => {
                    if (err.message.includes('User denied transaction signature')) {
                        addToast('Denied Transaction', { appearance: 'error', autoDismiss: true });
                        setLoader(false);
                    } else {
                        addToast('Transaction Failed', { appearance: 'error', autoDismiss: true });
                        setLoader(false);
                    }
                });
        } else {
            const gas = await contract.methods
                .burnForAllTokensAndSwapForTokens(indexPool, minAmountsOut, intermediaries, poolAmountIn, burnWithThisToken.address, '1')
                .estimateGas({ from: account })
                .then(res => new BigNumber(res).multipliedBy(GAS_MULTIPLYER).toFixed(0))
                .catch(() => {
                    addToast('Error during gas estimation', {
                        appearance: 'error',
                        autoDismiss: true,
                    });
                    setLoader(false);
                });
            if (!gas) return;
            addToast('Waiting for transaction success...', { appearance: 'info', autoDismiss: true });
            await contract.methods
                .burnForAllTokensAndSwapForTokens(indexPool, minAmountsOut, intermediaries, poolAmountIn, burnWithThisToken.address, '1')
                .send({ from: account, gasLimit: gas })
                .then(() => {
                    addToast('Transaction Success!', { appearance: 'success', autoDismiss: true });
                    wrappedHandleChange('');
                    setLoader(false);
                })
                .catch(err => {
                    if (err.message.includes('User denied transaction signature')) {
                        addToast('Denied Transaction', { appearance: 'error', autoDismiss: true });
                        setLoader(false);
                    } else {
                        addToast('Transaction Failed', { appearance: 'error', autoDismiss: true });
                        setLoader(false);
                    }
                });
        }

    };

    return { handleBurn };
};

export default useRouterBurn;

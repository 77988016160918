import { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';

import useGraphRequest from '../useGraphRequest';
import usePoolBalance from '../usePoolBalance';

import { getTokenAvatar, getGraphUrl } from '../../utils/utils';

import { getIndexPoolTokens, getExchangePoolInfo } from '../../constants/graph.constants';
import { API_GRAPH_EXCHANGE } from '../../constants/routes.constants';
import { COMMON_BASE_TOKENS } from '../../constants/chain.constants.js';

const usePoolInfo = poolAddress => {
  const { chainId } = useWeb3React();
  const [info, setInfo] = useState();

  const { data } = useGraphRequest(getGraphUrl(chainId), getIndexPoolTokens(poolAddress));
  const { data: dataExchange } = useGraphRequest(API_GRAPH_EXCHANGE, getExchangePoolInfo(poolAddress));
  const poolTokenBalances = usePoolBalance(poolAddress);

  useEffect(() => {
    if (data?.indexPool && poolTokenBalances) {
      const avatar = getTokenAvatar(poolAddress, chainId);
      const { name, symbol, tokensList, totalValueLockedUSD, totalSupply, tokens, totalWeight, swapFee, dailySnapshots } = data?.indexPool;

      const poolTokenWeights = tokens.map(item => ({
        address: item.token.id,
        weight: item.denorm,
      }));

      const newInfo = {
        address: poolAddress,
        name,
        img: avatar,
        symbol,
        tokens: tokensList,
        balances: poolTokenBalances,
        weights: poolTokenWeights,
        totalSupply: new BigNumber(totalSupply),
        totalWeight: new BigNumber(totalWeight),
        swapFee: new BigNumber(swapFee).multipliedBy(1e18),
        tvl: new BigNumber(totalValueLockedUSD),
        dailySnapshots,
        exchangeInfo: dataExchange?.token,
        allTokensInfo: COMMON_BASE_TOKENS[chainId],
      };
      setInfo(newInfo);
    }
  }, [chainId, poolAddress, data, dataExchange, poolTokenBalances]);

  return info;
};

export default usePoolInfo;

import React, { useState, useMemo } from 'react';
import classNames from 'classnames';
import BigNumber from 'bignumber.js';
import { Grid, Tooltip } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useWeb3React } from '@web3-react/core';

import Card from '../../../../components/Card';

import { formatter, getTokenAvatar } from '../../../../utils/utils';
import { copyTextToClipboard } from '../../../../helpers/functions.helper';

import { CopyIcon, ArrowUpIcon, ArrowUpGreen, ArrowDownRed } from '../../../../constants/icons.constants';
import { BSC_SCAN_URL, FTM_SCAN_URL, BINANCE_ID, AR_BEX_URL, LAST_DAY } from '../../../../constants/global.constants';

import { useStyles } from './styles.module';

const PoolHeader = ({ poolInfo }) => {
  const classes = useStyles();
  const { chainId } = useWeb3React();
  const { dailySnapshots, exchangeInfo, name, address, tvl, totalSupply } = poolInfo;
  const matches = useMediaQuery('(max-width:960px)');
  const currentPoolAvatar = getTokenAvatar(address, chainId);
  const [isCopied, setIsCopied] = useState(false);

  const percentTvl = useMemo(() => (
      dailySnapshots.length
      && (dailySnapshots[dailySnapshots.length - 1].totalValueLockedUSD / dailySnapshots[0].totalValueLockedUSD - 1) * 100
  ), [dailySnapshots]);

  const snapshotPrice = useMemo(() => (
      dailySnapshots.length
      && dailySnapshots[dailySnapshots.length - 1].value - dailySnapshots[0].value
  ), [dailySnapshots]);

  const percentPrice = useMemo(() => (
      dailySnapshots.length
      && (dailySnapshots[dailySnapshots.length - 1].value / dailySnapshots[0].value - 1) * 100
  ), [dailySnapshots]);

  const tradeVolume = useMemo(() => (
      exchangeInfo?.tokenDayData.reduce((acc, item) => (
          item.date > LAST_DAY && Number(acc) + Number(item?.dailyVolumeUSD)
      ), 0)
  ), [exchangeInfo]);

  const percentVolume = useMemo(() => {
    const volumeDayToDate = exchangeInfo?.tokenDayData.reduce((acc, item) => {
      if (item.date > LAST_DAY) return { ...acc, first: Number(acc.first) + Number(item?.dailyVolumeUSD) };
      return { ...acc, second: Number(acc.second) + Number(item?.dailyVolumeUSD) };
    }, { first: 0, second: 0 });

    return (volumeDayToDate?.first / volumeDayToDate?.second - 1) * 100;
}, [exchangeInfo]);

  const tradePrice = useMemo(() => (
      new BigNumber(exchangeInfo?.derivedUSD).toFixed(3)
  ), [exchangeInfo]);

  const percentTradePrice = useMemo(() => {
    const priceDayToDate = exchangeInfo?.tokenDayData.reduce((acc, item) => {
      if (item.date > LAST_DAY) {
        return { ...acc, first: Number(acc.first) + Number(item?.priceUSD), countFirst: acc.countFirst + 1 };
      }
      return { ...acc, second: Number(acc.second) + Number(item?.priceUSD), countSecond: acc.countSecond + 1 };
    }, { first: 0, second: 0, countFirst: 0, countSecond: 0 });
    return ((priceDayToDate?.first) / priceDayToDate?.countFirst / (priceDayToDate?.second - 1) / priceDayToDate?.countSecond) * 100;
  }, [exchangeInfo]);

  return (
    <Grid item container justifyContent="space-between" className={classes.container} alignItems="stretch">
      <Grid
        item
        component={Card}
        xs={matches ? 12 : false}
        lg={3}
        md={3}
        sm={12}
        className={classNames(classes.card, { [classes.mobileCard]: matches })}
      >
        <img
          src={currentPoolAvatar}
          alt="bit10"
          className={classes.avatar}
        />
        <div className={classNames(classes.text, classes.withImg)}>
          <div className={classes.bsc}>
            <div className={classes.title}>{name}</div>
            <Tooltip title={isCopied ? 'Copied!' : 'Copy address to clipboard'} placement="top">
              <div
                className={classes.icon}
                onPointerLeave={() => setTimeout(() => setIsCopied(false), 500)}
                onClick={() => {
                  copyTextToClipboard(address);
                  setIsCopied(true);
                }}
              >
                {CopyIcon}
              </div>
            </Tooltip>
            <a
              className={classes.icon}
              href={`${ chainId === BINANCE_ID ? BSC_SCAN_URL : FTM_SCAN_URL}${address}`}
              target="_blank"
              rel="noreferrer"
            >
              {ArrowUpIcon}
            </a>
          </div>
          <div className={classNames(classes.price, classes.priceWithImg)}>
            <div>${formatter.format(tvl.toNumber() / totalSupply.div(1e18))}</div>
            <div className={classNames(classes.green, { [classes.red]: snapshotPrice < 0 })}>
              {snapshotPrice > 0 ? '+' : ''}
              {snapshotPrice.toFixed(2)}
              ({snapshotPrice > 0 ? '+' : ''}{percentPrice.toFixed(2)}%)
            </div>
          </div>
          <div className={classes.supply}>Supply: {formatter.format(totalSupply.div(1e18))}</div>
        </div>
      </Grid>
      <Grid
        item
        component={Card}
        xs={matches ? 12 : false}
        lg={3}
        md={3}
        sm={12}
        className={classNames(classes.card, { [classes.mobileCard]: matches })}
      >
        <div className={classes.text}>
          <div className={classes.titleTVL}>Total Value Locked</div>
          <div className={classes.priceTVL}>
            <div>${formatter.format(tvl.toNumber())}</div>
            {
              percentTvl !== 0
              && (
              <div className={classes.percent}>
                {percentTvl > 0 ? '+' : ''}
                {percentTvl.toFixed(2)}%
                {percentTvl > 0 ? ArrowUpGreen : ArrowDownRed}
              </div>)
            }
          </div>
        </div>
      </Grid>
      <Grid
        item
        component={exchangeInfo ? Card : undefined}
        xs={matches ? 12 : false}
        lg={3}
        md={3}
        sm={12}
        className={classNames(classes.card, { [classes.mobileCard]: matches })}
      >
        {
          exchangeInfo && (
          <div className={classes.text}>
            <div className={classes.title}>
              ArbEx Price & Volume
              <a className={classes.icon} href={AR_BEX_URL} target="_blank" rel="noreferrer">
                {ArrowUpIcon}
              </a>
            </div>
            <div className={classes.priceTVL}>
              <div>${formatter.format(tradePrice)}</div>
              {
                (!!percentTradePrice && percentTradePrice !== 0)
                && (
                <div className={classes.percent}>
                  {percentTradePrice > 0 ? '+' : ''}
                  {percentTradePrice.toFixed(2)}%
                  {percentTradePrice > 0 ? ArrowUpGreen : ArrowDownRed}
                </div>
                )
              }
            </div>
            {tradeVolume && (
            <div className={classes.priceTVL}>
              <div>${formatter.format(tradeVolume.toFixed(2))}</div>
                {(!!percentVolume && percentVolume !== 0) && (
                  <div className={classes.percent}>
                    {percentVolume > 0 ? '+' : ''}
                    {percentVolume.toFixed(2)}%
                    {percentVolume > 0 ? ArrowUpGreen : ArrowDownRed}
                  </div>
                )}
            </div>
            )}
          </div>
          )
        }
      </Grid>
    </Grid>
  );
};

export default PoolHeader;

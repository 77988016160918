import { BINANCE_ID, FANTOM_ID } from '../constants/global.constants';
import { CG_TOKEN_IDS } from '../state/bnbPriceContext';

const getNativeChainPrice = ({ tokenUsdPrices, chainId }) => {
    switch (chainId) {
        case BINANCE_ID:
            return tokenUsdPrices[CG_TOKEN_IDS.BNB]?.usd;
        case FANTOM_ID:
            return tokenUsdPrices[CG_TOKEN_IDS.FANTOM]?.usd;
        default:
            return tokenUsdPrices[CG_TOKEN_IDS.BNB]?.usd;
    }
};

export default getNativeChainPrice;

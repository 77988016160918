import React from 'react';
import { Grid } from '@material-ui/core';
import classNames from 'classnames';

import FarmInfo from './FarmInfo';

import { useStyles } from './styles.module';

const PoolContent = ({ poolInfo }) => {
    const classes = useStyles();

    return (
      <Grid
        container
        item
        direction="column"
        className={classes.content}
      >
        <Grid item spacing={2} container direction="column" className={classes.text}>
          <div>
            <button
              className={classNames(classes.titleButton)}
            >
              Asset Allocation
            </button>
          </div>
          <Grid item className={classes.description}>Breakdown of total assets in this fund</Grid>
          <Grid container spacing={6} item>
            {poolInfo.farmInfoList.map(item => (
              <Grid container item lg={12} key={item.address}>
                <FarmInfo farmInfo={item} />
              </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    );
};

export default PoolContent;

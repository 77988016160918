import { useState, useMemo } from 'react';
import { useWeb3React } from '@web3-react/core';
import { abis, addresses } from '@project/contracts';
import { useToasts } from 'react-toast-notifications';

import BigNumber from 'bignumber.js';

import { makeContract } from '../../utils/utils';

import { GAS_MULTIPLYER } from '../../constants/global.constants';

const useMintYBF = ({ indexPool, currentTokenBalance }) => {
    const { account, library, chainId } = useWeb3React();
    const { addToast } = useToasts();
    const [inputValue, setInputValue] = useState('');
    const [loader, setLoader] = useState(false);
    const [isDemandGreaterThanBalance, setIsDemandGreaterThanBalance] = useState(null);

    const poolAmountOut = useMemo(() => (
        library.utils.toWei(inputValue === '' ? '0' : inputValue, 'ether')
    ), [inputValue, library]);

    const contract = makeContract(
        library,
        abis.uniswapYBFRouterMinter,
        addresses[chainId].YBF[indexPool].uniswapYBFRouterMinter,
    );

    const handleChange = async value => {
        const balance = await library.eth.getBalance(account);
        if (value !== undefined && value !== '' && Number(value) >= 0) {
            const weiValue = library.utils.toWei(value === '' ? '0' : value, 'ether');
            setInputValue(value);
            if (new BigNumber(weiValue).isGreaterThan(balance)) {
                setIsDemandGreaterThanBalance('Insufficient balance');
            } else {
                setIsDemandGreaterThanBalance(null);
            }
        }
        if (value === '') {
            setInputValue('');
            setIsDemandGreaterThanBalance(null);
        }

    };

    const handleMint = async () => {
        setLoader(true);
        const gas = await contract.methods
            .swapETHForVaultsAndMint(indexPool)
            .estimateGas({ from: account, value: poolAmountOut })
            .then(res => new BigNumber(res).multipliedBy(GAS_MULTIPLYER).toFixed(0))
            .catch(() => {
                addToast('Error during gas estimation', { appearance: 'error', autoDismiss: true });
                setLoader(false);
            });
        if (!gas) return;

        addToast('Waiting for transaction success...', { appearance: 'info', autoDismiss: true });

        await contract.methods
            .swapETHForVaultsAndMint(indexPool)
            .send({ from: account, value: poolAmountOut, gasLimit: gas })
            .then(() => {
                addToast('Transaction Success!', { appearance: 'success', autoDismiss: true });
                setInputValue('');
                setLoader(false);
            })
            .catch(err => {
                if (err.message.includes('User denied transaction signature')) {
                    addToast('Denied Transaction', { appearance: 'error', autoDismiss: true });
                    setLoader(false);
                } else {
                    addToast('Transaction Failed', { appearance: 'error', autoDismiss: true });
                    setLoader(false);
                }
            });

    };

    return { inputValue, handleChange, handleMint, isDemandGreaterThanBalance, loader, currentTokenBalance };
};
export default useMintYBF;

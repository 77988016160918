import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';

import Card from '../../../components/Card';
import Button from '../../../components/ButtonUI';

import ybfFlag from '../../../assets/ybfFlag.svg';
import MfFlag from '../../../assets/MfFlag.svg';

import { getTokenAvatar, getDexAvatar, formatBNWithCommas } from '../../../utils/utils';
import { FANTOM_ID } from '../../../constants/global.constants';

import { useStyles } from './styles.module';

const PoolCardYBF = ({ poolInfo }) => {
    const classes = useStyles();
    const history = useHistory();
    const { chainId } = useWeb3React();
    const { address, name, symbol, dexName, apy } = poolInfo;

    const currentPoolAvatar = getTokenAvatar(address, chainId);

    const otherToken = useMemo(() => (
        dexName?.map(item => <Avatar src={getDexAvatar(item)} key={item} />)
    ), [dexName]);

    return (
      <Grid item>
        <Card className={classes.card}>
          <div className={classes.row}>
            <img src={chainId === FANTOM_ID ? MfFlag : ybfFlag} alt="ybfFlag" className={classes.flag} />
            <Avatar
              src={currentPoolAvatar}
              className={classes.avatar}
            />
            <div className={classes.titleName}>{name}</div>
            <AvatarGroup max={5} className={classes.avatarGroup}>
              {otherToken}
            </AvatarGroup>
            <div className={classes.statistic}>
              <div className={classes.price}>
                APY
              </div>
              <div className={classes.percent}>
                {apy ? `${formatBNWithCommas(apy, 2)}%` : '-'}
              </div>
            </div>
          </div>
          <Button onClick={() => history.push(`/ybf/${address}`)} className={classes.button}>
            {symbol} Dashboard
          </Button>
        </Card>
      </Grid>
    );
};

export default PoolCardYBF;

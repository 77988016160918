import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '20px 16px 20px',
        background: props => (props.gradient
            ? 'linear-gradient(270deg, rgba(218, 161, 14, 0.5) 0%, rgba(218, 161, 14, 0.21875) 36.98%, rgba(218, 161, 14, 0) 68.75%), #191919'
            : ''),
        borderRadius: '16px',
    },
    flexContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
    },
    tooltip: {
        marginLeft: '5px',
    },
    icon: {
        color: '#F0B90B',
    },
    balance: {
        color: '#9F9F9F',
        fontSize: '14px',
    },
    balanceUsd: {
        color: props => (props.error ? '#DD3434' : '#9F9F9F'),
        fontSize: '14px',
        fontFamily: 'DM Mono',
    },
    tokenSelectButton: {
        display: 'flex',
        background: 'rgba(255, 255, 255, 0.05)',
        borderRadius: '100px',
        padding: '6px 8px',
        minWidth: '120px',
        marginTop: '8px',
    },
    tokenButtonLabel: {
        width: '22px',
        height: '22px',
        marginRight: '6px',
    },
    input: {
        color: props => (props.error ? '#DD3434' : '#9F9F9F'),
    },
    tokenButtonImg: {
        width: '22px',
        height: '22px',
    },
    maxButton: {
        color: '#F0B90B',
        '& img': {
            marginRight: '6px',
        },
    },
    tokenSymbol: {
        color: '#F0B90B',
    },
}));

export default useStyles;

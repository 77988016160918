import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  buttonGroup: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
    marginBottom: '-15px',
  },
  button: {
    minWidth: '165px',
    '& button': {
      width: '100%',
    },
  },

});

import React from 'react';
import { Grid } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useWeb3React } from '@web3-react/core';
import ProgressBar from '../../../../../components/ProgressBar';

import { getTokenAvatar, formatBN } from '../../../../../utils/utils';
import getDexName from '../../../../../utils/getDexName';

import { ArrowUpIcon } from '../../../../../constants/icons.constants';

import { useStyles } from './styles.module';

const FarmInfo = ({ farmInfo }) => {
  const classes = useStyles();
  const { chainId } = useWeb3React();
  const matches = useMediaQuery('(max-width:450px)');

  const avatar = getTokenAvatar(farmInfo.address, chainId);

  return (
    <Grid
      container
      spacing={1}
      className={classes.root}
      direction="row"
    >
      <Grid container item xs>
        <Grid
          container
          item
          xs={12}
          spacing={2}
          alignItems="flex-start"
          justifyContent="space-between"
          className={classes.wrap}
        >
          <Grid item className={classes.row} lg={5} md={5} sm={5} xs={12}>
            <Grid item>
              <Avatar src={avatar} variant="square" className={classes.avatar} />
            </Grid>
            <Grid item className={classes.tokenNameColumn}>
              <Grid item>
                <span>{farmInfo.symbol}</span>
                {!matches && <span className={classes.tokenName}>{farmInfo.dexName}</span>}
              </Grid>
              <Grid item className={classes.text}>
                {getDexName(farmInfo.dexName).name}
                <a
                  className={classes.icon}
                  href={getDexName(farmInfo.dexName).link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {ArrowUpIcon}
                </a>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container lg={7} md={7} sm={7} xs={12} justifyContent="space-between">
            <Grid item className={classes.column}>
              <Grid item>{farmInfo.apy ? `${formatBN(farmInfo.apy, 2)}%` : '-'}</Grid>
              <Grid item className={classes.textPercentage}>APY</Grid>
            </Grid>
            <Grid item className={classes.column}>
              <Grid item>{farmInfo.apy ? `${formatBN(farmInfo.apy / 365, 4)}%` : '-'}</Grid>
              <Grid item className={classes.textPercentage}>Daily</Grid>
            </Grid>
            <Grid item className={classes.column}>
              <Grid item>{farmInfo.weight.toFixed(2)}%</Grid>
              <Grid item className={classes.textPercentage}>Weight</Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ProgressBar percentage={farmInfo.weight.toString() || '0'} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FarmInfo;
